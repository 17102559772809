import React from 'react';
import Empty from '../Results/Empty';
import formatDate from '../../utils/dates';
import formatMoney from '../../utils/money';
import { useTranslation } from "react-i18next";

const FormDataDisabledPayment = (props) => {
    const { t } = useTranslation('common');
    return (
        <div className={`repeater-box-disabled form-data-disabled ${props.className || ''}`}>
            {props.data.map((repeater) => {
                return (
                    <div className='repeater-item-disabled' key={repeater.id}>
                        {repeater.description &&
                            <div>
                                <span>{t('deal.description')}</span>
                                <span>{repeater.description}</span>
                            </div>
                        }
                        {repeater.amount &&
                            <div>
                                <span>{t('deal.amount')}</span>
                                <span>{formatMoney(repeater.amount)}€</span>
                            </div>
                        }
                        {repeater.date &&
                            <div>
                                <span>{t('deal.date')}</span>
                                <span>{formatDate(repeater.description, 'DD/MM/YYYY')}</span>
                            </div>
                        }
                    </div>
                );
            })}
            {props.data.length === 0 &&
                <Empty type='info' className='mb-10' message={t('commont.not-found')} />}
        </div>
    )

};

export default FormDataDisabledPayment;
