import React from 'react';

import formatDate from '../../shared/utils/dates';
import Status from '../../shared/components/UIElements/Status';
import Tooltip from '../../shared/components/UIElements/Tooltip';
import ActionButton from '../../shared/components/UIElements/ActionButton';

import { useTranslation } from "react-i18next";

const NotesBubbleList = (props) => {

    const { t } = useTranslation('common');

    return (
        <div className='notes-list'>
            {props.notes.map((note) => {
                return (
                    <div className='note-item' key={note.id}>
                        <div className='note-header'>
                            <span>{note.created_by?.first_name} {note.created_by?.last_name} {note.type === 'task' && <Status icon='fi-br-checkbox' status={note.status} className='tooltip'><Tooltip><span className='capitalize'>{note.status}</span> {t('models.task')}</Tooltip></Status>}</span>
                            <span>
                                {formatDate(note.created_at, 'DD/MM/YYYY HH:MM')}
                                <div className='note-actions'>
                                    {note.status === 'pending' && note.type === 'task' && <ActionButton text={t('common.complete')} size='small' flaticon='fi-rr-check' onClick={(e) => props.completeTask(note)} />}
                                    {/* <ActionButton text='Archive' size='small' onClick={() => props.archiveNote(note)} /> */}
                                    <ActionButton text={t('common.remove')} size='small' onClick={() => props.deleteNote(note)} />
                                </div>
                                </span> 
                            
                        </div>
                        <div className='note-content'>
                            {note.content}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default NotesBubbleList;
