import React from 'react';
import { Link } from 'react-router-dom';
import Icon from './Icon';

import './ActionButton.css';
import Tooltip from './Tooltip';

const ActionButton = (props) => {
	if (props.href) {
		return (
			<a
				href={props.href}
				className={`action-button ${props.tooltip ? ' tooltip' : ''}${!props.text ? ' only-icon' : ''}${props.size ? ' size-' + props.size : ''}${props.className ? ' ' + props.className : ''}${props.icon ? ' with-icon' : ''}${props.style ? props.style : ''}`}
				onClick={props.onClick}
			>
				<div className='action-button-icon'>
                    {props.icon && (<Icon icon={props.icon} className='action-button-icon' />)}
					{props.flaticon && (<Icon flaticon={props.flaticon} className='action-button-icon' />)}
					{props.text && <div className='action-button-text'>{props.text}</div>}
					{props.children}
					{props.tooltip && <Tooltip>{props.tooltip}</Tooltip>}
				</div>
			</a>
		);
	}
	if (props.to) {
		return (
			<Link
				to={props.to}
				className={`action-button ${props.tooltip ? ' tooltip' : ''}${!props.text ? ' only-icon' : ''}${props.size ? ' size-' + props.size : ''}${props.className ? ' ' + props.className : ''}${props.icon ? ' with-icon' : ''}${props.style ? props.style : ''}`}
				onClick={props.onClick}
			>
				{props.icon && (<Icon icon={props.icon} className='action-button-icon' />)}
				{props.flaticon && (<Icon flaticon={props.flaticon} className='action-button-icon' />)}
				{props.text && <div className='action-button-text'>{props.text}</div>}
				{props.children}
				{props.tooltip && <Tooltip>{props.tooltip}</Tooltip>}
			</Link>
		);
	}
	return (
		<button
		className={`action-button ${props.tooltip ? ' tooltip' : ''}${!props.text ? ' only-icon' : ''}${props.size ? ' size-' + props.size : ''}${props.className ? ' ' + props.className : ''}${props.icon ? ' with-icon' : ''}${props.style ? props.style : ''}`}
			onClick={props.onClick}
			disabled={props.disabled}
			type={props.type || ''}
			form={props.form}
		>
			{props.icon && (<Icon icon={props.icon} className='action-button-icon' />)}
			{props.flaticon && (<Icon flaticon={props.flaticon} className='action-button-icon' />)}
			{props.text && <div className='action-button-text'>{props.text}</div>}
			{props.children}
			{props.tooltip && <Tooltip>{props.tooltip}</Tooltip>}
		</button>
	);
};

export default ActionButton;
