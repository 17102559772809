import React from 'react';

import Icon from './Icon';

import './Notice.css';

const Notice = (props) => {
	let icon;
	switch (props.type) {
		case 'success':
			icon = 'fi-rr-check';
			break;
		case 'error':
			icon = 'fi-rr-cross-circle';
			break;
		case 'alert':
			icon = 'fi-rr-triangle-warning';
			break;
		case 'danger':
			icon = 'fi-rr-triangle-warning';
			break;
		case 'info':
			icon = 'fi-rr-info';
			break;
		default:
			break;
	}
	return <div className={`notice ${props.type}-message ${props.className || ''}`}><Icon flaticon={icon} /><span>{props.message}</span></div>;
};

export default Notice;
