import React, { useContext, useEffect, useState } from 'react';
import { useForm } from '../../shared/hooks/form-hook';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import PageHeader from '../../shared/components/Titles/PageHeader';
import ContentHead from '../../shared/components/Wrappers/ContentHead';

import PayoutDeals from '../components/PayoutDeals';
import DashboardColumn from '../../shared/components/Dashboards/DashboardColumn';
import DashboardWrapper from '../../shared/components/Dashboards/DashboardWrapper';
import DashboardBox from '../../shared/components/Dashboards/DashboardBox';
import DataTable from '../../shared/components/Dashboards/DataTable';
import Modal from '../../shared/components/UIElements/Modal';
import ActionButton from '../../shared/components/UIElements/ActionButton';

import { useTranslation } from "react-i18next";

const Payout = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const history = useHistory();
	const payoutId = useParams().pid;
	const [payout, setPayout] = useState();

	const [modalConfirmDelete, setmodalConfirmDelete] = useState(false);

	const openConfirmDelete = () => {
		if (modalConfirmDelete) {
			setmodalConfirmDelete(false);
		} else {
			setmodalConfirmDelete(true);
		}
	};

	const confirmDelete = async (event) => {
		event.preventDefault();
		setmodalConfirmDelete(false);
		try {
			const response = await sendRequest(
				`${process.env.REACT_APP_API_URL}/payouts/${payoutId}`,
				'DELETE',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			history.push({
				pathname: '/payouts/',
				noticeMsg: response.message,
				noticeType: 'success',
			});
		} catch (error) { }
	};

	useEffect(() => {
		const fetchPayout = async () => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_API_URL + '/payouts/' + payoutId,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setPayout(response.payout);
			} catch (error) {
				console.log(error);
			}
		};
		fetchPayout();
	}, [sendRequest, auth, payoutId]);


	const actions = [
		{
			title: t('common.back'),
			onClick: () => history.goBack()
		}
	];

	let subactions = [
		{
			title: t('payout.remove'),
			flaticon: 'fi-rr-trash',
			onClick: openConfirmDelete
		}
	];

	if (isLoading) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	return (
		<ContentWrapper>
			{payout &&
				<>
					<ContentHead>
						<PageHeader
							gobackOnClick={() => history.goBack()}
							title={`${t('models.payout')} ${payout.month}/${payout.year}`}
							actions={actions}
							subactions={subactions}
							separator={true}
						/>
					</ContentHead>
					{isLoading && <LoadingSpinner />}
					{errorMsg && <Notice type='error' message={errorMsg} />}
					<DashboardWrapper>
						<DashboardColumn className='flex-small'>
							<DashboardBox
								title={t('payout.information')}
							>
								<DataTable>
									<div><span>{t('payout.month')}</span><span>{payout.month}</span></div>
									<div><span>{t('payout.year')}</span><span>{payout.year}</span></div>
									<div><span>{t('payout.amount')}</span><span>{payout.amount}</span></div>
								</DataTable>
							</DashboardBox>
						</DashboardColumn>
						<DashboardColumn>
							<DashboardBox
								title={t('payout.deals-associated')}
								className='dashboard-box-table'
							>
								<PayoutDeals payout={payout.id} hideColumnsIndex={[1, 4, 5, 7]} />
							</DashboardBox>
						</DashboardColumn>
					</DashboardWrapper>
					<Modal
						show={modalConfirmDelete}
						title={t('payout.remove')}
						closeAction={openConfirmDelete}
						footer={
							<div className='modal-footer-actions'>
								<ActionButton text={t('common.confirm-remove')} style={`red-delete`} onClick={confirmDelete}/>
								<ActionButton text={t('common.close')} onClick={openConfirmDelete} />
							</div>
						}
					>
						{isLoading &&
							<LoadingSpinner></LoadingSpinner>
						}
						<React.Fragment>
							<Notice type='danger' message={t('action-irreversible')} />
							<p>{t('payout.notice-remove')}</p>
						</React.Fragment>

					</Modal>
				</>
			}
		</ContentWrapper>

	);
};

export default Payout;
