import React from 'react';

import Icon from './Icon';
import Tooltip from './Tooltip';

import './InfoTooltip.css';

const InfoTooltip = (props) => {
	return <span className='infolabel tooltip'><Icon flaticon='fi-sr-info' /><Tooltip dangerouslySetInnerHTML={{ __html: props.children }} /></span>;
};

export default InfoTooltip;
