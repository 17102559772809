import React from "react";
import ReactDom from "react-dom";
import { CSSTransition } from "react-transition-group";

import NavLinks from "./NavLinks";

import "./MobileMenu.css";

const MobileMenu = (props) => {
  const content = (
    <CSSTransition in={props.show} timeout={500} classNames='mobile-menu-anim' mountOnEnter unmountOnExit>
      <aside className="mobile-menu" onClick={props.onClick}>
        <NavLinks />
      </aside>
    </CSSTransition>
  );
  return ReactDom.createPortal(content, document.getElementById("mobile-menu"));
};

export default MobileMenu;
