import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../../shared/components/UIElements/ActionButton';

const UserItem = (props) => {
	return (
		<React.Fragment>
			<Link className='item no-link' to={`/users/edit/${props.id}`} key={props.id}>
				<div className={props.header[0] && props.header[0]?.style}>
					<span>
						{props.first_name} {props.last_name}
					</span>
				</div>
				<div className={props.header[1] && props.header[1]?.style}>
					<span>{props.email}</span>
				</div>
				<div className={props.header[2] && props.header[2]?.style}>
					<span>{props.phone}</span>
				</div>
				<div className={props.header[3] && props.header[3]?.style}>
					<span>{props.role}</span>
				</div>
				<div className={`actions ${props.header[4] && props.header[4]?.style}`}>
					{/* <ActionButton
						to={`/users/${props.id}`}
						text='View'
						className='inline-grid'
					/> */}
					<ActionButton to={`/users/edit/${props.id}`} flaticon='fi-br-pencil' className='inline-grid table-action-icon' />
				</div>
			</Link>
		</React.Fragment >
	);
};

export default UserItem;
