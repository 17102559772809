import React, { useContext } from 'react';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHistory } from 'react-router-dom';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Input from '../../shared/components/UIElements/Input';
import Button from '../../shared/components/UIElements/Button';
import ContentBox from '../../shared/components/Wrappers/ContentBox';
import MainTitle from '../../shared/components/Titles/MainTitle';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import SelectWrapper from '../../shared/components/UIElements/SelectWrapper';

import './NewService.css';
import PageHeader from '../../shared/components/Titles/PageHeader';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import FormFooter from '../../shared/components/Forms/FormFooter';
import ServiceTotals from '../components/ServiceTotals';
import { serviceTypes } from '../data/ServiceOptions';

import { useTranslation } from "react-i18next";

const NewService = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const history = useHistory();

	const [formState, inputHandler] = useForm();

	const formSubmitHandler = async (event) => {
		event.preventDefault();

		try {
			await sendRequest(
				process.env.REACT_APP_API_URL + '/services/',
				'POST',
				JSON.stringify({
					type: formState.inputs.type.value,
					name: formState.inputs.name.value,
					description: formState.inputs.description.value,
					price: formState.inputs.price.value,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/services/',
				noticeMsg: t('service.added'),
				noticeType: 'success',
			});
		} catch (error) { }

		//console.log(formState.inputs); // send data to backend
	};

	const actions = [
		{
			title: t('common.cancel'),
			onClick: () => history.goBack()
		},
		{
			title: t('service.add'),
			style: 'filled-blue',
			flaticon: 'fi-rr-check',
			form: 'add-service',
			type: 'submit',
			disabled: !formState.isValid
		}
	];

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					gobackOnClick={() => history.goBack()}
					title={t('service.add-t')}
					actions={actions}
					separator={true}
				/>
			</ContentHead>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			<form className='add-service' onSubmit={formSubmitHandler} id='add-service'>
				<FormGroup
					title={t('service.information')}
					subtitle={t('service.information-st')}
				>
					<Input
						id='name'
						element='input'
						type='text'
						placeholder={t('service.name-ph')}
						label={t('service.name')}
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
						errorText='Please input a valid text'
					/>
					<SelectWrapper
						onInput={inputHandler}
						// initialValue={'web'}
						id='type'
						label={t('service.type')}
						options={serviceTypes}
						initialValid={false}
					/>
					<Input
						id='description'
						element='input'
						type='text'
						placeholder={t('service.description-ph')}
						label={t('service.description')}
						onInput={inputHandler}
						validators={[]}
						initialValid={true}
					/>
					<Input
						id='price'
						element='input'
						type='number'
						placeholder={t('service.price-ph')}
						label={t('service.price')}
						onInput={inputHandler}
						validators={[]}
						initialValid={true}
					/>
					<ServiceTotals price={formState.inputs?.price.value} type={formState.inputs?.type.value} />
				</FormGroup>
				<FormActions>
					<FormFooter
						actions={actions}
					/>
				</FormActions>
			</form>
		</ContentWrapper>
	);
};

export default NewService;
