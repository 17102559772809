import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import Status from '../../shared/components/UIElements/Status';
import { projectStatuses } from '../data/ProjectOptions';

const ProjectItem = (props) => {

	function removeHttp(url) {
		return url.replace(/^https?:\/\//, '');
	}

	const statusTable = projectStatuses;
	const status = statusTable
		.filter((status) => status.value === props.status)
		.map((status) => status);

	return (
		<React.Fragment>
			<Link to={`/projects/${props.id}`} key={props.id} className='item no-link'>
				<div className={props.header[0] && props.header[0]?.style}>
					<span>{props.name}</span>
				</div>
				<div className={props.header[1] && props.header[1]?.style}>
					<span>{removeHttp(props.domain)}</span>
				</div>
				<div className={props.header[2] && props.header[2]?.style}>
					<Status icon='fi-rr-globe' status={status[0]} />
					{/* <span>{props.status}</span> */}
				</div>
				<div className={`actions ${props.header[3] && props.header[3]?.style}`}>
					<ActionButton to={`/projects/edit/${props.id}`} flaticon='fi-br-pencil' className='inline-grid table-action-icon' />
					<ActionButton to={`/projects/${props.id}`} flaticon='fi-br-menu-dots' className='inline-grid table-action-icon' />
				</div>
			</Link >
		</React.Fragment >
	);
};

export default ProjectItem;
