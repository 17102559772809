import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';

import Input from '../../shared/components/UIElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Modal from '../../shared/components/UIElements/Modal';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import SelectWrapper from '../../shared/components/UIElements/SelectWrapper';
import SelectAsyncWrapper from '../../shared/components/UIElements/SelectAsyncWrapper';
import Notice from '../../shared/components/UIElements/Notice';
import PageHeader from '../../shared/components/Titles/PageHeader';
import Tabs from '../../shared/components/Results/Tabs';
import FormFooter from '../../shared/components/Forms/FormFooter';
import ActionButton from '../../shared/components/UIElements/ActionButton';

import './EditProject.css';
import { projectStatusesEdit, projectTypes } from '../data/ProjectOptions';
import formatDate from '../../shared/utils/dates';

import { useTranslation } from "react-i18next";

const EditProject = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [project, setLoadedProject] = useState();
	const [payments, setPayments] = useState();
	const [subscriptions, setSubscriptions] = useState();
	const [deals, setDeals] = useState();
	const projectId = useParams().pid;
	const history = useHistory();
	const [modalConfirmDelete, setmodalConfirmDelete] = useState(false);
	const [modalConfirmRestore, setmodalConfirmRestore] = useState(false);
	const [defaultCompany, setDefaultCompany] = useState('');
	const [defaultSaleman, setDefaultSaleman] = useState('');

	const openConfirmDelete = () => {
		if (modalConfirmDelete) {
			setmodalConfirmDelete(false);
		} else {
			const fetchPayments = async () => {
				try {
					const response = await sendRequest(
						`${process.env.REACT_APP_API_URL}/payments/project/${projectId}`,
						'GET',
						null,
						{ Authorization: 'Bearer ' + auth.token }
					);
					setPayments(response.payments);
				} catch (error) {
					console.log(error);
				}
			};
			const fetchSubscriptions = async () => {
				try {
					const response = await sendRequest(
						`${process.env.REACT_APP_API_URL}/subscriptions/project/${projectId}?status=active`,
						'GET',
						null,
						{ Authorization: 'Bearer ' + auth.token }
					);
					setSubscriptions(response.subscriptions);
				} catch (error) {
					console.log(error);
				}
			};
			const fetchDeals = async () => {
				try {
					const response = await sendRequest(
						`${process.env.REACT_APP_API_URL}/deals/project/${projectId}`,
						'GET',
						null,
						{ Authorization: 'Bearer ' + auth.token }
					);
					setDeals(response.deals);
				} catch (error) {
					console.log(error);
				}
			};
			fetchPayments();
			fetchSubscriptions();
			fetchDeals();
			setmodalConfirmDelete(true);
		}
	};

	const openConfirmRestore = () => {
		if (modalConfirmRestore) {
			setmodalConfirmRestore(false);
		} else {
			setmodalConfirmRestore(true);
		}
	};

	const searchCompanies = async (inputValue) => {
		if (inputValue.length > 2) {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/companies/s/`,
					'POST',
					JSON.stringify({
						search: inputValue,
						format: 'select',
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					},
					false
				);

				if (response.companies) {
					return response.companies;
				} else {
					return [];
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const searchUsers = async (inputValue) => {
		if (inputValue.length > 2) {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/users/s/?admin=true`,
					'POST',
					JSON.stringify({
						search: inputValue,
						format: 'select',
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					},
					false
				);
				if (response.users) {
					return response.users;
				} else {
					return [];
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const [formState, inputHandler, setFormData] = useForm();

	useEffect(() => {
		const fetchProject = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/projects/${projectId}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setLoadedProject(response.project);
				setDefaultCompany(response.project.company);
				setDefaultSaleman(response.project.sold_by);
				setFormData(
					{
						type: {
							value: response.project.type,
							isValid: true,
						},
						name: {
							value: response.project.name,
							isValid: true,
						},
						description: {
							value: response.project.description,
							isValid: true,
						},
						status: {
							value: response.project.status,
							isValid: true,
						},
						domain_url: {
							value: response.project.domain.url,
							isValid: true,
						},
						company: {
							value: response.project.company,
							isValid: true,
						},
						// sold_by: {
						// 	value: response.project.sold_by,
						// 	isValid: true,
						// },
						// start_date: {
						// 	value: response.project.start_date ? formatDate(response.project.start_date) : formatDate(new Date()),
						// 	isValid: true,
						// },
					},
					true
				);
			} catch (error) {
				console.log(error);
			}
		};
		fetchProject();
	}, [sendRequest, projectId, setFormData, auth]);

	const formSubmitHandler = async (event) => {
		event.preventDefault();
		try {
			console.log(formState.inputs);
			await sendRequest(
				`${process.env.REACT_APP_API_URL}/projects/${projectId}`,
				'PATCH',
				JSON.stringify({
					type: formState.inputs.type.value,
					name: formState.inputs.name.value,
					description: formState.inputs.description.value,
					status: formState.inputs.status.value,
					domain: {
						url: formState.inputs.domain_url.value,
					},
					company: formState.inputs.company.value,
					// sold_by: formState.inputs.sold_by.value,
					// start_date: new Date(formState.inputs.start_date.value).toISOString()
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/projects/',
				noticeMsg: t('project.updated'),
				noticeType: 'success',
			});
		} catch (error) {
			console.log(error);
		}
	};

	const confirmDelete = async (event) => {
		event.preventDefault();
		try {
			const response = await sendRequest(
				`${process.env.REACT_APP_API_URL}/projects/${projectId}`,
				'DELETE',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			history.push({
				pathname: '/projects/',
				noticeMsg: response.message,
				noticeType: 'success',
			});
		} catch (error) {
			setmodalConfirmDelete(false);
		}
	};

	const confirmRestore = async (event) => {
		event.preventDefault();
		try {
			const response = await sendRequest(
				`${process.env.REACT_APP_API_URL}/projects/restore/${projectId}`,
				'PATCH',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			history.push({
				pathname: '/projects/',
				noticeMsg: response.message,
				noticeType: 'success',
			});
		} catch (error) {
			setmodalConfirmRestore(false);
		}
	};

	if (!project) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	const actions = [
		{
			title: t('common.cancel'),
			onClick: () => history.goBack()
		},
		{
			title: t('common.save'),
			style: 'filled-blue',
			flaticon: 'fi-rr-check',
			form: 'edit-project',
			type: 'submit',
			disabled: !formState.isValid
		}
	];

	let subactions = [
		{
			title: t('project.remove'),
			flaticon: 'fi-rr-trash',
			onClick: openConfirmDelete
		}
	];

	if (project && project.status === 'archived') {
		subactions = [
			{
				title: t('project.restore'),
				flaticon: 'fi-rr-time-past',
				onClick: openConfirmRestore
			}
		];
	}

	const tabs = [
		{
			title: t('common.general'),
			to: '/projects/' + projectId,
			status: 'active'
		},
		{
			title: t('common.integrations'),
			status: 'disabled'
		},
	];
	// if (!project) {
	// 	return <div>No project found</div>;
	// }
	let subdata;
	if (project) {
		subdata = [
			{
				title: project.company?.company_name || '',
				flaticon: 'fi-rr-briefcase',
				tooltip: t('project.company'),
				to: '/companies/' + project.company?.id
			},
			{
				title: project.domain.url ? project.domain.url.replace(/^https?:\/\//, '') : '',
				flaticon: 'fi-rr-globe',
				tooltip: t('project.domain')
			},
			{
				title: project.type,
				flaticon: 'fi-rr-square-code',
				tooltip: t('project.type')

			},
			{
				title: (project.created_by?.first_name || '') + ' ' + (project.created_by?.last_name || ''),
				flaticon: 'fi-rr-user',
				tooltip: t('common.createdby')
			}
		];
	}

	return (
		<ContentWrapper>
			{project && (
				<React.Fragment>
					<ContentHead>
						<PageHeader
							gobackOnClick={() => history.goBack()}
							title={project.name}
							subdata={subdata}
							actions={actions}
							subactions={subactions}
						/>
						<Tabs tabs={tabs} />
					</ContentHead>
					{errorMsg && <Notice type='error' message={errorMsg} />}
					{project && (
						<form onSubmit={formSubmitHandler} id='edit-project'>
							<FormGroup
								title={t('project.information')}
								subtitle={t('project.information-st')}
							>
								<Input
									id='name'
									element='input'
									type='text'
									placeholder={t('project.name-ph')}
									label={t('project.name')}
									onInput={inputHandler}
									validators={[VALIDATOR_REQUIRE()]}
									errorText='Please input a valid text'
									initialValid={formState.inputs.name.isValid}
									initialValue={formState.inputs.name.value}
								/>
								<Input
									id='description'
									element='input'
									type='text'
									placeholder={t('project.description-ph')}
									label={t('project.description')}
									onInput={inputHandler}
									validators={[]}
									initialValid={true}
									initialValue={formState.inputs.description.value}
								/>
							</FormGroup>
							<FormGroup
								title={t('project.status')}
								subtitle={t('project.status-st')}
							>
								{formState.inputs.status.value !== 'archived' &&
									<SelectWrapper
										onInput={inputHandler}
										id='status'
										label={t('project.status-assigned')}
										options={projectStatusesEdit}
										initialValid={true}
										initialValue={formState.inputs.status.value}
									/>
								}
								<SelectWrapper
									onInput={inputHandler}
									// initialValue={'web'}
									id='type'
									label='Project type'
									options={projectTypes}
									initialValid={true}
									initialValue={formState.inputs.type.value}
								/>
								<Input
									id='domain_url'
									element='input'
									type='url'
									placeholder={t('project.domain-ph')}
									label={t('project.domain')}
									onInput={inputHandler}
									validators={[VALIDATOR_REQUIRE()]}
									errorText={t('project.domain-err')}
									initialValid={true}
									initialValue={formState.inputs.domain_url.value}
								/>
							</FormGroup>
							<FormGroup
								title={t('project.company')}
								subtitle={t('project.company-st')}
							>
								<SelectAsyncWrapper
									onInput={inputHandler}
									id='company'
									label={t('project.company-assigned')}
									loadOptions={searchCompanies}
									initialValue={defaultCompany}
									defaultValue={defaultCompany}
									initialValid={true}
									getOptionLabel={(e) => e.name + ' (' + e.company_name + ')'}
									getOptionValue={(e) => e._id}
									isMulti={false}
								/>
							</FormGroup>
							{/* <FormGroup
								title='Sale'
								subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et commodo mauris, ut blandit diam. Fusce lobortis orci eget leo pharetra condimentum.'
							>
								<SelectAsyncWrapper
									onInput={inputHandler}
									id='sold_by'
									label='Project sold by'
									loadOptions={searchUsers}
									initialValue={defaultSaleman}
									defaultValue={defaultSaleman}
									initialValid={true}
									getOptionLabel={(e) =>
										e.first_name + ' ' + e.last_name + ' (' + e.email + ')'
									}
									getOptionValue={(e) => e._id}
									isMulti={false}
								/>
								<Input
									id='start_date'
									element='input'
									type='date'
									label='Start date'
									onInput={inputHandler}
									validators={[]}
									initialValid={formState.inputs.start_date.isValid}
									initialValue={formState.inputs.start_date.value}
								/>
							</FormGroup> */}
							<FormActions>
								<FormFooter
									actions={actions}
								/>
							</FormActions>
						</form>
					)}
					{!isLoading && payments && subscriptions && deals &&
						<Modal
							show={modalConfirmDelete}
							title={t('project.remove')}
							closeAction={openConfirmDelete}
							footer={
								<div className='modal-footer-actions'>
									{subscriptions.length === 0 && <ActionButton text={(payments.length > 0) ? t('common.confirm-archive') : t('common.confirm-remove')} style={`red-delete`} onClick={confirmDelete} />}
									<ActionButton text={t('common.close')} onClick={openConfirmDelete} />
								</div>
							}
						>
							{(subscriptions.length > 0) &&
								<React.Fragment>
									<Notice type='danger' message={t('project.danger-associated-subscriptions', {subscriptions: subscriptions.length, subscriptionsWord: subscriptions.length > 1 ? t('models.subscription-plur') : t('models.subscription-sing')})} />
								</React.Fragment>
							}
							{(deals.length > 0) &&
								<React.Fragment>
									<Notice type='alert' message={t('project.danger-associated-deals', {deals: deals.length})} />
								</React.Fragment>
							}
							{(payments.length > 0) &&
								<React.Fragment>
									<Notice type='alert' message={t('project.danger-associated-payments', {payments: payments.length})} />
								</React.Fragment>
							}
							{(payments.length > 0) &&
								<React.Fragment>
									<p>{t('project.notice-archive')}</p>
								</React.Fragment>
							}
							{(subscriptions.length === 0 && payments.length === 0) &&
								<React.Fragment>
									<Notice type='danger' message={t('common.action-irreversible')} />
									<p>{t('project.notice-remove')}</p>
								</React.Fragment>
							}

						</Modal>
					}
					{/* Fake modal */}
					{(!payments || !subscriptions || !deals || isLoading) &&
						<Modal
							show={modalConfirmDelete}
							title={t('project.remove')}
							closeAction={openConfirmDelete}
							footer={
								<div className='modal-footer-actions'>
									<ActionButton text={t('common.confirm-remove')} style={`red-delete`} />
									<ActionButton text={t('common.close')} onClick={openConfirmDelete} />
								</div>
							}
						>
							{isLoading &&
								<LoadingSpinner></LoadingSpinner>
							}
							<React.Fragment>
								<Notice type='danger' message={t('common.action-irreversible')} />
								<p>{t('project.notice-remove')}</p>
							</React.Fragment>

						</Modal>
					}
					{/* End fake modal */}
					{project &&
						<Modal
							show={modalConfirmRestore}
							title={t('project.restore')}
							closeAction={openConfirmRestore}
							footer={
								<div className='modal-footer-actions'>
									<ActionButton text={`${project.company.status === 'archived' ? t('project.restore-confirm-all') : t('project.restore-confirm')}`} style={`filled-blue`} onClick={confirmRestore} />
									<ActionButton text={t('common.close')} onClick={openConfirmRestore} />
								</div>
							}
						>
							{(project.company.status === 'archived') &&
								<React.Fragment>
									<Notice type='alert' message={t('project.danger-associated-company-archived')} />
								</React.Fragment>
							}
							{(project.company.status !== 'archived') &&
								<React.Fragment>
									<p>{t('project.notice-restore-parent')}</p>
								</React.Fragment>
							}
							{(project.company.status !== 'archived') &&
								<React.Fragment>
									<p>{t('project.notice-restore')}</p>
								</React.Fragment>
							}

						</Modal>
					}
				</React.Fragment>
			)}
		</ContentWrapper>
	);
};

export default EditProject;
