import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';

import ContentBox from '../../shared/components/Wrappers/ContentBox';
import MainTitle from '../../shared/components/Titles/MainTitle';
import Button from '../../shared/components/UIElements/Button';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ActionsWrapper from '../../shared/components/Wrappers/ActionsWrapper';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import ServerList from '../components/ServerList';
import Notice from '../../shared/components/UIElements/Notice';

import './ServersSettings.css';

import { useTranslation } from "react-i18next";

const ServerSettings = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [settings, setLoadedSettings] = useState();
	const history = useHistory();

	const [formState, inputHandler, setFormData] = useForm();

	const [servers, setServersData] = useState('');
	const [serversList, setServersList] = useState([]);

	const removeRow = (item) => (e) => {
		e.preventDefault();
		let serversTemp = [...servers];
		serversTemp.splice(item, 1);
		setServersData(serversTemp);
		formatFormStateServers(serversTemp);
	};

	const addRow = (item) => (e) => {
		e.preventDefault();
		const emptyRow = {
			name: '',
			ip: '',
		};
		let serversTemp = [...servers];
		serversTemp.push(emptyRow);
		setServersData(serversTemp);
		formatFormStateServers(serversTemp);
	};

	const formatFormStateServers = (servers) => {
		let serverList = [];
		servers.map((servers, item) => {
			serverList['name.' + item] = {
				value: formState.inputs['name.' + item]
					? formState.inputs['name.' + item].value
					: '',
				isValid: formState.inputs['name.' + item]
					? formState.inputs['name.' + item].isValid
					: false,
			};
			serverList['ip.' + item] = {
				value: formState.inputs['ip.' + item]
					? formState.inputs['ip.' + item].value
					: '',
				isValid: formState.inputs['ip.' + item]
					? formState.inputs['ip.' + item].isValid
					: false,
			};
			return serverList;
		});
		console.log(serverList);
		setServersList(serverList);
	};

	useEffect(() => {
		setFormData(
			{
				...serversList,
			},
			true
		);
	}, [serversList, inputHandler]);

	useEffect(() => {
		const fetchSettings = async () => {
			try {
				const settings = await sendRequest(
					process.env.REACT_APP_API_URL + '/settings/servers',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setLoadedSettings(settings);
				setServersData(settings.setting.value);
				// await formatFormStateServers(settings.setting.value);
				let serverList = [];
				settings.setting.value.map((servers, item) => {
					serverList['name.' + item] = {
						value: servers.name,
						isValid: true,
					};
					serverList['ip.' + item] = {
						value: servers.ip,
						isValid: true,
					};
					return serverList;
				});

				setFormData(
					{
						...serverList,
					},
					true
				);
			} catch (error) {
				console.log(error);
			}
		};
		fetchSettings();
	}, [sendRequest, setFormData]);

	const formSubmitHandler = async (event) => {
		event.preventDefault();
		let serverUpdateList = [];
		const currentList = formState.inputs;
		await Promise.all(
			Object.entries(currentList).map(([key, value], item) => {
				const formatKey = key.split('.');
				const _key = formatKey[0];
				const _item = formatKey[1];
				if (!serverUpdateList[_item]) {
					serverUpdateList[_item] = {};
				}
				serverUpdateList[_item][_key] = value.value;
				return serverUpdateList;
			})
		);
		try {
			await sendRequest(
				process.env.REACT_APP_API_URL + '/settings/',
				'POST',
				JSON.stringify({
					servers: serverUpdateList,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/settings/',
				noticeMsg: t('setting.updated'),
				noticeType: 'success',
			});
		} catch (error) {
			console.log(error);
		}
	};

	const confirmDelete = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				process.env.REACT_APP_API_URL + '/settings/restore/servers',
				'GET',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			history.push({
				pathname: '/settings/',
				noticeMsg: t('setting.restore'),
				noticeType: 'success',
			});
		} catch (error) { }
	};

	if (isLoading) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	if (!settings) {
		return (
			<ContentWrapper>
				<ContentHead>
					<MainTitle>{t('models.settings')}</MainTitle>
					<ActionsWrapper>
						<Button onClick={confirmDelete} className='delete'>
							{t('setting.restore')}
						</Button>
					</ActionsWrapper>
				</ContentHead>
				{errorMsg && <Notice type='error' message={errorMsg} />}
				<ContentBox>
					<div>{t('setting.restore-error')}</div>
				</ContentBox>
			</ContentWrapper>
		);
	}

	return (
		<ContentWrapper>
			<ContentHead>
				<MainTitle>{t('models.settings')}</MainTitle>
				<ActionsWrapper></ActionsWrapper>
			</ContentHead>
			{errorMsg && <Notice type='error' message={errorMsg} />}
			<ContentBox>
				{!isLoading && settings && (
					<form onSubmit={formSubmitHandler}>
						<FormGroup title='Servers'>
							<ServerList
								servers={servers}
								formState={formState}
								inputHandler={inputHandler}
								removeRow={removeRow}
								addRow={addRow}
							/>
						</FormGroup>
						<FormActions>
							<Button type='submit' disabled={!formState.isValid}>
								{t('common.save')}
							</Button>
						</FormActions>
					</form>
				)}
			</ContentBox>
		</ContentWrapper>
	);
};

export default ServerSettings;
