import React from 'react';

const MainTitle = (props) => {
	return (
		<div className='title-wrapper'>
			<div className='left'>
				<h1>{props.title}</h1>
				{props.subtitle &&
					<p>{props.subtitle}</p>
				}
			</div>
			<div className='right'>
				
			</div>
		</div>
	);
};

export default MainTitle;
