import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Overlay from './Overlay';

import './Modal.css';

const ModalOverlay = (props) => {
	const [isLoading, setIsLoading] = useState(true);
	const hookRef = useRef(null);
	const nodeRef = useRef(null);
	const { hook = 'modal-hook' } = props;

	useEffect(() => {
		hookRef.current = document.getElementById(hook);
		if (props.show && isLoading) {
			setIsLoading(false);
		}
	}, [hook, props.show, isLoading]);

	useEffect(() => {
		if (nodeRef.current && props.show) {
			nodeRef.current.focus();
		}
	}, [props.show]);
	
	const content = (
		<div className={`modal ${props.className ?? ''}`} style={props.style} ref={nodeRef}>
			<div>
				<header className={`modal-header ${props.className ?? ''}`}>
					<h3>{props.title}</h3>
				</header>
				<div className={`modal-content ${props.className ?? ''}`}>{props.children}</div>
				{props.footer && (
					<footer className={`modal-footer ${props.footerClass ?? ''}`}>
						{props.footer}
					</footer>
				)}
			</div>
		</div>
	);

	if (!hookRef.current) {
		return null;
	}

	return ReactDOM.createPortal(
		<CSSTransition
			in={props.show}
			timeout={0}
			mountOnEnter
			unmountOnExit
			classNames='modal'
			nodeRef={nodeRef}
		>
			{content}
		</CSSTransition>,
		hookRef.current,
		props.key
	);
};

const Modal = (props) => {
	// Block scroll
	useEffect(() => {
		document.body.style.overflow = '';
		if (props.show) {
			document.body.style.overflow = 'hidden';
		}
		if (!props.show) {
			document.body.style.overflow = '';
		}
	}, [props.show]);

	return (
		<React.Fragment>
			{props.show && <Overlay onClick={props.closeAction} />}
			<ModalOverlay {...props} />
		</React.Fragment>
	);
};

export default Modal;
