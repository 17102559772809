import React from 'react';


const FormDataDisabled = (props) => {

    return (
        <div className={`form-control form-data-disabled ${props.className || ''}`}>
            <label>{props.label}</label>
            <span>{props.children}</span>
        </div>
    )

};

export default FormDataDisabled;
