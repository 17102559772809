import React from 'react';
import { Link } from 'react-router-dom';

import './Button.css';

const Button = (props) => {
	if (props.href) {
		return (
			<a
				href={props.href}
				className={`button button-link ${props.className || ''} button-${
					props.size || 'default'
				}`}
			>
				{props.children}
			</a>
		);
	}
	if (props.to) {
		return (
			<Link
				to={props.to}
				className={`button button-link ${props.className || ''} button-${
					props.size || 'default'
				}`}
			>
				{props.children}
			</Link>
		);
	}
	return (
		<button
			className={`button button-normal ${props.className || ''} button-${
				props.size || 'default'
			}`}
			onClick={props.onClick}
            disabled={props.disabled}
			type={props.type || ''}
		>
			{props.children}
		</button>
	);
};

export default Button;
