import React, { useContext, useState } from 'react';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHistory } from 'react-router-dom';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Input from '../../shared/components/UIElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import SelectAsyncWrapper from '../../shared/components/UIElements/SelectAsyncWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import PageHeader from '../../shared/components/Titles/PageHeader';
import FormFooter from '../../shared/components/Forms/FormFooter';

import './NewCompany.css';
import SelectWrapper from '../../shared/components/UIElements/SelectWrapper';
import { companyPaymentMethods, companyPaymentPlatforms, companyStatuses, companyTaxes } from '../data/CompanyOptions';

import { useTranslation } from "react-i18next";

const NewCompany = (props) => {
	const { t, i18n } = useTranslation('common');
	const auth = useContext(AuthContex);
	const [isSepa, setIsSepa] = useState(false);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const history = useHistory();

	const changePaymentType = (event) => {
		if (event.value === 'sepa') {
			setIsSepa(true);
		} else {
			setIsSepa(false);
		}
	};

	const searchUsers = async (inputValue) => {
		if (inputValue.length > 2) {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/users/s/`,
					'POST',
					JSON.stringify({
						search: inputValue,
						format: 'select',
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					},
					false
				);
				if (response.users) {
					return response.users;
				} else {
					return [];
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const [formState, inputHandler] = useForm(
		{
			name: {
				value: '',
				isValid: true,
			},
			company_name: {
				value: '',
				isValid: true,
			},
			vat: {
				value: '',
				isValid: true,
			},
			tax: {
				value: '',
				isValid: true,
			},
			address_1: {
				value: '',
				isValid: true,
			},
			address_2: {
				value: '',
				isValid: true,
			},
			city: {
				value: '',
				isValid: true,
			},
			postal_code: {
				value: '',
				isValid: true,
			},
			country: {
				value: '',
				isValid: true,
			},
			payment_type: {
				value: '',
				isValid: true,
			},
			iban: {
				value: '',
				isValid: true,
			},
			reference: {
				value: '',
				isValid: true,
			},
			status: {
				value: 'active',
				isValid: true,
			},
			// start_date: {
			// 	value: formatDate(new Date()),
			// 	isValid: true,
			// },
		},
		false
	);

	const formSubmitHandler = async (event) => {
		event.preventDefault();

		try {
			await sendRequest(
				process.env.REACT_APP_API_URL + '/companies/',
				'POST',
				JSON.stringify({
					name: formState.inputs.name.value,
					company_name: formState.inputs.company_name.value,
					vat: formState.inputs.vat.value,
					tax: formState.inputs.tax.value,
					address: {
						address_1: formState.inputs.address_1.value,
						address_2: formState.inputs.address_2.value,
						city: formState.inputs.city.value,
						postal_code: formState.inputs.postal_code.value,
						country: formState.inputs.country.value,
					},
					payment: {
						platform: formState.inputs.platform.value,
						method: formState.inputs.method.value,
						iban: formState.inputs.iban.value,
						reference: formState.inputs.reference.value,
					},
					users: formState.inputs.users.value,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/companies/',
				noticeMsg: t('company.added'),
				noticeType: 'success',
			});
		} catch (error) { }
	};


	const actions = [
		{
			title: 'Cancel',
			// to: '/companies/' + companyId,
			onClick: () => history.goBack()
		},
		{
			title: t('company.add'),
			style: 'filled-blue',
			flaticon: 'fi-rr-check',
			form: 'add-company',
			type: 'submit',
			disabled: !formState.isValid
		}
	];

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					gobackOnClick={() => history.goBack()}
					title={t('company.add-t')}
					actions={actions}
					separator={true}
				/>
			</ContentHead>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			<form className='add-company' id='add-company' onSubmit={formSubmitHandler}>
				<FormGroup
					title={t('company.information')}
					subtitle={t('company.information-st')}
				>
					<Input
						id='name'
						element='input'
						type='text'
						placeholder={t('company.commercial-name-ph')}
						label={t('company.commercial-name')}
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
						errorText={t('forms.common.error-valid-text')}
					/>
					<Input
						id='company_name'
						element='input'
						type='text'
						placeholder={t('company.legal-name-ph')}
						label={t('company.legal-name')}
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
					/>
					<Input
						id='vat'
						element='input'
						type='text'
						placeholder={t('company.vat-number-ph')}
						label={t('company.vat-number')}
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
						errorText={t('company.vat-number-err')}
					/>
					<SelectWrapper
						onInput={inputHandler}
						id='tax'
						label={t('company.tax-rate')}
						initialValid={true}
						initialValue={companyTaxes[0].value}
						options={companyTaxes}
					/>
				</FormGroup>
				<FormGroup
					title={t('company.address')}
					subtitle={t('company.address-st')}
				>
					<Input
						id='address_1'
						element='input'
						type='text'
						placeholder={t('company.address-1-ph')}
						label={t('company.address-1')}
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
					/>
					<Input
						id='address_2'
						element='input'
						type='text'
						placeholder={t('company.address-2-ph')}
						label={t('company.address-2')}
						onInput={inputHandler}
						validators={[]}
						initialValid={true}
					/>
					<Input
						id='postal_code'
						element='input'
						type='text'
						placeholder={t('company.postal-code-ph')}
						label={t('company.postal-code')}
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
					/>
					<Input
						id='city'
						element='input'
						type='text'
						placeholder={t('company.city-ph')}
						label={t('company.city')}
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
					/>
											<Input
							id='country'
							element='input'
							type='text'
							placeholder={t('company.country-ph')}
							label={t('company.country')}
							onInput={inputHandler}
							validators={[]}
						/>
				</FormGroup>
				<FormGroup
						title={t('company.payment')}
						subtitle={t('company.payment-st')}
					>
					<SelectWrapper
						onInput={inputHandler}
						id='platform'
						label={t('company.payment-platform')}
						initialValid={true}
						options={companyPaymentPlatforms}
					/>
					<SelectWrapper
						onChange={changePaymentType}
						onInput={inputHandler}
						id='method'
						label={t('company.payment-method')}
						initialValid={true}
						options={companyPaymentMethods}
					/>
					{isSepa && (
						<React.Fragment>
							<Input
								id='iban'
								element='input'
								type='text'
								placeholder={t('company.payment-iban-ph')}
								label={t('company.payment-iban')}
								onInput={inputHandler}
								validators={[]}
								initialValid={true}
							/>
							<Input
								id='reference'
								element='input'
								type='text'
								placeholder={t('company.payment-reference')}
								label={t('company.payment-reference')}
								onInput={inputHandler}
								validators={[]}
								initialValid={true}
							/>
						</React.Fragment>
					)}
				</FormGroup>
				<FormGroup
						title={t('company.access')}
						subtitle={t('company.access-st')}
					>
					<SelectAsyncWrapper
						onInput={inputHandler}
						id='users'
						label={t('company.access-users')}
						loadOptions={searchUsers}
						initialValid={true}
						getOptionLabel={(e) =>
							e.first_name + ' ' + e.last_name + ' (' + e.email + ')'
						}
						getOptionValue={(e) => e._id}
						isMulti={true}
					/>
				</FormGroup>
				{/* <FormGroup
					title='Status'
					subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et commodo mauris, ut blandit diam. Fusce lobortis orci eget leo pharetra condimentum.'
				>
					<SelectWrapper
						onInput={inputHandler}
						id='status'
						label='Status'
						initialValid={formState.inputs.status.isValid}
						initialValue={formState.inputs.status.value}
						options={companyStatuses}
					/>
					<Input
						id='start_date'
						element='input'
						type='date'
						label='Start date'
						onInput={inputHandler}
						validators={[]}
						initialValid={formState.inputs.start_date.isValid}
						initialValue={formState.inputs.start_date.value}
					/>
				</FormGroup> */}
				<FormActions>
					<FormFooter
						actions={actions}
					/>
					{/* <Button type='submit' disabled={!formState.isValid}>
							Add company
						</Button> */}
				</FormActions>
			</form>
		</ContentWrapper>
	);
};

export default NewCompany;
