import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import MainTitle from '../../shared/components/Titles/MainTitle';
import ActionsWrapper from '../../shared/components/Wrappers/ActionsWrapper';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import Notice from '../../shared/components/UIElements/Notice';
import SettingsList from '../components/SettingsList';

import './Settings.css';
import PageHeader from '../../shared/components/Titles/PageHeader';

import { useTranslation } from "react-i18next";
const Settings = (props) => {
	const { t } = useTranslation('common');
	const location = useLocation();
	const [noticeMsg, setNoticeMsg] = useState();
	const [noticeType, setnoticeType] = useState();

	useEffect(() => {
		setNoticeMsg(location.noticeMsg);
		setnoticeType(location.noticeType);
	}, [location]);

	const settingsList = [
		{
			slug: 'main',
			name: t('setting.main-settings'),
			desc: t('setting.main-settings-st'),
			icon: 'fi-br-gears'
		},
		{
			slug: 'servers',
			name: t('setting.server-settings'),
			desc: t('setting.server-settings-st'),
			icon: 'fi-br-cloud'
		},
		// {
		// 	slug: 'projects',
		// 	name: 'Projects',
		// 	desc: 'Settings related to projects',
		// 	icon: 'world'
		// },
		{
			slug: '',
			name: t('setting.api-settings'),
			desc: t('setting.api-settings-st'),
			icon: 'fi-br-bolt'
		},
	]

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					title={t('models.settings')}
					separator={true}
				// subtitle='List of all the client’s companies.'
				// actions={actions}
				// subactions={subactions}
				/>
			</ContentHead>
			{noticeMsg && <Notice type={noticeType} message={noticeMsg} />}
			<SettingsList settings={settingsList} />
		</ContentWrapper>
	);
};

export default Settings;
