import React from "react";

import './DashboardSection.css';

const DashboardSection = (props) => {
    return (
        <div className={`dashboard-section ${props.className || ''}`} >
            <h3>{ props.title }</h3>
            <p>{ props.subtitle }</p>
        </div >
    );
};

export default DashboardSection;
