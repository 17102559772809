import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import Status from '../../shared/components/UIElements/Status';

import Tooltip from '../../shared/components/UIElements/Tooltip';
import { subscriptionStatuses } from '../data/SubscriptionOptions';
import Icon from '../../shared/components/UIElements/Icon';

const SubscriptionItem = (props) => {

	const statusTable = subscriptionStatuses;
	const status = statusTable
		.filter((status) => status.value === props.status)
		.map((status) => status);

	return (
		<React.Fragment>
			<Link to={`/subscriptions/${props.id}`} key={props.id} className='item no-link'>
				<div className={props.header[0] && props.header[0]?.style}>
					<span>{props.project}{props.subscription.tasks && (!props.subscription.tasks.holded || !props.subscription.tasks.stripe) && <Status icon='fi-sr-triangle-warning' className='orange tooltip ml-10'><Tooltip>Pending to set Stripe or Holded, please check tasks</Tooltip></Status>}</span>
				</div>
				<div className={props.header[1] && props.header[1]?.style}>
					<Status icon='fi-rr-arrows-repeat' status={status[0]} />
				</div>
				<div className={props.header[2] && props.header[2]?.style}>
					<span className='tooltip'>
						{props.services.length} {props.services.length === 1 ? 'service' : 'services'}
						<Tooltip>
							{props.services.map((service) => <span key={service.id} className='block pf-5'>{service.name}</span>)}
						</Tooltip>
					</span>
				</div>
				<div className={props.header[3] && props.header[3]?.style}>
					<span>{props.price}</span>
				</div>
				<div className={`actions ${props.header[4] && props.header[4]?.style}`}>
					{props.status !== 'cancelled' &&
						<ActionButton to={`/subscriptions/edit/${props.id}`} flaticon='fi-br-pencil' className='inline-grid table-action-icon' />
					}
					<ActionButton to={`/subscriptions/${props.id}`} flaticon='fi-br-menu-dots' className='inline-grid table-action-icon' />
				</div>
			</Link>
		</React.Fragment >
	);
};

export default SubscriptionItem;
