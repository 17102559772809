import React from 'react';
import PayoutItem from './PayoutItem';

const PayoutList = (props) => {
	return (
		<React.Fragment>
				{props.payouts.map((payout) => {
					return (
						<PayoutItem
							key={payout.id}
							header={props.header}
							id={payout.id}
							payout={payout}
						/>
					);
				})}
		</React.Fragment>
	);
};

export default PayoutList;
