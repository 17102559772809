import React, { useState, useEffect, useContext } from 'react';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useHistory, useLocation } from 'react-router-dom';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ItemList from '../../shared/components/UIElements/ItemList';
import Notice from '../../shared/components/UIElements/Notice';
import PageHeader from '../../shared/components/Titles/PageHeader';
import Filters from '../../shared/components/Results/Filters';

import './Deals.css';
import { projectStatuses } from '../../projects/data/ProjectOptions';
import DealList from '../components/DealList';
import Modal from '../../shared/components/UIElements/Modal';
import ActionButton from '../../shared/components/UIElements/ActionButton';

import { useTranslation } from "react-i18next";

const Deals = (props) => {
	const { t, i18n } = useTranslation('common');
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [deals, setDeals] = useState();
	const auth = useContext(AuthContex);
	const location = useLocation();
	const history = useHistory();
	const [noticeMsg, setNoticeMsg] = useState();
	const [noticeType, setnoticeType] = useState();
	const [modalConfirmDelete, setmodalConfirmDelete] = useState(false);
	const [modalDeal, setModalDeal] = useState();
	const openConfirmDelete = (deal) => {
		if (modalConfirmDelete) {
			setModalDeal();
			setmodalConfirmDelete(false);
		} else {
			setModalDeal(deal);
			setmodalConfirmDelete(true);
		}
	}

	// Pagination, Search & Filter
	const [totalResults, setTotalResults] = useState(0);
	const [perPageLimit, setPerPageLimit] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	// const [query, setQuery] = useState({status: 'pending'});
	const [query, setQuery] = useState({});
	const fetchResultsQuery = async (query) => {
		try {
			const response = await sendRequest(
				process.env.REACT_APP_API_URL + '/deals/?' + query,
				'GET',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			setDeals(response.deals);
			setTotalResults(response.count);
		} catch (error) {
			console.log(error);
		}
	};
	const filters = {
		queryFn: fetchResultsQuery,
		setQueryFn: setQuery,
		query: query,
		totalRes: totalResults,
		limitRes: perPageLimit,
		currentPage: currentPage,
		setCurrentPage: setCurrentPage,
		filtersStyle: 'buttons',
		filtersData: [
			{
				id: 'status',
				type: 'button',
				label: t('deal.status-all'),
				value: '',
			},
			{
				id: 'status',
				type: 'button',
				label: t('deal.status-pending'),
				value: 'pending',
			},
			{
				id: 'status',
				type: 'button',
				label: t('deal.status-paid'),
				value: 'paid',
			},

		]
	};
	// End Pagination, Search & Filter

	useEffect(() => {
		setNoticeMsg(location.noticeMsg);
		setnoticeType(location.noticeType);
	}, [location]);

	useEffect(() => {
		const fetchDeals = async () => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_API_URL + '/deals/?' + new URLSearchParams(query).toString(),
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setDeals(response.deals);
				setTotalResults(response.count);
				setPerPageLimit(response.per_page);
			} catch (error) {
				console.log(error);
			}
		};
		fetchDeals();
	}, [sendRequest, auth]);

	const deleteDeal = async (deal) => {
		try {
			const response = await sendRequest(
				`${process.env.REACT_APP_API_URL}/deals/${deal.id}`,
				'DELETE',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			history.push({
				pathname: '/deals/',
				noticeMsg: response.message,
				noticeType: 'success',
			});
			fetchResultsQuery(new URLSearchParams(query).toString());
		} catch (error) {
			console.log(error)
		}
	};

	let itemsHeader = [
		{
			title: t('tables.project'),
		},
		{
			title: t('tables.name'),
		},
		{
			title: t('tables.status'),
			style: 'flex-regular'
		},
		{
			title: t('tables.amount'),
			style: 'flex-regular text-align-right'
		},
		{
			title: t('tables.payments'),
			style: 'flex-regular text-align-right'
		},
		{
			title: t('tables.pending'),
			style: 'flex-regular text-align-right'
		},
		{
			title: t('tables.expenses'),
			style: 'flex-regular text-align-right'
		},
		{
			title: t('tables.actions'),
			style: 'flex-small'
		}];

	const hideColumnsIndex = [1, 5];

	if (hideColumnsIndex) {
		itemsHeader = itemsHeader.map((item, index) => {
			const style = item.style || '';
			const hiddenStyle = hideColumnsIndex.includes(index) ? 'hidden' : '';
			return { ...item, style: `${style} ${hiddenStyle}`.trim() };
		});
	}
	// const actions = [
	// 	{
	// 		title: 'Add project',
	// 		flaticon: 'fi-br-plus-small',
	// 		style: 'filled-blue',
	// 		to: '/projects/new'
	// 	}
	// ];

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					title='Deals'
				// subtitle='List of all the client’s companies.'
				// actions={actions}
				// subactions={subactions}
				/>
				{auth.role !== 'user' && <Filters pagination={true} filter={true} filters={filters} search={true} />}
			</ContentHead>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{noticeMsg && <Notice type={noticeType} message={noticeMsg} />}
			<ItemList
				header={itemsHeader}
				actions={true}
				columns={9}
				template='boxed'
				align='center'
			>
				{!isLoading && deals && <DealList subtotals={true} deals={deals} header={itemsHeader} deleteDeal={openConfirmDelete} />}
			</ItemList>
			{(totalResults > perPageLimit) && <Filters pagination={true} filters={filters} />}
			{!isLoading && modalDeal &&
				<Modal
					show={modalConfirmDelete}
					title={t('deal.remove')}
					closeAction={openConfirmDelete}
					footer={
						<div className='modal-footer-actions'>
							<ActionButton text={t('common.confirm-remove')} style={`red-delete`} onClick={() => deleteDeal(modalDeal)} />
							<ActionButton text={t('common.close')} onClick={openConfirmDelete} />
						</div>
					}
				>
					{isLoading &&
						<LoadingSpinner></LoadingSpinner>
					}
					<React.Fragment>
						<Notice type='danger' message={t('common.action-irreversible')} />
						<p>{t('deal.notice-remove')}</p>
					</React.Fragment>
				</Modal>
			}
		</ContentWrapper>
	);
};

export default Deals;
