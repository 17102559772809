import React from "react";

import './DashboardBox.css';

const DashboardBox = (props) => {
    return (
        <div className={`dashboard-box ${props.className || ''}`}>
            <div className='dashboard-box-title'>
                <span>{props.title}</span>
                {props.action && <span>{props.action}</span>}
            </div>
            <div className='dashboard-box-content'>
                {props.children}
            </div>
        </div>
    );
};

export default DashboardBox;
