import React from 'react';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import formatDate from '../../shared/utils/dates';
import NoteObject from './NoteObject';
import Status from '../../shared/components/UIElements/Status';
import Tooltip from '../../shared/components/UIElements/Tooltip';

import { capitalizeText } from '../../shared/utils/text';
import NoteModal from './NoteModal';
import NoteAssigned from './NoteAssigned';
import { notePreventRemove } from '../data/NoteOptions';

import { useTranslation } from "react-i18next";

const NoteItem = (props) => {

	const { t, i18n } = useTranslation('common');
	return (
		<React.Fragment>
			<div className='item' key={props.id}>
				<div className={props.header[0] && props.header[0].style}>
					{formatDate(props.date, 'DD/MM/YYYY')}
				</div>
				<div className={props.header[1] && props.header[1].style}>
					<NoteModal note={props.note} limit={40} />
				</div>
				<div className={props.header[2] && props.header[2].style}>
					<Status status={props.status} text={capitalizeText(props.status)} />
				</div>
				<div className={props.header[3] && props.header[3].style}>
					<NoteObject object={props.note} />
				</div>
				<div className={props.header[4] && props.header[4].style}>
					<NoteAssigned assigned_to_user={props.assigned_to_user} assigned_to_group={props.assigned_to_group} />
					{/* {props.created_by?.first_name} {props.created_by?.last_name} */}
				</div>
				<div className={`actions ${props.header[5] && props.header[5].style}`}>
					{props.status === 'pending' && <ActionButton onClick={(e) => props.completeTask(props.note)} flaticon='fi-br-check' className='inline-grid table-action-icon tooltip'><Tooltip>{t('note.task-complete')}</Tooltip></ActionButton>}
					{!notePreventRemove.includes(props.note.feature) &&
						<>
							{props.status !== 'archived' && <ActionButton onClick={(e) => props.archiveTask(props.note)} flaticon='fi-br-box' className='inline-grid table-action-icon tooltip'><Tooltip>{t('note.task-archive')}</Tooltip></ActionButton>}
							{props.status === 'archived' && <ActionButton onClick={(e) => props.restoreTask(props.note)} flaticon='fi-br-time-past' className='inline-grid table-action-icon tooltip'><Tooltip>{t('note.task-restore')}</Tooltip></ActionButton>}
						</>
					}
					{/* <ActionButton onClick={(e) => props.deleteTask(props.note)} flaticon='fi-br-trash' className='inline-grid table-action-icon tooltip'><Tooltip>Archive task</Tooltip></ActionButton> */}
				</div>
			</div>
		</React.Fragment>
	);
};

export default NoteItem;
