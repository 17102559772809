import React from 'react';

import Icon from '../../shared/components/UIElements/Icon';
import { Link, NavLink } from 'react-router-dom';

import './SettingsList.css';

const SettingsList = (props) => {

	return (
		<React.Fragment>
			<div className='settings-list grid col-3'>
				{props.settings.map((setting, item) => {
					return (
						<Link to={`/settings/${setting.slug}`} className='no-link'>
							<div key={item} className='setting-card'>
								<div className='setting-card-icon'>
									<Icon flaticon={setting.icon} />
								</div>
								<div className='setting-card-content'>
									<div className='setting-card-content-name'>
										{setting.name}
									</div>
									<div className='setting-card-content-desc'>
										{setting.desc}
									</div>
								</div>
							</div>
						</Link>
					);
				})}
			</div>
		</React.Fragment>
	);
};

export default SettingsList;
