import React from "react";

import './DataTable.css';

const DataTable = (props) => {
    return (
        <div className={`data-table ${props.className || ''}`} >
            { props.children }
        </div >
    );
};

export default DataTable;
