import React from 'react';

import Icon from '../UIElements/Icon';
import ActionButton from '../UIElements/ActionButton';

import './Empty.css';

const Empty = (props) => {
	let icon;
	const action = props.action;
	switch (props.type) {
		case 'success':
			icon = 'fi-rr-check';
			break;
		case 'error':
			icon = 'fi-rr-cross-circle';
			break;
		case 'alert':
			icon = 'fi-rr-triangle-warning';
			break;
		case 'danger':
			icon = 'fi-rr-triangle-warning';
			break;
		case 'info':
			icon = 'fi-rr-info';
			break;
		default:
			break;
	}
	return (
		<div className={`empty-results ${props.type}-message ${props.className || ''}`}>
			<Icon flaticon={icon} />
			<span>{props.message}</span>
			{action &&
				<ActionButton to={action.to} className={action.className} flaticon={action.flaticon} text={action.title} style={action.style} type={action.type} form={action.form} onClick={action.onClick} disabled={action.disabled} />
			}
		</div>
	);
};

export default Empty;
