import React from "react";

import './LoadingSpinner.css';

const LoadingSpinner = props => {
    return(
        <div className={`loading-spinner ${props.withOverlay ? 'loading-spinner__overlay' : ''} ${props.type}`}>
            <i className='fi fi-rr-spinner'></i>
            {/* <i className="fi fi-rr-loading"></i> */}
        </div>
    );
}

export default LoadingSpinner;