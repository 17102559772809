import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContex } from '../../context/auth-context';
import { useTranslation } from "react-i18next";

const NavLinks = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);

	return (
		<ul className={props.className}>
			{auth.isLoggedIn && (
				<React.Fragment>
					<li>
						<NavLink to='/logout' onClick={auth.logout}>{t('user.logout')}</NavLink>
					</li>
				</React.Fragment>
			)}
			{!auth.isLoggedIn && (
				<li>
					<NavLink to='/login'>{t('user.login')}</NavLink>
				</li>
			)}
		</ul>
	);
};

export default NavLinks;
