import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import Status from '../../shared/components/UIElements/Status';

import formatDate from '../../shared/utils/dates';
import Tooltip from '../../shared/components/UIElements/Tooltip';

import { useTranslation } from "react-i18next";

const CompanyItem = (props) => {
	const { t, i18n } = useTranslation('common');
	return (
		<React.Fragment>
			<Link to={`/companies/${props.id}`} key={props.id} className='item no-link'>
				<div className={props.header[0] && props.header[0].style}>
					<span>
						{props.name}
						{props.status === 'archived' &&
							<Status icon='fi fi-br-box' className='ml-10 tooltip' color='grey' text=''>
								<Tooltip>
									{t('company.archived')}
								</Tooltip>
							</Status>
						}
					</span>
				</div>
				<div className={props.header[1] && props.header[1].style}>
					<span>{props.company_name}</span>
				</div>
				<div className={props.header[2] && props.header[2].style}>
					<span>{props.vat}</span>
				</div>
				<div className={props.header[3] && props.header[3].style}>
					<span>{formatDate(props.date, 'DD/MM/YYYY')}</span>
				</div>
				<div className={props.header[4] && props.header[4].style}>
					<Status icon='fi-rr-globe' className='tooltip mw' color={`${props.projects.length > 0 ? 'blue' : ''}`} text={`${props.projects.length}`}>
						{props.projects.length > 0 &&
							<Tooltip>
								{props.projects.map((project) => <span key={project.id} className='block pf-5'>{project.name}</span>)}
							</Tooltip>
						}
					</Status>
				</div>
				<div className={`actions ${props.header[5] && props.header[5].style}`}>
					<ActionButton to={`/companies/edit/${props.id}`} flaticon='fi-br-pencil' className='inline-grid table-action-icon' />
					<ActionButton to={`/companies/${props.id}`} flaticon='fi-br-menu-dots' className='inline-grid table-action-icon' />
				</div>
			</Link>
		</React.Fragment>
	);
};

export default CompanyItem;
