import React from 'react';
import ActionButton from '../UIElements/ActionButton';

import './Pagination.css';
import { useTranslation } from "react-i18next";

const Pagination = (props) => {

    const { t } = useTranslation('common');
    const lastPage = Math.ceil(props.pagination.count / props.pagination.per_page);

    return (
        <div className='pagination'>
            <div className='count'>
                {props.pagination.count || 0} {t('common.nav-results')}
            </div>
            <ActionButton text={t('common.nav-previous')} style={props.pagination.currentPage === 1 ? 'disabled' : ''} onClick={props.pagination.currentPage === 1 ? () => {} : () => props.pagination.setCurrentPage(props.pagination.currentPage - 1)} />
            {/* <ActionButton text={`Page ${props.pagination.currentPage}/${lastPage}`} style='disabled' /> */}
            <div className='count'>
            {t('common.nav-page')} {props.pagination.currentPage || 1} {t('common.nav-of')} {lastPage || 1}
            </div>
            <ActionButton text={t('common.nav-next')} style={props.pagination.currentPage >= lastPage ? 'disabled' : ''} onClick={props.pagination.currentPage >= lastPage ? () => {} : () => props.pagination.setCurrentPage(props.pagination.currentPage + 1)} />
        </div>
    );
};

export default Pagination;