import React from 'react';
import ProjectItem from './ProjectItem';

const ProjectList = (props) => {
	return (
		<React.Fragment>
			{props.projects.map((project) => {
				return (
					<ProjectItem
						key={project.id}
						id={project.id}
						name={project.name}
						status={project.status}
						domain={project.domain.url}
						header={props.header}
					/>
				);
			})}
		</React.Fragment>
	);
};

export default ProjectList;
