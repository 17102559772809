import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import './index.css';
import App from './App';

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./lang/en/common.json";
import common_es from "./lang/es/common.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en', 
  resources: {
      en: {
          common: common_en
      },
      es: {
          common: common_es
      },
  },
});

//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
