import React from 'react';

import formatMoney from '../../shared/utils/money';

import './ServiceTotals.css';

import { useTranslation } from "react-i18next";

const ServiceTotals = (props) => {
	const { t } = useTranslation('common');
	return (
		<React.Fragment>
			{props.price && props.type &&
				<div className='service-total'>
					<span>{t('common.total')}</span>
					<span>{formatMoney(props.price)}€{props.type === 'recurring' ? '/' + t('service.month-lc') : ''}</span>
				</div>
			}
		</React.Fragment>
	);
};

export default ServiceTotals;
