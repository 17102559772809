import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../../shared/components/UIElements/ActionButton';

import Tooltip from '../../shared/components/UIElements/Tooltip';
import formatMoney from '../../shared/utils/money';

const PayoutItem = (props) => {

	return (
		<React.Fragment>
			<Link to={`/payouts/${props.id}`} key={props.id} className='item no-link'>
				<div className={props.header[0] && props.header[0]?.style}>
					<span>{props.payout.month}</span>
				</div>
				<div className={props.header[1] && props.header[1]?.style}>
					<span>{props.payout.year}</span>
				</div>
				<div className={props.header[2] && props.header[2]?.style}>
					<span className='tooltip'>
						{props.payout.deals.length} {props.payout.deals.length === 1 ? 'deal' : 'deals'}
						<Tooltip>
							{props.payout.deals.map((deal) => <span key={deal.id} className='block pf-5'>{deal.name}</span>)}
						</Tooltip>
					</span>
				</div>
				<div className={props.header[3] && props.header[3]?.style}>
					<span>{formatMoney(props.payout.amount)}</span>
				</div>
				<div className={`actions ${props.header[4] && props.header[4]?.style}`}>
					<ActionButton to={`/payouts/${props.id}`} flaticon='fi-br-menu-dots' className='inline-grid table-action-icon' />
				</div>
			</Link>
		</React.Fragment >
	);
};

export default PayoutItem;
