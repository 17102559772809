import React, { useEffect, useState } from "react";
import Input from "../UIElements/Input";
import { useForm } from "../../hooks/form-hook";
import ActionButton from "../UIElements/ActionButton";

import './FieldRepeater.css';
import Empty from "../Results/Empty";
import formatDate from "../../utils/dates";
import { useTranslation } from "react-i18next";

const FieldRepeater = (props) => {
    const { t } = useTranslation('common');
    // const [formState, inputHandler, setFormData] = useForm();

    const inputHandler = props.data.inputHandler;
    const formState = props.data.formState;
    const setFormData = (e) => props.data.setFormData(e);
    const setParentData = (e) => props.data.setParentData(e);
    const fields = props.data.fields;
    const initialObject = Object.keys(fields).reduce((acc, curr) => {
        acc[curr] = fields[curr].value;
        return acc;
    }, {});

    const generateUniqueId = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    let loadedObject = [{ ...initialObject, id: generateUniqueId() }];
    if(props.data.initialEmpty){
        loadedObject = [];
    }
    if(props.data.initialValues){
        loadedObject = props.data.initialValues.map(obj => ({...obj, id: generateUniqueId()}));;
    }
    const [objects, setObjects] = useState(loadedObject);

    const addObject = () => {
        const id = generateUniqueId();
        const newObject = { ...initialObject, id };
        setObjects([...objects, newObject]);
        Object.keys(fields).forEach((field) => {
            inputHandler(`${field}-${id}`, '', false)
        });
    };

    const removeObject = (objectId) => {
        setObjects((prevObjects) => prevObjects.filter((object) => object.id !== objectId));
        const newFormData = { ...formState };
        Object.keys(fields).forEach((field) => {
            delete newFormData[`${field}-${objectId}`];
        });
        setFormData(newFormData.inputs, newFormData.isValid);
    };

    const setStructuredData = (structuredData) => {
        setParentData(structuredData);
    };

    useEffect(() => {
        let structuredData = objects.map((object) => {
            let newObj = {};
            Object.keys(fields).forEach((field) => {
                if(field.type === 'date' && formState.inputs[`${field}-${object.id}`]?.value){
                    newObj[field] = new Date(formState.inputs[`${field}-${object.id}`]?.value).toISOString();
                }else{
                    newObj[field] = formState.inputs[`${field}-${object.id}`]?.value;
                }
            })
            return newObj;
        })
        setStructuredData(structuredData);
    }, [formState.inputs, objects]);

    return (
        <div className={`repeater-container ${props.className || ''}`}>
            <div className='repeater-items'>
                {objects && objects.map((object, index) => (
                    <div className='repeater-item' key={`${object.id}`}>
                        {fields && Object.keys(fields).map((field, index) => (
                            <Input
                                key={`${field}-${object.id}`}
                                id={`${field}-${object.id}`}
                                element='input'
                                type={fields[field].type}
                                className={fields[field].className ?? ''}
                                placeholder={fields[field].placeholder}
                                label={fields[field].label}
                                onInput={inputHandler}
                                // onInputAditional={handleInputChange}
                                // handleRepeaterHandler={handleInputChange}
                                validators={fields[field].validators}
                                errorText={fields[field].errorText}
                                initialValid={fields[field].initialValid}
                                initialValue={(fields[field].type === 'date' && object[field]) ? formatDate(object[field]) : object[field]}
                                checked={object[field]}
                                // initialValue={fields[field].value}
                            />
                        ))}
                        <div className="repeater-actions">
                            {objects.length > (props.minObjects ?? 0) &&
                                <ActionButton flaticon='fi-br-trash' type='button' className='remove' onClick={() => removeObject(object.id)} />
                            }
                        </div>
                    </div>
                ))}
                {objects && objects.length === 0 &&
                    <Empty type='info' className='mb-10' message={t('common.not-found')} />
                }
            </div>
            <ActionButton text={t('common.add-new')} type='button' flaticon='fi-br-plus-small' onClick={addObject} />
        </div>
    );
};

export default FieldRepeater;