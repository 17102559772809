import React from 'react';


import './InputSolo.css';
import Tooltip from './Tooltip';
import Icon from './Icon';
import InfoTooltip from './InfoTooltip';

const InputSolo = (props) => {

    const element =
        props.type === 'select' ? (
            <div className={`input-solo ${props.className || ''}${props.type ? ' type-' + props.type : ''}`}>
                {props.label && (<label htmlFor={props.id}><span>{props.label}</span>{props.infoLabel && <InfoTooltip>{props.infoLabel}</InfoTooltip>}</label>)}
                <select name={props.id} id={props.id} onChange={props.onChange}>
                    {props.options.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
        ) : props.type === 'checkbox' ? (
            <div className={`input-solo ${props.className || ''}${props.type ? ' type-' + props.type : ''}`}>
                {props.label && (<label htmlFor={props.id}><span>{props.label}</span>{props.infoLabel && <InfoTooltip>{props.infoLabel}</InfoTooltip>}</label>)}
                <div className='cb-container'>
                    <input
                        id={props.id}
                        type={props.type}
                        placeholder={props.placeholder}
                        className={`${props.className || ''}${props.style || ' cb-tgl-light'}`}
                        onChange={props.onChange}
                        value={props.value}
                        checked={props.checked}
                    />
                    <label htmlFor={props.id} className='cb-tgl'></label>
                    {props.text &&
                    <label htmlFor={props.id}>{props.text}</label>}
                </div>
            </div>
        ) : (
            <div className={`input-solo ${props.className || ''}${props.type ? ' type-' + props.type : ''}`}>
                {props.label && (<label htmlFor={props.id}><span>{props.label}</span>{props.infoLabel && <InfoTooltip>{props.infoLabel}</InfoTooltip>}</label>)}
                <input
                    id={props.id}
                    type={props.type}
                    // value={props.value}
                    placeholder={props.placeholder}
                    className={props.className}
                    onChange={props.onChange}
                />
            </div>
        );

    return (element);
};

export default InputSolo;
