import Translate from "../../shared/components/Utils/Translate";

const noteStatuses = [
    { value: 'completed', label: <Translate t='note.status-completed' /> },
    { value: 'pending', label: <Translate t='note.status-pending' /> },
    { value: 'archived', label: <Translate t='note.status-archived' /> },
];

const noteStatusesFilter = [
    { value: 'pending', label: <Translate t='note.status-pending' /> },
    { value: 'completed', label: <Translate t='note.status-completed' /> },
    { value: 'archived', label: <Translate t='note.status-archived' /> },
    { value: 'all', label: <Translate t='note.status-all' /> },
];

const noteFeatures = {
    'holded': <Translate t='note.feature-holded' />,
    'stripe': <Translate t='note.feature-stripe' />,
    'add-subscription': <Translate t='note.add-subs' />
};

const noteTypes = [
    { value: 'note', label: <Translate t='models.note' /> },
    { value: 'task', label: <Translate t='models.task' /> },
];

const noteObjects = [
    { value: 'company', field:  <Translate t='models.company' />, icon: 'fi-rr-briefcase' },
    { value: 'project', label:  <Translate t='models.project' />, icon: 'fi-rr-globe' },
    { value: 'service', label:  <Translate t='models.service' />, icon: 'fi-rr-cube' },
    { value: 'subscription', label:  <Translate t='models.subscription' />, icon: 'fi-rr-arrows-repeat' },
    { value: 'payment', label:  <Translate t='models.payment' />, icon: 'fi-rr-credit-card' },
    { value: 'user', label:  <Translate t='models.user' />, icon: 'fi-rr-users' },
];

const notePreventRemove = ['holded', 'stripe'];

export { noteStatuses, noteTypes, noteFeatures, noteObjects, noteStatusesFilter, notePreventRemove };