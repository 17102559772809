import React, { useContext, useState, useEffect } from 'react';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHistory } from 'react-router-dom';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Input from '../../shared/components/UIElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import SelectWrapper from '../../shared/components/UIElements/SelectWrapper';
import SelectAsyncWrapper from '../../shared/components/UIElements/SelectAsyncWrapper';
import PageHeader from '../../shared/components/Titles/PageHeader';
import FormFooter from '../../shared/components/Forms/FormFooter';

import './NewProject.css';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import { projectStatuses, projectTypes } from '../data/ProjectOptions';
import formatDate from '../../shared/utils/dates';

import { useTranslation } from "react-i18next";

const NewProject = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const history = useHistory();
	const [formState, inputHandler] = useForm();

	const searchCompanies = async (inputValue) => {
		if (inputValue.length > 2) {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/companies/s/`,
					'POST',
					JSON.stringify({
						search: inputValue,
						format: 'select',
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					},
					false
				);
				if (response.companies) {
					return response.companies;
				} else {
					return [];
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const searchUsers = async (inputValue) => {
		if (inputValue.length > 2) {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/users/s/?admin=true`,
					'POST',
					JSON.stringify({
						search: inputValue,
						format: 'select',
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					},
					false
				);
				if (response.users) {
					return response.users;
				} else {
					return [];
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const formSubmitHandler = async (event) => {
		event.preventDefault();

		try {
			const response = await sendRequest(
				process.env.REACT_APP_API_URL + '/projects/',
				'POST',
				JSON.stringify({
					type: formState.inputs.type.value,
					name: formState.inputs.name.value,
					description: formState.inputs.description.value,
					status: formState.inputs.status.value,
					domain: {
						url: formState.inputs.domain_url.value,
					},
					company: formState.inputs.company.value,
					// sold_by: formState.inputs.sold_by.value,
					// services: formState.inputs.services.value,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/projects/',
				noticeMsg: t('project.added'),
				noticeType: 'success',
			});
		} catch (error) {
			console.log(error);
		}

		//console.log(formState.inputs); // send data to backend
	};

	const actions = [
		{
			title: t('common.cancel'),
			onClick: () => history.goBack()
		},
		{
			title: t('project.add'),
			style: 'filled-blue',
			flaticon: 'fi-rr-check',
			form: 'add-project',
			type: 'submit',
			disabled: !formState.isValid
		}
	];

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					gobackOnClick={() => history.goBack()}
					title={t('project.add-t')}
					actions={actions}
					separator={true}
				/>
			</ContentHead>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			<form className='add-project' id='add-project' onSubmit={formSubmitHandler}>
				<FormGroup
					title={t('project.information')}
					subtitle={t('project.information-st')}
				>
					<Input
						id='name'
						element='input'
						type='text'
						placeholder={t('project.name-ph')}
						label={t('project.name')}
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
						errorText='Please input a valid text'
					/>
					<Input
						id='description'
						element='input'
						type='text'
						placeholder={t('project.description-ph')}
						label={t('project.description')}
						onInput={inputHandler}
						validators={[]}
						initialValid={true}
					/>
				</FormGroup>
				<FormGroup
					title={t('project.status')}
					subtitle={t('project.status-st')}
				>
					<SelectWrapper
						onInput={inputHandler}
						id='status'
						label={t('project.status-assigned')}
						initialValue={'active'}
						options={projectStatuses}
						initialValid={true}
					/>
					<SelectWrapper
						onInput={inputHandler}
						// initialValue={'web'}
						id='type'
						label={t('project.type')}
						options={projectTypes}
						initialValid={false}
					/>
					<Input
						id='domain_url'
						element='input'
						type='url'
						placeholder={t('project.domain-ph')}
						label={t('project.domain')}
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
					/>
				</FormGroup>
				<FormGroup
					title={t('project.company')}
					subtitle={t('project.company-st')}
				>
					<SelectAsyncWrapper
						onInput={inputHandler}
						id='company'
						label={t('project.company-assigned')}
						loadOptions={searchCompanies}
						getOptionLabel={(e) => e.name + ' (' + e.company_name + ')'}
						getOptionValue={(e) => e._id}
						isMulti={false}
					/>
				</FormGroup>
				{/* <FormGroup
					title='Sale'
					subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et commodo mauris, ut blandit diam. Fusce lobortis orci eget leo pharetra condimentum.'
				>
					<SelectAsyncWrapper
						onInput={inputHandler}
						id='sold_by'
						label='Project sold by'
						loadOptions={searchUsers}
						initialValid={true}
						getOptionLabel={(e) =>
							e.first_name + ' ' + e.last_name + ' (' + e.email + ')'
						}
						getOptionValue={(e) => e._id}
						isMulti={false}
					/>
					<Input
						id='start_date'
						element='input'
						type='date'
						label='Start date'
						onInput={inputHandler}
						validators={[]}
						initialValid={true}
						initialValue={formatDate(new Date())}
					/>
				</FormGroup> */}
				{/* <FormGroup
					title='Services'
					subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et commodo mauris, ut blandit diam. Fusce lobortis orci eget leo pharetra condimentum.'
				>
					<SelectAsyncWrapper
						onInput={inputHandler}
						id='services'
						label='Services'
						loadOptions={searchServices}
						getOptionLabel={(e) => e.name + ' (' + e.price + '€)'}
						getOptionValue={(e) => e._id}
						isMulti={true}
					/>
				</FormGroup> */}
				<FormActions>
					<FormFooter
						actions={actions}
					/>
					{/* <Button type='submit' disabled={!formState.isValid}>
							Add company
						</Button> */}
				</FormActions>
			</form>
		</ContentWrapper>
	);
};

export default NewProject;
