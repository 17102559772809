import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContex } from '../../../shared/context/auth-context';
import Icon from '../UIElements/Icon';
import Modal from '../UIElements/Modal';
import { useHttpClient } from '../../hooks/http-hook';

import './SidebarSearch.css';
import ActionButton from '../UIElements/ActionButton';
import { capitalizeText } from '../../utils/text';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const SidebarSearch = () => {
    const { t } = useTranslation('common');
    const { isLoading, errorMsg, sendRequest } = useHttpClient();
    const auth = useContext(AuthContex);
    const [searchOpen, setSearchOpen] = useState(false);
    const inputRef = useRef(null);
    const [query, setQuery] = useState({});
    const [results, setResults] = useState([]);

    const openSearch = () => {
        setSearchOpen(!searchOpen);
    };

    const startSearching = (e) => {
        const searchValue = e.target.value.trim();
        if (searchValue.length > 2) {
            setQuery(searchValue);
        } else {
            setResults([]);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.metaKey && event.code === "KeyP") {
                event.preventDefault();
                if (searchOpen) {
                    setSearchOpen(false); // Cierra el modal si ya está abierto
                } else {
                    setSearchOpen(true);
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [searchOpen]);

    useEffect(() => {
        if (searchOpen) {
            inputRef.current.focus(); // enfoca el input cuando se muestra el modal
        }
    }, [searchOpen]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.metaKey && event.code === "KeyP") {
                event.preventDefault();
                if (searchOpen) {
                    setSearchOpen(false);
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [searchOpen]);


    useEffect(() => {
        if (query.length > 2) {
            const fetchResultsQuery = async (query) => {
                try {
                    const response = await sendRequest(
                        process.env.REACT_APP_API_URL + '/utils/search/?search=' + query,
                        'GET',
                        null,
                        { Authorization: 'Bearer ' + auth.token }
                    );
                    setResults(response.results);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchResultsQuery(query);
        } else {
            setResults([]);
        }
    }, [query]);

    const formatName = (result) => {
        if (result.object === 'user') {
            return result.first_name+' '+result.last_name+' ('+result.email+')';
        }
        return result.name;
    }

    const formatURL = (result) => {
        if (result.object === 'company') {
            return '/companies/' + result._id;
        }
        if (result.object === 'project') {
            return '/projects/' + result._id;
        }
        if (result.object === 'service') {
            return '/services/edit/' + result._id;
        }
        // if (result.object === 'deal') {
        //     return '/users/' + result._id;
        // }
        if (result.object === 'user') {
            return '/users/edit/' + result._id;
        }
    }


    return (
        <React.Fragment>
            <div className='search' onClick={() => openSearch()}>
                <span className='placeholder'><Icon flaticon='fi-rr-search' /><span>{t('common.search')}</span></span>
                <span className='shortcut'><span>&#8984;</span><span>P</span></span>
                <input type='text' placeholder='' />
            </div>
            <Modal
                show={searchOpen}
                closeAction={openSearch}
                className='search-modal'
                hook='search-hook'
            >
                <React.Fragment>
                    <input type='text' name='s' key='s' placeholder={t('common.search-ph')} ref={inputRef} onChange={(e) => startSearching(e)} />
                    <div className='results'>
                        {results &&
                            results.map(result => (
                                <div className="result" key={result._id}>
                                    <Link to={formatURL(result)} className='no-link' onClick={() => openSearch()}>
                                        <span className='result-info'>{formatName(result)}</span>
                                        <span className='result-object'><ActionButton text={capitalizeText(result.object)} /></span>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </React.Fragment>

            </Modal>
        </React.Fragment>
    );
};

export default SidebarSearch;
