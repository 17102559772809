import React, { useState, useEffect, useContext } from 'react';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useLocation } from 'react-router-dom';

import SubscriptionList from '../components/SubscriptionList';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ItemList from '../../shared/components/UIElements/ItemList';
import PageHeader from '../../shared/components/Titles/PageHeader';
import Filters from '../../shared/components/Results/Filters';

import './Subscriptions.css';
import { subscriptionPeriods, subscriptionStatuses } from '../data/SubscriptionOptions';

import { useTranslation } from "react-i18next";

const Subscriptions = (props) => {
    const { t } = useTranslation('common');
    const { isLoading, errorMsg, sendRequest } = useHttpClient();
    const [subscriptionList, setSubscriptionList] = useState();
    const auth = useContext(AuthContex);
    const location = useLocation();
    const [noticeMsg, setNoticeMsg] = useState();
    const [noticeType, setnoticeType] = useState();

    // Pagination, Search & Filter
    const [totalResults, setTotalResults] = useState(0);
    const [perPageLimit, setPerPageLimit] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
	const [query, setQuery] = useState({});
    const fetchResultsQuery = async (query) => {
        try {
            const response = await sendRequest(
                process.env.REACT_APP_API_URL + '/subscriptions/?' + query,
                'GET',
                null,
                { Authorization: 'Bearer ' + auth.token }
            );
            setSubscriptionList(response.subscriptions);
            setTotalResults(response.count);
        } catch (error) {
            console.log(error);
        }
    };

    const statuses = subscriptionStatuses;
    const periods = subscriptionPeriods;

    const filters = {
		queryFn: fetchResultsQuery,
		setQueryFn: setQuery,
		query: query,
		totalRes: totalResults,
		limitRes: perPageLimit,
		currentPage: currentPage,
		setCurrentPage: setCurrentPage,
        filtersStyle: 'buttons',
		filtersData: [
			{
				id: 'status',
				type: 'button',
				label: t('subscription.status-all'),
				value: '',
			},
			{
				id: 'status',
				type: 'button',
				label: t('subscription.status-active'),
				value: 'active',
			},
			{
				id: 'status',
				type: 'button',
				label: t('subscription.status-pending'),
				value: 'pending',
			},
			{
				id: 'status',
				type: 'button',
				label: t('subscription.status-cancelled'),
				value: 'cancelled',
			},
        // filtersData: [
        //     {
        //         id: 'status',
        //         type: 'select',
        //         label: 'Status',
        //         options: statuses
        //     },
        //     {
        //         id: 'period',
        //         type: 'select',
        //         label: 'Period',
        //         options: periods
        //     }
        ]
    };
    // End Pagination, Search & Filter

    useEffect(() => {
        setNoticeMsg(location.noticeMsg);
        setnoticeType(location.noticeType);
    }, [location]);

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const response = await sendRequest(
                    process.env.REACT_APP_API_URL + '/subscriptions/',
                    'GET',
                    null,
                    { Authorization: 'Bearer ' + auth.token }
                );
                setSubscriptionList(response.subscriptions);
                setTotalResults(response.count);
                setPerPageLimit(response.per_page);
            } catch (error) {
                console.log(error);
            }
        };
        fetchSubscriptions();
    }, []);

    let itemsHeader = [
        {
            title: t('tables.project'),
            // style: 'flex-big'
        },
        {
            title: t('tables.status'),
            style: 'flex-regular'
        },
        {
            title: t('tables.services'),
            style: 'flex-big'
        },
        {
            title: t('tables.price'),
            style: 'flex-regular text-align-right'
        },
        {
            title: t('tables.actions'),
        }];

    const actions = [
        {
            title: t('subscription.add'),
            flaticon: 'fi-br-plus-small',
            style: 'filled-blue',
            to: '/subscriptions/new'
        }
    ];

    return (
        <ContentWrapper>
            <ContentHead>
                <PageHeader
                    title={t('models.subscriptions')}
                    // subtitle='List of all the client’s companies.'
                    actions={actions}
                // subactions={subactions}
                />
                {auth.role !== 'user' && <Filters pagination={true} filter={true} filters={filters} search={true} />}
            </ContentHead>
            {isLoading && <LoadingSpinner />}
            {errorMsg && <Notice type='error' message={errorMsg} />}
            {noticeMsg && <Notice type={noticeType} message={noticeMsg} />}
            <ItemList
                header={itemsHeader}
                actions={true}
                columns={5}
                template='boxed'
                align='center'
            >
                {!isLoading && subscriptionList && <SubscriptionList subscriptions={subscriptionList} header={itemsHeader} />}
            </ItemList>
            {(totalResults > perPageLimit) && <Filters pagination={true} filters={filters} />}
        </ContentWrapper>
    );
};

export default Subscriptions;
