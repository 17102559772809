import React from 'react';
import ActionButton from '../UIElements/ActionButton';
import InputSolo from '../UIElements/InputSolo';

import './FilterBox.css';
import { useTranslation } from "react-i18next";

const FilterBox = (props) => {
    const { t } = useTranslation('common');
    const filters = props.filters;
    const onSubmit = (data) => props.onSubmit(data);
    const setFilterActive = (value) => props.setActive(value);

    const filterHandler = async (event) => {
        event.preventDefault();
        let filterData = {};
        filters.filtersData.forEach((field) => {
            let value = event.target[field.id].value;
            if (field.type === 'checkbox') {
                value = (event.target[field.id].checked === true ? field.value : '');
            }
            filterData = {
                ...filterData,
                [field.id]: value,
            }
        });
        onSubmit(filterData);
        setFilterActive(true);
    }

    const cleanFilter = () => {
        let filterData = {};
        filters.filtersData.forEach((field) => {
            filterData = {
                ...filterData,
                [field.id]: filters.defaultFiltersData?.find(dField => dField.id === field.id)?.value ?? '',
            }
        });
        onSubmit(filterData);
        setFilterActive(false);
    }

    return (
        <div className={`filter-box`}>
            <form id='filter' onSubmit={filterHandler}>
                {props.filters.filtersData &&
                    props.filters.filtersData.map((filter, index) => (
                        <InputSolo key={index} id={filter.id} type={filter.type} label={filter.label} infoLabel={filter.infoLabel} options={filter.options} onChange={filter.onChange} placeholder={filter.placeholder} value={filter.value} text={filter.text} />
                    ))
                }
                {props.filters &&
                    <div className='filter-actions'>
                        <ActionButton form='filter' type='submit' text={t('common.apply-filters')} ></ActionButton>
                        <ActionButton type='button' text={t('common.remove-filters')} onClick={cleanFilter} ></ActionButton>
                    </div>
                }
            </form>
        </div>
    );
};

export default FilterBox;