import React, { useContext, useEffect, useState } from 'react';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Input from '../../shared/components/UIElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import SelectWrapper from '../../shared/components/UIElements/SelectWrapper';
import PageHeader from '../../shared/components/Titles/PageHeader';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import SelectAsyncWrapper from '../../shared/components/UIElements/SelectAsyncWrapper';
import FormFooter from '../../shared/components/Forms/FormFooter';

import formatDate from '../../shared/utils/dates';
import formatMoney from '../../shared/utils/money';

import './NewPayout.css';
import { payoutMonths, payoutYears } from '../data/PayoutData';
import DealList from '../../deals/components/DealList';
import Filters from '../../shared/components/Results/Filters';
import ItemList from '../../shared/components/UIElements/ItemList';
import PayoutDealsSelector from '../components/PayoutDealsSelector';

import { useTranslation } from "react-i18next";

const NewPayout = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const history = useHistory();
	const [deals, setDeals] = useState();
	const [noticeMsg, setNoticeMsg] = useState();
	const [noticeType, setnoticeType] = useState();

	const [formState, inputHandler] = useForm();
	const [selectedDeals, setSelectedDeals] = useState([]);

	useEffect(() => {
		const fetchDeals = async () => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_API_URL + '/deals/?status=paid&payout=null',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setDeals(response.deals);
			} catch (error) {
				console.log(error);
			}
		};
		fetchDeals();
	}, [sendRequest, auth]);

	useEffect(() => {
	}, [selectedDeals]);

	const formSubmitHandler = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				process.env.REACT_APP_API_URL + '/payouts/',
				'POST',
				JSON.stringify({
					month: formState.inputs.month.value,
					year: formState.inputs.year.value,
					deals: selectedDeals || null,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/payouts/',
				noticeMsg: t('payout.generated'),
				noticeType: 'success',
			});
		} catch (error) {
			console.log(error);
		}

		//console.log(formState.inputs); // send data to backend
	};

	const actions = [
		{
			title: t('common.cancel'),
			onClick: () => history.goBack()
		},
		{
			title: t('payout.generate'),
			style: 'filled-blue',
			flaticon: 'fi-rr-check',
			form: 'add-payout',
			type: 'submit',
			disabled: !formState.isValid
		}
	];

	let itemsHeader = [
		{
			title: t('tables.project'),
		},
		{
			title: t('tables.name'),
		},
		{
			title: t('tables.status'),
			style: 'flex-regular'
		},
		{
			title: t('tables.amount'),
			style: 'flex-regular text-align-right'
		},
		{
			title: t('tables.payments'),
			style: 'flex-regular text-align-right'
		},
		{
			title: t('tables.pending'),
			style: 'flex-regular text-align-right'
		},
		{
			title: t('tables.expenses'),
			style: 'flex-regular text-align-right'
		},
		{
			title: t('tables.actions'),
			style: 'flex-small'
		}];

	if (props.hideColumnsIndex) {
		itemsHeader = itemsHeader.map((item, index) => {
			const style = item.style || '';
			const hiddenStyle = props.hideColumnsIndex.includes(index) ? 'hidden' : '';
			return { ...item, style: `${style} ${hiddenStyle}`.trim() };
		});
	}

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					gobackOnClick={() => history.goBack()}
					title={t('payout.generate')}
					actions={actions}
					separator={true}
				/>
			</ContentHead>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			<form className='add-payout' id='add-payout' onSubmit={formSubmitHandler}>
				<FormGroup
					title={t('payout.period')}
					subtitle={t('payout.period-st')}
					className='form-distribution'
				>
					<SelectWrapper
						onInput={inputHandler}
						initialValue={(new Date().getMonth() + 1).toString()} // Month 1-12 (avoid 0-11)
						id='month'
						label={t('payout.month')}
						options={payoutMonths}
						initialValid={true}
						className='width-half'
					/>
					<SelectWrapper
						onInput={inputHandler}
						initialValue={(new Date().getFullYear()).toString()} // Month 1-12 (avoid 0-11)
						id='year'
						label={t('payout.year')}
						options={payoutYears}
						initialValid={true}
						className='width-half'
					/>
				</FormGroup>
				<FormGroup
					title={t('payout.deal-attribution')}
					subtitle={t('payout.deal-attribution-st')}
					className='with-table'
				>
					<PayoutDealsSelector hideColumnsIndex={[4,5,7]} setSelectedDeals={setSelectedDeals} selectedDeals={selectedDeals} />
				</FormGroup>
				<FormActions>
					<FormFooter
						actions={actions}
					/>
				</FormActions>
			</form>
		</ContentWrapper>
	);
};

export default NewPayout;
