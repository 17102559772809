import React, { useContext, useEffect, useState } from 'react';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';

import './NoteData.css';
import formatMoney from '../../shared/utils/money';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import Notice from '../../shared/components/UIElements/Notice';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import { useTranslation } from "react-i18next";

const NoteData = (props) => {

    const { t, i18n } = useTranslation('common');
    const { isLoading, errorMsg, sendRequest } = useHttpClient();
    const auth = useContext(AuthContex);

    const note = props.note;
    const data = note.data;

    const companyId = note.project?.company ?? null;
    const [company, setCompany] = useState();

    useEffect(() => {
        if (props.note.feature === 'add-subscription' && companyId) {
            const fetchCompanies = async () => {
                try {
                    const response = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/companies/${companyId}`,
                        'GET',
                        null,
                        { Authorization: 'Bearer ' + auth.token }
                    );
                    setCompany(response.company);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchCompanies();
        }
    }, [sendRequest, auth, companyId, props.note.feature]);

    // Add subscription reminder
    if (props.note.feature === 'add-subscription') {

        if (isLoading) {
            return <LoadingSpinner></LoadingSpinner>;
        }

        return (
            <>
                <p>{t('note.feature-add-subscription')}</p>
                {company && !company.payment?.iban &&
                    <div className='task-section mb-20'>
                        <Notice type='danger' className='mb-0' message={t('note.feature-add-subscription-danger-sepa')} />
                        <ActionButton to={`/companies/edit/${note.project.company}`} className='mt-10 inline-left' flaticon=' fi-rr-arrow-right' text={t('note.feature-add-subscription-action-1')} />
                    </div>
                }
                <div className='subscription-change-box'>
                    <div className='subscription-change-box-updated'>
                        <h4>{t('subscription.add')}</h4>
                        <div><span>{t('common.price')}</span><span>{formatMoney(data.subscription.price)}€/month</span></div>
                    </div>
                </div>
                <ActionButton to={`/subscriptions/new/${note.project.id}`} className='mt-10 inline-left' flaticon=' fi-rr-arrow-right' text={t('note.feature-add-subscription-action-2')} />
            </>
        )
    }

    // Holded & Stripe
    if (props.note.feature === 'stripe' || props.note.feature === 'holded') {
        let original;
        let updated;
        let remove;
        let key;
        if (data.holded) {
            key = 'holded';
        }
        if (data.stripe) {
            key = 'stripe';
        }



        if (data[key].updated) {
            original = data[key].original;
            updated = data[key].updated;
            remove = data[key].remove;
            return (
                <div className='subscription-change-box'>
                    {original &&
                        <div className='subscription-change-box-original'>
                            <h4>{t('note.feature-holded-stripe-original')}</h4>
                            <div><span>{t('common.price')}</span><span>{formatMoney(original.price)}€/{updated.period}</span></div>
                            <div><span>{t('models.services')}</span><span><ul>{original.services.map((service) => (<li key={service._id}>{service.name}</li>))}</ul></span></div>
                        </div>
                    }
                    {remove &&
                        <div className='subscription-change-box-remove'>
                            <h4>{t('note.feature-holded-stripe-remove')}</h4>
                            <div><span>{t('common.price')}</span><span>{formatMoney(remove.price)}€/{remove.period}</span></div>
                            <div><span>{t('models.services')}</span><span><ul>{remove.services.map((service) => (<li key={service._id}>{service.name}</li>))}</ul></span></div>
                        </div>
                    }
                    <div className='subscription-change-box-updated'>
                        <h4>{t('note.feature-holded-stripe-updated', { type: original ? 'Updated' : 'New' })}</h4>
                        <div><span>{t('common.price')}</span><span>{formatMoney(updated.price)}€/{updated.period}</span></div>
                        <div><span>{t('models.services')}</span><span><ul>{updated.services.map((service) => (<li key={service._id}>{service.name}</li>))}</ul></span></div>
                    </div>
                </div>
            )
        }

        if (data[key].remove) {
            remove = data[key].remove;
            return (
                <div className='subscription-change-box'>
                    {remove &&
                        <div className='subscription-change-box-remove'>
                            <h4>{t('note.feature-holded-stripe-remove')}</h4>
                            <div><span>{t('common.price')}</span><span>{formatMoney(remove.price)}€/{remove.period}</span></div>
                            <div><span>{t('models.services')}</span><span><ul>{remove.services.map((service) => (<li key={service._id}>{service.name}</li>))}</ul></span></div>
                        </div>
                    }
                </div>
            )
        }
    }


};

export default NoteData;
