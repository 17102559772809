import React from 'react';

import SidebarLinks from './SidebarLinks';

import './Sidebar.css';
import SidebarSearch from './SidebarSearch';

const Sidebar = () => {
	return (
		<React.Fragment>
			<div className='sidebar'>
				<SidebarSearch />
				<SidebarLinks className='sidebar-nav' />
			</div>
		</React.Fragment>
	);
};

export default Sidebar;
