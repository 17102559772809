import React, { useState, useEffect, useContext } from 'react';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useLocation } from 'react-router-dom';

import ServiceList from '../components/ServiceList';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ItemList from '../../shared/components/UIElements/ItemList';
import PageHeader from '../../shared/components/Titles/PageHeader';

import './Services.css';
import Filters from '../../shared/components/Results/Filters';
import { serviceTypes } from '../data/ServiceOptions';

import { useTranslation } from "react-i18next";

const Services = (props) => {
	const { t } = useTranslation('common');
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [serviceList, setServiceList] = useState();
	const auth = useContext(AuthContex);
	const location = useLocation();
	const [noticeMsg, setNoticeMsg] = useState();
	const [noticeType, setnoticeType] = useState();

	// Pagination, Search & Filter
	const [totalResults, setTotalResults] = useState(0);
	const [perPageLimit, setPerPageLimit] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [query, setQuery] = useState({});
	const fetchResultsQuery = async (query) => {
		try {
			const response = await sendRequest(
				process.env.REACT_APP_API_URL + '/services/?' + query,
				'GET',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			setServiceList(response.services);
			setTotalResults(response.count);
		} catch (error) {
			console.log(error);
		}
	};
	const filters = {
		queryFn: fetchResultsQuery,
		setQueryFn: setQuery,
		query: query,
		totalRes: totalResults,
		limitRes: perPageLimit,
		currentPage: currentPage,
		setCurrentPage: setCurrentPage,
		filtersData: [
			{
				id: 'type',
				type: 'select',
				label: t('service.type'),
				options: serviceTypes,
			}
		]
	};
	// End Pagination, Search & Filter

	useEffect(() => {
		setNoticeMsg(location.noticeMsg);
		setnoticeType(location.noticeType);
	}, [location]);

	useEffect(() => {
		const fetchServices = async () => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_API_URL + '/services/',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setServiceList(response.services);
				setTotalResults(response.count);
				setPerPageLimit(response.per_page);
			} catch (error) {
				console.log(error);
			}
		};
		fetchServices();
	}, [sendRequest, auth]);

	const itemsHeader = [t('tables.name'), t('tables.type'), t('tables.price'), t('tables.actions')];

	const actions = [
		{
			title: t('service.add'),
			flaticon: 'fi-br-plus-small',
			style: 'filled-blue',
			to: '/services/new'
		}
	];
	
	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					title={t('models.services')}
					// subtitle='List of all the client’s companies.'
					actions={actions}
				// subactions={subactions}
				/>
				{auth.role !== 'user' && <Filters pagination={true} filter={true} filters={filters} search={true} />}
			</ContentHead>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{noticeMsg && <Notice type={noticeType} message={noticeMsg} />}
			<ItemList
				header={itemsHeader}
				actions={true}
				columns={4}
				template='boxed'
				align='center'
			>
				{!isLoading && serviceList && <ServiceList services={serviceList} />}
			</ItemList>
		</ContentWrapper>
	);
};

export default Services;
