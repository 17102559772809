import React from 'react';
import ContentBox from './ContentBox';
import ContentWrapper from './ContentWrapper';
import './LoginBox.css';

const LoginBox = (props) => {
	return <ContentWrapper>
		<ContentBox className='login-box'>{props.children}</ContentBox>
	</ContentWrapper>;
};

export default LoginBox;
