import React from "react";

import './FormInfo.css';

const FormInfo = (props) => {
    return (
        <div className={`form-info ${props.className || ''}${props.title && ' with-title'}`}>
            <div>
                <h4>{props.title}</h4>
                <p className='form-info-subtitle'>{props.subtitle}</p>
            </div>
            <div>
                {props.children}
            </div>
        </div>
    );
};

export default FormInfo;
