import React from "react";

const ContentBox = (props) => {
    return (
        <div className={`content-box ${props.className || ''}`}>
            {props.children}
        </div>
    );
};

export default ContentBox;
