import React from "react";

import './DashboardKPI.css';

const DashboardKPI = (props) => {
    return (
        <div className={`dashboard-box dashboard-kpi ${props.className || ''}`}>
            <div className='dashboard-kpi-title'>
                {props.title}
            </div>
            <div className='dashboard-kpi-content'>
                <span className='dashboard-kpi-content-data'>
                    {props.data}
                </span>
                {props.extra &&
                    <span className='dashboard-kpi-content-extra'>
                        {props.extra}
                    </span>
                }
            </div>
        </div>
    );
};

export default DashboardKPI;
