import React, { useContext, useState } from 'react';
import { AuthContex } from '../../context/auth-context';

import Icon from '../UIElements/Icon';

import './AccountBox.css';
import SimpleLink from '../UIElements/SimpleLink';

const AccountBox = (props) => {
    const auth = useContext(AuthContex);
    const [subMenu, setSubMenu] = useState(false);

    const openSubMenu = () => {
        if (subMenu) {
            setSubMenu(false);
        } else {
            setSubMenu(true);
        }
        console.log(subMenu);
    }

    return (
        <div className='account-box'>
            <div className='user-container' onClick={openSubMenu}>
                <div className='user-data'>
                    <span>
                        {auth.first_name} {auth.last_name}
                    </span>
                </div>
                <div className='user-opener'>
                    {!subMenu && (
                        <Icon flaticon='fi-br-angle-small-down' />
                        // <i className="fi fi-rr-angle-down"></i>
                    )}
                    {subMenu && (
                        <Icon flaticon='fi-br-angle-small-up' />
                    )}
                </div>
            </div>
            <div className={`user-actions ${subMenu ? 'open' : ''}`}>
                <ul>
                    <li>
                        <SimpleLink to='/logout' onClick={auth.logout} flaticon='fi-br-lock' text='Logout' />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default AccountBox;
