import React from "react";
import formatMoney from "./money";

const periodToNumber = (period) => {
	let periodNumber;
	switch (period) {
		case 'month':
			periodNumber = 1;
			break;
		case 'quarter':
			periodNumber = 4;
			break;
		case 'semester':
			periodNumber = 6;
			break;
		case 'year':
			periodNumber = 12;
			break;
		default:
			break;
	}
	return periodNumber;
}

const subscriptionRate = (subscription) => {
	return (
		<React.Fragment>
			{formatMoney(subscription.price)}€/{subscription.period}
		</React.Fragment>
	);
}

export { periodToNumber, subscriptionRate };