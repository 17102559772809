import React, { useContext, useState, useEffect, useRef } from 'react';
import { VALIDATOR_VALID } from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Input from '../../shared/components/UIElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import SelectWrapper from '../../shared/components/UIElements/SelectWrapper';

import './NewNote.css';
import Modal from '../../shared/components/UIElements/Modal';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import NotesBubbleList from './NotesBubbleList';
import Status from '../../shared/components/UIElements/Status';
import InputSolo from '../../shared/components/UIElements/InputSolo';
import { noteObjects, noteStatuses, noteTypes } from '../data/NoteOptions';
import SelectAsyncWrapper from '../../shared/components/UIElements/SelectAsyncWrapper';
import { userGroups } from '../../users/data/UserOptions';

import { useTranslation } from "react-i18next";

const NewNote = (props) => {
    const { t, i18n } = useTranslation('common');
    const auth = useContext(AuthContex);
    const { isLoading, errorMsg, sendRequest } = useHttpClient();
    const [type, setType] = useState('note');
    const [status, setStatus] = useState('completed');
    const [notes, setNotes] = useState();
    const [formState, inputHandler] = useForm();
    const [isSending, setIsSending] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const setTask = (event) => {
        const { checked } = event.target;
        if (checked) {
            setType('task');
            setStatus('pending');
            setIsDisabled(false);
        } else {
            setType('note');
            setStatus('completed');
            setIsDisabled(true);
        }
    }

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                const response = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/notes/${props.object}/${props.id}`,
                    'GET',
                    null,
                    { Authorization: 'Bearer ' + auth.token }
                );
                setNotes(response.notes);
            } catch (error) {
                console.log(error);
            }
        };
        fetchNotes();
    }, [sendRequest, auth, props.object, props.id]);

    const formSubmitHandler = async (event) => {
        event.preventDefault();
        setIsSending(true);
        try {
            const response = await sendRequest(
                process.env.REACT_APP_API_URL + '/notes/',
                'POST',
                JSON.stringify({
                    type: type,
                    content: formState.inputs.content.value,
                    status: status,
                    [props.object]: props.id,
                    assigned_to: formState.inputs.assigned_to.value
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                }
            );
            setNotes([...notes, response.note]);
            setIsSending(false);
        } catch (error) {
            console.log(error);
        }
    };

    const completeTask = async (note) => {
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_API_URL}/notes/complete/${note.id}`,
                'PATCH',
                null,
                { Authorization: 'Bearer ' + auth.token }
            );
            let updatedNote = notes.filter(item => item.id === note.id)[0];
            updatedNote.status = 'completed';
            setNotes(notes.filter(item => {
                if (item.id === note.id) {
                    item.status = 'completed';
                }
                return item;
            }));
            // setNotes(notes.filter(item => item.id !== note.id));
        } catch (error) {
            console.log(error)
        }
    };

    const archiveNote = async (note) => {
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_API_URL}/notes/archive/${note.id}`,
                'PATCH',
                null,
                { Authorization: 'Bearer ' + auth.token }
            );
            setNotes(notes.filter(item => item.id !== note.id));
        } catch (error) {
            console.log(error)
        }
    };

    const deleteNote = async (note) => {
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_API_URL}/notes/${note.id}`,
                'DELETE',
                null,
                { Authorization: 'Bearer ' + auth.token }
            );
            setNotes(notes.filter(item => item.id !== note.id));
        } catch (error) {
            console.log(error)
        }
    };

    const searchAssignment = async (inputValue) => {
        const firstChar = inputValue[0];
        if (firstChar === '@') {
            try {
                const response = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/users/s/?admin=true`,
                    'POST',
                    JSON.stringify({
                        search: inputValue.slice(1),
                        format: 'select',
                    }),
                    {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + auth.token,
                    },
                    false
                );
                if (response.users) {
                    return response.users;
                } else {
                    return [];
                }
            } catch (error) {
                console.log(error);
            }
        } else if (firstChar === '#') {
            return userGroups;
        } else {
            return;
        }
    };

    const objectTable = noteObjects;
    const object = objectTable
        .filter((object) => object.value === props.object)
        .map((object) => object);

    return (
        <React.Fragment>
            {isLoading && <LoadingSpinner />}
            {errorMsg && <Notice type='error' message={errorMsg} />}
            <Modal
                show={props.show}
                title={<span className='notes-head'><span>Notes</span><Status icon={object[0].icon} color='grey' text={props.label ?? object[0].label} /></span>}
                closeAction={props.toggleNote}
                className='notes'
                hook='notes-hook'
                footer={
                    <div className='modal-footer-actions'>
                        <form id='add-note' onSubmit={formSubmitHandler}>
                            {props.type &&
                                <SelectWrapper
                                    onInput={inputHandler}
                                    id='type'
                                    label={t('note.type')}
                                    initialValue={'note'}
                                    options={noteTypes}
                                    initialValid={true}
                                    onChange={e => setType(e.target.value)}
                                />
                            }
                            {props.status &&
                                <SelectWrapper
                                    onInput={inputHandler}
                                    id='status'
                                    label={t('note.status')}
                                    initialValue={'completed'}
                                    options={noteStatuses}
                                    initialValid={true}
                                    onChange={e => setStatus(e.target.value)}
                                />
                            }
                            {!isSending &&
                                <Input
                                    id='content'
                                    element='textarea'
                                    type='text'
                                    placeholder={t('note.content-ph')}
                                    onInput={inputHandler}
                                    validators={[VALIDATOR_VALID]}
                                    initialValid={true}
                                />
                            }
                            {isSending &&
                                <Input
                                    id='dummy'
                                    element='textarea'
                                    type='text'
                                    placeholder={t('note.content-ph')}
                                    onInput={inputHandler}
                                    initialValid={true}
                                />
                            }
                            <div className='task-options'>
                                <InputSolo type="checkbox" text="Task" value="task" id="task" onInput={inputHandler} onChange={e => setTask(e)} />
                                <SelectAsyncWrapper
                                    onInput={inputHandler}
                                    id='assigned_to'
                                    // label='Users with access'
                                    loadOptions={searchAssignment}
                                    initialValid={true}
                                    getOptionLabel={(e) => {
                                        if (e.first_name) {
                                            return e.first_name + " " + e.last_name + " (" + e.email + ")";
                                        } else {
                                            return e.label;
                                        }
                                    }}
                                    getOptionValue={(e) => {
                                        return e._id ?? e.value;
                                    }}
                                    isMulti={false}
                                    menuPlacement='top'
                                    isDisabled={isDisabled}
                                    placeholder={t('note.assign-ph')}
                                    className='assigned-to'
                                />
                            </div>
                        </form>
                        <div className='action-notes'>
                            <ActionButton text={t('common.add')} flaticon='fi-rr-check' form='add-note' type='submit' style={`filled-blue`} disabled={!formState.isValid} />
                            <ActionButton text={t('common.close')} onClick={props.toggleNote} />
                        </div>
                    </div>
                }
            >
                {notes &&
                    <NotesBubbleList notes={notes} deleteNote={(note) => deleteNote(note)} archiveNote={(note) => archiveNote(note)} completeTask={(note) => completeTask(note)} />
                }
            </Modal>
        </React.Fragment>
    );
};

export default NewNote;
