import React from 'react';

import './ItemList.css';

const ItemList = (props) => {
	return (
		<div className={`item-list grid-${props.columns} ${props.template || ''} align-${props.align} ${props.actions && 'with-actions'}`}>
            <div className='items-head'>
                <div className='item'>
                {props.header.map((headerItem, index) => (
                    <div key={index} className={headerItem.style && headerItem.style}><span>{headerItem.title && headerItem.title}{!headerItem.title && headerItem}</span></div>
                ))}
                </div>
            </div>
            <div className='items-content'>
                {props.children}
            </div>
			
		</div>
	);
};

export default ItemList;
