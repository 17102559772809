import React, { useReducer, useEffect, useState } from 'react';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

import Icon from './Icon';

import './SelectWrapper.css';

const selectReducer = (state, action) => {
	switch (action.type) {
		case 'change':
			return {
				...state,
				value: action.val,
				isValid: true,
			};
		case 'touch':
			return {
				...state,
				isTouched: true,
			};
		default:
			return state;
	}
};

const SelectAsyncWrapper = (props) => {
	const [inputState, dispatch] = useReducer(selectReducer, {
		value: props.initialValue || '',
		isValid: props.initialValid || false,
		isTouched: false,
	});

	let dropdownIcon = props.dropdownIcon;
	const options = props.options;

	const DropdownIndicator = (props) => {
		let flaticon = 'fi-rr-angle-small-down';
		if (dropdownIcon) {
			flaticon = dropdownIcon;
		}
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<Icon flaticon={flaticon} />
				</components.DropdownIndicator>
			)
		);
	};

	const ClearIndicator = (props) => {
		return (
			components.ClearIndicator && (
				<components.ClearIndicator {...props}>
					<Icon flaticon='fi-rr-cross-small' />
				</components.ClearIndicator>
			)
		);
	}

	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage {...props}>
				<span className='custom-css-class'>
					Type at least 3 letters to search...
				</span>
			</components.NoOptionsMessage>
		);
	};

	const { id, onInput } = props;
	const { value, isValid } = inputState;
	const [selectedOption, setSelectedOption] = useState(inputState.value);

	useEffect(() => {
		onInput(props.id, inputState.value, inputState.isValid);
	}, [id, value, isValid, onInput]);

	// We send the id -> Expected always in SelectAsyncWrapper
	const changeHandler = (event) => {
		if (props.onChange) {
			props.onChange(event);
		}
		dispatch({
			type: 'change',
			val: event,
			validators: props.validators,
		});
		setSelectedOption(event);
	};

	const touchHandler = () => {
		dispatch({
			type: 'touch',
		});
	};

	return (
		<div className={`form-control ${props.className || ''}`}>
			<label htmlFor={props.id}>{props.label}</label>
			<AsyncSelect
				id={props.id}
				key={props.id}
				className={`select ${props.className || ''}`}
				classNamePrefix='select-content'
				onChange={changeHandler}
				onBlur={touchHandler}
				onInputChange={props.onInputChange}
				value={selectedOption}
				cacheOptions
				loadOptions={props.loadOptions}
				options={options}
				getOptionLabel={props.getOptionLabel}
				getOptionValue={props.getOptionValue}
				isMulti={props.isMulti}
				components={{ DropdownIndicator, ClearIndicator, NoOptionsMessage }}
				formatOptionLabel={props.formatOptionLabel}
				styles={{ NoOptionsMessage: (base) => ({ ...base }) }}
				menuPlacement={props.menuPlacement}
				isDisabled={props.isDisabled}
				placeholder={props.placeholder}
			/>
		</div>
	);
};

export default SelectAsyncWrapper;
