import React, { useState, useEffect, useContext } from 'react';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentBox from '../../shared/components/Wrappers/ContentBox';
import MainTitle from '../../shared/components/Titles/MainTitle';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';

import './MyAccount.css';
import PageHeader from '../../shared/components/Titles/PageHeader';
import { useHistory } from 'react-router-dom';
import DataTable from '../../shared/components/Dashboards/DataTable';
import DashboardBox from '../../shared/components/Dashboards/DashboardBox';
import DashboardColumn from '../../shared/components/Dashboards/DashboardColumn';
import DashboardWrapper from '../../shared/components/Dashboards/DashboardWrapper';
import Empty from '../../shared/components/Results/Empty';

const MyAccount = (props) => {
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [user, setLoadedUser] = useState();
	const history = useHistory();
	const auth = useContext(AuthContex);

	useEffect(() => {
		const fetchUser = async () => {
			try {
				if (auth.userId) {
					const response = await sendRequest(
						`${process.env.REACT_APP_API_URL}/users/${auth.userId}`,
						'GET',
						null,
						{ Authorization: 'Bearer ' + auth.token }
					);
					setLoadedUser(response.user);
				}
			} catch (error) {
				console.log(error);
			}
		};
		fetchUser();
	}, [sendRequest, auth]);

	if (isLoading) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	return (
		<ContentWrapper>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{!isLoading && user && (
				<React.Fragment>
					<ContentHead>
						<PageHeader
							gobackOnClick={() => history.goBack()}
							title='My account'
							separator={true}
						/>
					</ContentHead>
					<DashboardWrapper>
						<DashboardColumn className='flex-small'>
							<DashboardBox
								title='Account information'
							>
								<DataTable>
									<div><span>First name</span><span>{user.first_name}</span></div>
									<div><span>Last name</span><span>{user.last_name}</span></div>
									<div><span>Email</span><span>{user.email}</span></div>
									<div><span>Phone</span><span>{user.phone}</span></div>
									<div><span>Role</span><span>{user.role}</span></div>
								</DataTable>
							</DashboardBox>
						</DashboardColumn>
						<DashboardColumn>
							<DashboardBox
								title='Companies'
								className='dashboard-box-table'
							>
								{user.role === 'super' &&
									<Empty type='success' message='Your role is superadmin and you have access to all companies' />
								}
								{user.role !== 'super' &&
									<Empty type='info' message='Widget not installed' />
								}
							</DashboardBox>
						</DashboardColumn>
					</DashboardWrapper>
				</React.Fragment>
			)}
		</ContentWrapper>
	);
}
export default MyAccount;