import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';
import { useHistory } from 'react-router-dom';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ItemList from '../../shared/components/UIElements/ItemList';
import SubscriptionList from '../../subscriptions/components/SubscriptionList';
import Empty from '../../shared/components/Results/Empty';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import Filters from '../../shared/components/Results/Filters';
import PaymentList from '../../payments/components/PaymentList';
import { paymentStatuses } from '../../payments/data/PaymentOptions';

import { useTranslation } from "react-i18next";

const SubscriptionPayments = (props) => {
    const { t } = useTranslation('common');
    const subscriptionId = props.subscription.id;
    const { isLoading, errorMsg, sendRequest } = useHttpClient();
    const auth = useContext(AuthContex);
    const [payments, setLoadedPayments] = useState();

    // Pagination, Search & Filter
    const [totalResults, setTotalResults] = useState(0);
    const [perPageLimit, setPerPageLimit] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState({});
    const fetchResultsQuery = async (query) => {
        try {
            const response = await sendRequest(
                process.env.REACT_APP_API_URL + '/payments/subscription/' + subscriptionId + '?' + query,
                'GET',
                null,
                { Authorization: 'Bearer ' + auth.token }
            );
            setLoadedPayments(response.payments);
            setTotalResults(response.count);
            setPerPageLimit(response.per_page);
        } catch (error) {
            console.log(error);
        }
    };

    const statuses = paymentStatuses;

    const filters = {
        queryFn: fetchResultsQuery,
        setQueryFn: setQuery,
        query: query,
        totalRes: totalResults,
        limitRes: perPageLimit,
        currentPage: currentPage,
        setCurrentPage: setCurrentPage,
        filtersData: [
            {
                id: 'status',
                type: 'select',
                label: t('payment.status'),
                options: statuses
            }
        ]
    };
    // End Pagination, Search & Filter

    // Patch Component limitations for widgets
    const queryProp = {};
    queryProp.limit = props.limit ?? '';

    useEffect(() => {
        const fetchPayments = async () => {
            try {
                const response = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/payments/subscription/${subscriptionId}?${new URLSearchParams(queryProp).toString()}`,
                    'GET',
                    null,
                    { Authorization: 'Bearer ' + auth.token }
                );
                setLoadedPayments(response.payments);
                setTotalResults(response.count);
                setPerPageLimit(response.per_page);
            } catch (error) {
                console.log(error);
            }
        };
        fetchPayments();
    }, [sendRequest, auth, subscriptionId]);

    let itemsHeader = [
        {
            title: t('tables.date'),
        },
        {
            title: t('tables.status')
        },
        {
            title: t('tables.amount'),
        }];

    if (props.hideColumnsIndex) {
        itemsHeader = itemsHeader.map((item, index) => {
            const style = item.style || '';
            const hiddenStyle = props.hideColumnsIndex.includes(index) ? 'hidden' : '';
            return { ...item, style: `${style} ${hiddenStyle}`.trim() };
        });
    }

    if (isLoading) {
        return <LoadingSpinner></LoadingSpinner>;
    }

    return (
        <React.Fragment>
            {payments && payments.length > 0 &&
                <React.Fragment>
                    <ItemList
                        header={itemsHeader}
                        actions={true}
                        columns={3}
                        template='boxed'
                        align='center'
                    >
                        {!isLoading && payments && <PaymentList payments={payments} header={itemsHeader} />}
                    </ItemList>
                    {props.filters && <Filters pagination={true} filter={false} filters={filters} search={true} />}
                </React.Fragment>
            }
            {payments && payments.length === props.limit &&
                <ActionButton className='m-0a mt-10' text={t('common.viewall')} onClick={props.viewAll} />
            }
            {payments && payments.length === 0 &&
                <Empty type='info' message={t('payments.not-found')} />
            }
        </React.Fragment>
    );
};

export default SubscriptionPayments;
