import Translate from "../../shared/components/Utils/Translate";

const projectStatuses = [
    { value: 'active', label: <Translate t='project.status-active' /> },
    { value: 'development', label: <Translate t='project.status-development' /> },
    { value: 'waiting', label: <Translate t='project.status-waiting' /> },
    { value: 'archived', label: <Translate t='project.status-archived' /> },
];

const projectTypes = [
    { value: 'web', label: <Translate t='project.type-web' /> },
    { value: 'ecommerce', label: <Translate t='project.type-ecommerce' /> },
    { value: 'other', label: <Translate t='project.type-other' /> },
];

// For Edit form, we remove archived option
const projectStatusesEdit = [
    { value: 'active', label: <Translate t='project.status-active' /> },
    { value: 'development', label: <Translate t='project.status-development' /> },
    { value: 'waiting', label: <Translate t='project.status-waiting' /> },
];

export { projectStatuses, projectStatusesEdit, projectTypes };