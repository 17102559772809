import React from 'react';

import SimpleLink from '../UIElements/SimpleLink';
import ActionButton from '../UIElements/ActionButton';

import './PageHeader.css';
import { useTranslation } from "react-i18next";

const PageHeader = (props) => {
	const { t } = useTranslation('common');
	return (
		<React.Fragment>
			{1 === 2 && props.goback && 
				<div className='goback'>
					<SimpleLink to={props.goback} flaticon='fi-rr-arrow-left' text={t('common.goback')} className='filled' />
				</div>
			}
			{1 === 2 && props.gobackOnClick &&
				<div className='goback'>
					<SimpleLink onClick={props.gobackOnClick} flaticon='fi-rr-arrow-left' text={t('common.goback')} className='filled' />
				</div>
			}
			<div className='page-header'>
				<div className='left'>
					<div className='row'>
						<h1 className='semibold s28'>{props.title}</h1>
					</div>
					{props.subtitle &&
						<div className='row'><p>{props.subtitle}</p></div>
					}
					{props.subdata && (
						<div className='row gap-16'>
							{props.subdata &&
								props.subdata.map((subdata, index) => (
									(subdata.title && subdata.title !== ' ') &&
									<SimpleLink key={index} tooltip={subdata.tooltip} flaticon={subdata.flaticon} text={subdata.title} to={subdata.to} />
								))
							}
						</div>
					)}
				</div>
				<div className='right'>
					{props.actions && (
						<div className='row'>
							{props.actions &&

								props.actions.map((action, index) =>
								(
									<React.Fragment key={index}>
										{action.component &&
											action.component
										}
										{!action.component &&
											<ActionButton to={action.to} className={action.className} flaticon={action.flaticon} text={action.title} style={action.style} type={action.type} form={action.form} onClick={action.onClick} disabled={action.disabled} tooltip={action.tooltip} />
										}
									</React.Fragment>
								))
							}
						</div>
					)}
					{props.subactions && (
						<div className='row'>
							{props.subactions &&
								props.subactions.map((subaction, index) => (
									<SimpleLink key={index} to={subaction.to} className={subaction.className} flaticon={subaction.flaticon} text={subaction.title} type={subaction.type} form={subaction.form} onClick={subaction.onClick} disabled={subaction.disabled} />
								))
							}
						</div>
					)}
				</div>
			</div>
			{props.separator &&
				<div className='separator'></div>}
		</React.Fragment>
	);
};

export default PageHeader;
