import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';

import Input from '../../shared/components/UIElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import SelectAsyncWrapper from '../../shared/components/UIElements/SelectAsyncWrapper';
import FormFooter from '../../shared/components/Forms/FormFooter';

import './DealModal.css';
import formatDate from '../../shared/utils/dates';
import FieldRepeater from '../../shared/components/Forms/FieldRepeater';
import SelectWrapper from '../../shared/components/UIElements/SelectWrapper';
import { dealTypes } from '../data/DealOptions';
import Notice from '../../shared/components/UIElements/Notice';
import FormDataDisabled from '../../shared/components/UIElements/FormDataDisabled';
import formatMoney from '../../shared/utils/money';
import FormDataDisabledPayment from '../../shared/components/UIElements/FormDataDisabledPayment';
import { capitalizeText } from '../../shared/utils/text';

import { useTranslation } from "react-i18next";

const DealModal = (props) => {
    const { t, i18n } = useTranslation('common');
    const { isLoading, errorMsg, sendRequest } = useHttpClient();
    const auth = useContext(AuthContex);
    const history = useHistory();
    const project = props.deal?.project ?? props.project;
    const [noticeMsg, setNoticeMsg] = useState();
    const [noticeType, setnoticeType] = useState();

    const setParentDeal = (deal) => props.updateParent(deal);
    const [payments, setPayments] = useState(props.deal?.payments ?? '');
    const [expenses, setExpenses] = useState(props.deal?.expenses ?? '');

    const [formState, inputHandler, setFormData] = useForm(
        {
            name: {
                value: props.deal?.name ?? '',
                isValid: props.deal?.name ? true : false,
            },
            type: {
                value: props.deal?.type ?? '',
                isValid: props.deal?.type ? true : false,
            },
            sold_by: {
                value: props.deal?.sold_by ?? '',
                isValid: props.deal?.sold_by ? true : false,
            },
            start_date: {
                value: props.deal?.start_date ? formatDate(props.deal?.start_date) : '',
                isValid: props.deal?.start_date ? true : false,
            },
            price: {
                value: props.deal?.price ?? '',
                isValid: props.deal?.price ? true : false,
            },
            mrr_price: {
                value: props.deal?.mrr_price ?? '',
                isValid: true,
            },
            // payments: {
            //     value: [],
            //     isValid: true,
            // },
            // expenses: {
            //     value: [],
            //     isValid: true,
            // },
        },
        true
    );

    const formSubmitHandler = async (event) => {
        event.preventDefault();
        try {
            let requestType = 'POST';
            if (props.deal) {
                requestType = 'PATCH'
            }
            const response = await sendRequest(
                `${process.env.REACT_APP_API_URL}/deals/${props.deal?.id || ''}`,
                requestType,
                JSON.stringify({
                    project: project,
                    name: formState.inputs.name.value,
                    type: formState.inputs.type.value,
                    sold_by: formState.inputs.sold_by.value,
                    start_date: new Date(formState.inputs.start_date.value).toISOString(),
                    price: formState.inputs.price.value,
                    mrr_price: formState.inputs.mrr_price.value ?? null,
                    payments: payments,
                    expenses: expenses,
                }),
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                }
            );
            if (props.deal) {
                setParentDeal(response.deal);
            } else {
                // @TODO: just close the modal and refresh the deals with ok message (project page)
                history.push({
                    pathname: '/projects/',
                    noticeMsg: t('deal.added'),
                    noticeType: 'success',
                });
            }
            setNoticeMsg(response.message);
            setnoticeType('success');
        } catch (error) {
            console.log(error);
        }
    };

    const searchUsers = async (inputValue) => {
        if (inputValue.length > 2) {
            try {
                const response = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/users/s/?admin=true`,
                    'POST',
                    JSON.stringify({
                        search: inputValue,
                        format: 'select',
                    }),
                    {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + auth.token,
                    },
                    false
                );
                if (response.users) {
                    return response.users;
                } else {
                    return [];
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    if (isLoading) {
        return <LoadingSpinner></LoadingSpinner>;
    }

    let actions = [
        {
            title: t('common.close'),
            onClick: () => props.closeAction(),
            type: 'button'
        },
        {
            title: t('common.save'),
            style: 'filled-blue',
            flaticon: 'fi-rr-check',
            form: 'edit-project',
            type: 'submit',
            disabled: !formState.isValid
        }
    ];

    // Repeater for payments
    const paymentsData = {
        setParentData: (e) => setPayments(e),
        inputHandler: inputHandler,
        formState: formState,
        initialValues: payments,
        setFormData: (e) => setFormData(e),
        initialEmpty: true,
        fields: {
            paid: {
                value: false,
                initialValid: true,
                type: 'checkbox',
                label: t('deal.paid'),
                className: 'flex-tiny',
                validators: [VALIDATOR_REQUIRE()]
            },
            description: {
                value: '',
                initialValid: true,
                type: 'text',
                label: t('deal.description'),
                placeholder: 'Write a description',
                errorText: 'Please input a valid text',
                validators: [VALIDATOR_REQUIRE()]
            },
            amount: {
                value: '',
                initialValid: true,
                type: 'number',
                label: t('deal.amount'),
                placeholder: '0,00',
                className: 'flex-half',
                errorText: 'Please input a valid text',
                validators: [VALIDATOR_REQUIRE()]
            },
            date: {
                value: '',
                initialValid: true,
                type: 'date',
                label: t('deal.date'),
                className: 'flex-half',
                errorText: 'Please input a valid text',
                validators: [VALIDATOR_REQUIRE()]
            },
        }
    }

    // Repeater for payments
    const expensesData = {
        setParentData: (e) => setExpenses(e),
        inputHandler: inputHandler,
        formState: formState,
        initialValues: expenses,
        setFormData: (e) => setFormData(e),
        initialEmpty: true,
        fields: {
            paid: {
                value: false,
                initialValid: true,
                type: 'checkbox',
                label: t('deal.paid'),
                className: 'flex-tiny',
                validators: [VALIDATOR_REQUIRE()]
            },
            description: {
                value: '',
                initialValid: true,
                type: 'text',
                label: t('deal.description'),
                placeholder: 'Write a description',
                errorText: 'Please input a valid text',
                validators: [VALIDATOR_REQUIRE()]
            },
            amount: {
                value: '',
                initialValid: true,
                type: 'number',
                label: t('deal.amount'),
                placeholder: '0,00',
                className: 'flex-half',
                errorText: 'Please input a valid text',
                validators: [VALIDATOR_REQUIRE()]
            },
            date: {
                value: '',
                initialValid: true,
                type: 'date',
                label: t('deal.date'),
                className: 'flex-half',
                errorText: 'Please input a valid text',
                validators: [VALIDATOR_REQUIRE()]
            },
        }
    }

    if (props.deal?.payout) {
        actions = [
            {
                title: t('common.close'),
                onClick: () => props.closeAction(),
                type: 'button'
            },
        ];
    }

    return (
        <>
            {noticeMsg && <Notice type={noticeType} message={noticeMsg} />}
            {errorMsg && <Notice type='error' message={errorMsg} />}
            {props.deal?.payout &&
                <Notice type='alert' className='z-index-top' message={t('deal.danger-associated-payout')} />
            }
            <form onSubmit={formSubmitHandler} id='edit-project'>
                {/* <FormGroup
                    title='Project information'
                    subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et commodo mauris, ut blandit diam. Fusce lobortis orci eget leo pharetra condimentum.'
                > */}
                <FormGroup
                    className='form-distribution'
                    title={t('deal.general-information')}
                    subtitle={t('deal.general-information-st')}
                    titleStyle='upper-title'
                >
                    {props.deal?.payout
                        ?
                        <FormDataDisabled label={t('deal.name')} className='width-half'>
                            {props.deal.name}
                        </FormDataDisabled>
                        :
                        <Input
                            id='name'
                            element='input'
                            type='text'
                            placeholder={t('deal.name-ph')}
                            label={t('deal.name')}
                            className='width-half'
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText='Please input a valid text'
                            initialValid={formState.inputs.name.isValid}
                            initialValue={formState.inputs.name.value}
                        />
                    }
                    {props.deal?.payout
                        ?
                        <FormDataDisabled label={t('deal.types')} className='width-half'>
                            {capitalizeText(props.deal.type)}
                        </FormDataDisabled>
                        :
                        <SelectWrapper
                            onInput={inputHandler}
                            id='type'
                            label={t('deal.types')}
                            className='width-half no-grow'
                            options={dealTypes}
                            validators={[VALIDATOR_REQUIRE()]}
                            initialValid={formState.inputs.type.isValid}
                            initialValue={formState.inputs.type.value}
                            isMulti={true}
                            disableInputText={true}
                        />
                    }
                    {props.deal?.payout
                        ?
                        <FormDataDisabled label={t('deal.soldby')} className='width-half'>
                            {props.deal.sold_by.first_name} {props.deal.sold_by.last_name}
                        </FormDataDisabled>
                        :
                        <SelectAsyncWrapper
                            onInput={inputHandler}
                            id='sold_by'
                            label={t('deal.soldby')}
                            className='width-half'
                            loadOptions={searchUsers}
                            initialValue={formState.inputs.sold_by.value}
                            defaultValue={formState.inputs.sold_by.value}
                            initialValid={true}
                            getOptionLabel={(e) =>
                                e.first_name + ' ' + e.last_name + ' (' + e.email + ')'
                            }
                            getOptionValue={(e) => e._id}
                            isMulti={false}
                        />
                    }
                    {props.deal?.payout
                        ?
                        <FormDataDisabled label={t('deal.price')} className={props.deal.type.includes('recurring') ? 'width-half-quarter' : 'width-quarter'}>
                            {formatMoney(props.deal.price)}€
                        </FormDataDisabled>
                        :
                        <Input
                            id='price'
                            element='input'
                            type='number'
                            placeholder='0,00'
                            label={t('deal.price')}
                            className={`${formState.inputs.type.value.includes('recurring') ? 'width-half-quarter' : 'width-quarter'} no-arrows`}
                            onInput={inputHandler}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText={t('forms.common.error-valid-number')}
                            initialValid={formState.inputs.price.isValid}
                            initialValue={formState.inputs.price.value}
                        />
                    }
                    {props.deal?.payout && props.deal.type.includes('recurring') &&
                        <FormDataDisabled label={t('deal.mrr')} className='width-half-quarter'>
                            {formatMoney(props.deal.mrr_price)}€
                        </FormDataDisabled>
                    }
                    {formState.inputs.type.value.includes('recurring') && !props.deal?.payout &&
                        <Input
                            id='mrr_price'
                            element='input'
                            type='number'
                            placeholder='0,00'
                            label={t('deal.mrr')}
                            className='width-half-quarter no-arrows'
                            onInput={inputHandler}
                            validators={[]}
                            errorText={t('forms.common.error-valid-number')}
                            initialValid={true}
                            initialValue={props.deal?.mrr_price ?? 0}
                        />
                    }
                    {props.deal?.payout
                        ?
                        <FormDataDisabled label={t('deal.saledate')} className='width-quarter'>
                            {formatDate(props.deal.start_date, 'DD/MM/YYYY')}
                        </FormDataDisabled>
                        :
                        <Input
                            id='start_date'
                            element='input'
                            type='date'
                            label={t('deal.saledate')}
                            className='width-quarter'
                            onInput={inputHandler}
                            validators={[]}
                            initialValid={formState.inputs.start_date.isValid}
                            initialValue={formState.inputs.start_date.value}
                        />
                    }
                </FormGroup>
                <FormGroup
                    className='form-distribution'
                    title={t('deal.payments')}
                    subtitle={t('deal.payments-st')}
                    titleStyle='upper-title'
                >
                    {props.deal?.payout
                        ?
                        <FormDataDisabledPayment data={props.deal?.payments} />
                        :
                        <FieldRepeater data={paymentsData} className='payments-repeater' />
                    }
                </FormGroup>
                <FormGroup
                    className='form-distribution'
                    title={t('deal.expenses')}
                    subtitle={t('deal.expenses-st')}
                    titleStyle='upper-title'
                >
                    {props.deal?.payout
                        ?
                        <FormDataDisabledPayment data={props.deal?.expenses} />
                        :
                        <FieldRepeater data={expensesData} className='expenses-repeater' />
                    }
                </FormGroup>
                <FormActions>
                    <FormFooter
                        actions={actions}
                    />
                </FormActions>
            </form>
        </>
    );
};

export default DealModal;
