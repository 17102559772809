import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import Status from '../../shared/components/UIElements/Status';

import formatMoney from '../../shared/utils/money';
import formatDate from '../../shared/utils/dates';
import { paymentStatuses } from '../data/PaymentOptions';

const PaymentItem = (props) => {

	
	const statusTable = paymentStatuses;
	const status = statusTable
		.filter((status) => status.value === props.status)
		.map((status) => status);

	return (
		<React.Fragment>
			<div className='item' key={props.id}>
				<div className={props.header[0] && props.header[0]?.style}>
					<span>{formatDate(props.start_date, 'DD/MM/YYYY')}</span>
				</div>
				<div className={props.header[1] && props.header[1]?.style}>
					<Status status={status[0]} />
				</div>
				<div className={props.header[2] && props.header[2]?.style}>
					<span>{formatMoney(props.price)}€</span>
				</div>
			</div>
		</React.Fragment>
	);
};

export default PaymentItem;
