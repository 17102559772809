import React, { useContext, useEffect, useState } from 'react';
import {
	VALIDATOR_REQUIRE,
	VALIDATOR_EMAIL,
	VALIDATOR_MINLENGTH,
} from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHistory } from 'react-router-dom';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Input from '../../shared/components/UIElements/Input';
import Button from '../../shared/components/UIElements/Button';
import ContentBox from '../../shared/components/Wrappers/ContentBox';
import MainTitle from '../../shared/components/Titles/MainTitle';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/ErrorMessage';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
// import Select from '../../shared/components/UIElements/Select';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';

// import Select from 'react-select';
import SelectWrapper from '../../shared/components/UIElements/SelectWrapper';

import './NewUser.css';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import PageHeader from '../../shared/components/Titles/PageHeader';
import FormFooter from '../../shared/components/Forms/FormFooter';
import InputSolo from '../../shared/components/UIElements/InputSolo';
import { userGroups, userRoles } from '../data/UserOptions';

import { useTranslation } from "react-i18next";

const NewUser = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const history = useHistory();

	const [formState, inputHandler] = useForm(
		{
			first_name: {
				value: '',
				isValid: true,
			},
			last_name: {
				value: '',
				isValid: true,
			},
			email: {
				value: '',
				isValid: true,
			},
			phone: {
				value: '',
				isValid: true,
			},
			password: {
				value: '',
				isValid: true,
			},
			role: {
				value: '',
				isValid: true,
			},
			group: {
				value: 'all',
				isValid: true,
			},
		},
		false
	);

	const formSubmitHandler = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				process.env.REACT_APP_API_URL + '/users/',
				'POST',
				JSON.stringify({
					first_name: formState.inputs.first_name.value,
					last_name: formState.inputs.last_name.value,
					email: formState.inputs.email.value,
					password: formState.inputs.password.value,
					phone: formState.inputs.phone.value,
					role: formState.inputs.role.value,
					group: formState.inputs.group.value,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/users/',
				noticeMsg: t('user.added'),
				noticeType: 'success',
			});
		} catch (error) {
			console.log(error);
		}
	};

	const actions = [
		{
			title: t('common.close'),
			onClick: () => history.goBack()
		},
		{
			title: t('user.add'),
			style: 'filled-blue',
			flaticon: 'fi-rr-check',
			form: 'add-user',
			type: 'submit',
			disabled: !formState.isValid
		}
	];

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					gobackOnClick={() => history.goBack()}
					title={t('user.add-t')}
					actions={actions}
					separator={true}
				/>
			</ContentHead>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			<form className='add-user' id='add-user' onSubmit={formSubmitHandler}>
				<FormGroup
					title={t('user.user-details')}
					subtitle={t('user.user-details-st')}
				>
					<Input
						id='first_name'
						element='input'
						type='text'
						placeholder={t('user.first-name-ph')}
						label={t('user.first-name')}
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
						errorText='Please input a valid text'
					/>
					<Input
						id='last_name'
						element='input'
						type='text'
						placeholder={t('user.last-name-ph')}
						label={t('user.last-name')}
						onInput={inputHandler}
						validators={[VALIDATOR_REQUIRE()]}
						errorText='Please input a valid text'
					/>
				</FormGroup>
				<FormGroup
					title={t('user.contact-information')}
					subtitle={t('user.contact-information-st')}
				>
					<Input
						id='email'
						element='input'
						type='email'
						placeholder={t('user.email-ph')}
						label={t('user.email')}
						onInput={inputHandler}
						validators={[VALIDATOR_EMAIL()]}
						errorText='Please input a valid email'
					/>
					<Input
						id='phone'
						element='input'
						type='phone'
						placeholder={t('user.phone-ph')}
						label={t('user.phone')}
						onInput={inputHandler}
						validators={[]}
						initialValid={true}
					/>
				</FormGroup>
				<FormGroup
					title={t('user.security')}
					subtitle={t('user.security-st')}
				>
					<Input
						id='password'
						element='input'
						type='password'
						placeholder={t('user.password-ph')}
						label={t('user.password')}
						onInput={inputHandler}
						validators={[VALIDATOR_MINLENGTH(8)]}
						errorText={t('user.password-err')}
					/>
				</FormGroup>
				<FormGroup
					title={t('user.access')}
					subtitle={t('user.access-st')}
				>
					<SelectWrapper
						onInput={inputHandler}
						id='role'
						label={t('user.user-type')}
						options={userRoles}
						validators={[]}
						disableInputText={true}
					/>
				</FormGroup>
				{formState.inputs.role.value && formState.inputs.role.value !== 'user' && <FormGroup
					title={t('user.group')}
					subtitle={t('user.group-st')}
				>
					<SelectWrapper
						onInput={inputHandler}
						id='group'
						label={t('user.user-group')}
						selectDisabler={true}
						selectDisablerTitle={t('user.notify-all')}
						options={userGroups}
						validators={[VALIDATOR_REQUIRE()]}
						initialValid={formState.inputs.group.isValid}
						initialValue={['all']}
						isMulti={true}
						disableInputText={true}
					/>
				</FormGroup>}
				<FormActions>
					<FormFooter
						actions={actions}
					/>
				</FormActions>
			</form>
		</ContentWrapper>
	);
};

export default NewUser;
