import React, { useEffect, useState } from 'react';
import ActionButton from '../UIElements/ActionButton';
import Pagination from './Pagination';
import InputSolo from '../UIElements/InputSolo';

import './Filters.css';
import FilterBox from './FilterBox';
import { useTranslation } from "react-i18next";

const Filters = (props) => {
    const { t } = useTranslation('common');
    const [showFilter, setShowFilter] = useState(false);
    const [filterActive, setFilterActive] = useState(false);

    // const [query, setQuery] = useState({});
    const [searchResults, setSearchResults] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1);
    const fetchQuery = (query) => props.filters.queryFn(new URLSearchParams(query).toString());
    const setQuery = (query) => props.filters.setQueryFn(query);
    const setCurrentPage = (page) => props.filters.setCurrentPage(page);

    const query = props.filters.query;

    // useEffect(() => {
    //     fetchQuery(query);
    //     console.log(query);
    // }, [query]);

    const changePage = (page) => {
        const newQuery = { ...query };
        newQuery.page = page;
        setCurrentPage(page);
        setQuery(newQuery);
        fetchQuery(newQuery);
    }

    const pagination = {
        count: props.filters?.totalRes ?? 1,
        per_page: props.filters?.limitRes ?? 25,
        currentPage: props.filters?.currentPage,
        setCurrentPage: changePage
    };

    const searchInput = (search) => {
        const newQuery = { ...query };
        if (search.target.value.length >= 3) {
            newQuery.search = search.target.value;
            setQuery(newQuery);
            setSearchResults(true);
            fetchQuery(newQuery);
        } else if (searchResults && newQuery.search) {
            newQuery.search = '';
            setQuery(newQuery);
            setSearchResults(false);
            fetchQuery(newQuery);
        }
    }

    const searchFilters = (filteredData) => {
        const newQuery = { ...query };
        Object.entries(filteredData).forEach(([key, value]) => {
            if (value) {
                newQuery[key] = value;
            } else {
                delete newQuery[key];
            }
        });
        newQuery.page = 1;
        setCurrentPage(1);
        setQuery(newQuery);
        fetchQuery(newQuery);
    }

    const toggleFilter = () => {
        if (showFilter) {
            setShowFilter(false);
        } else {
            setShowFilter(true);
        }
    }

    return (
        <React.Fragment>
            <div className={`filters ${!props.filter ? ' no-border' : ''}`}>
                <div className='left'>
                    {props.filter &&
                        <React.Fragment>
                            {props.filters && !props.filters.onlySearch &&
                                <>
                                    {props.filters.filtersStyle === 'buttons' &&
                                        props.filters.filtersData.map((filter) => (
                                            <ActionButton key={filter.label} onClick={() => searchFilters({[filter.id]: filter.value})} className={`filter-tab ${((filter.value === query[filter.id]) || (filter.value === '' && !query[filter.id])) ? 'active' : ''}`} text={filter.label} />
                                        ))
                                    }
                                    {!props.filters.filtersStyle &&
                                        <ActionButton onClick={toggleFilter} className={`${filterActive ? 'filter-active' : ''}`} flaticon='fi-rr-filter' text={t('common.filter')} />
                                    }
                                </>

                            }
                            {props.daterange && !props.filters.onlySearch &&
                                <ActionButton to='#' flaticon='fi-rr-calendar' text={t('common.filter')} />
                            }
                            {props.search &&
                                <InputSolo
                                    id='search'
                                    element='input'
                                    type='text'
                                    placeholder={t('common.search-ph')}
                                    flaticon='fi-rr-calendar'
                                    onChange={searchInput}
                                // validators={[]}
                                // initialValid={formState.inputs.search.isValid}
                                // initialValue={formState.inputs.search.value}
                                />

                            }
                        </React.Fragment>
                    }
                </div>
                <div className='right'>
                    {props.pagination &&
                        <Pagination pagination={pagination} />
                    }
                </div>
            </div>
            {(props.filter && props.filters && showFilter) &&
                <FilterBox filters={props.filters} onSubmit={searchFilters} setActive={setFilterActive} />
            }
        </React.Fragment>
    );
};

export default Filters;