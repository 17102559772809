import React, { useContext, useEffect, useState } from 'react';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Input from '../../shared/components/UIElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import SelectWrapper from '../../shared/components/UIElements/SelectWrapper';
import PageHeader from '../../shared/components/Titles/PageHeader';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import SelectAsyncWrapper from '../../shared/components/UIElements/SelectAsyncWrapper';
import FormFooter from '../../shared/components/Forms/FormFooter';
import SubscriptionTotals from '../components/SubscriptionTotals';

import formatDate from '../../shared/utils/dates';
import formatMoney from '../../shared/utils/money';

import './NewSubscription.css';
import { subscriptionPeriods } from '../data/SubscriptionOptions';

import { useTranslation } from "react-i18next";

const NewSubscription = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const history = useHistory();

	const projectId = useParams().pid;
	const [project, setProject] = useState();

	const [formState, inputHandler] = useForm();
	const [selectedServices, setSelectedServices] = useState([]);

	useEffect(() => {
		const fetchProject = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/projects/${projectId}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setProject(response.project);
			} catch (error) {
				console.log(error);
			}
		};
		if (projectId) {
			fetchProject();
		}
	}, [sendRequest, projectId, auth]);

	const formSubmitHandler = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				process.env.REACT_APP_API_URL + '/subscriptions/',
				'POST',
				JSON.stringify({
					period: formState.inputs.period.value,
					services: formState.inputs.services.value,
					project: formState.inputs.project.value,
					start_date: formState.inputs.start_date.value,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/subscriptions/',
				noticeMsg: t('subscription.added'),
				noticeType: 'success',
			});
		} catch (error) {
			console.log(error);
		}

		//console.log(formState.inputs); // send data to backend
	};

	const searchServices = async (inputValue) => {
		if (inputValue.length > 2) {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/services/s/`,
					'POST',
					JSON.stringify({
						search: inputValue,
						type: 'recurring',
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					},
					false
				);
				if (response.services) {
					return response.services;
				} else {
					return [];
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const searchProjects = async (inputValue) => {
		if (inputValue.length > 2) {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/projects/s/`,
					'POST',
					JSON.stringify({
						search: inputValue,
						type: 'recurring',
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					},
					false
				);
				if (response.projects) {
					return response.projects;
				} else {
					return [];
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const updateTotals = (values) => {
		setSelectedServices(values);
	}

	const formatOptionLabelService = (option) => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						flexGrow: '1',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{option.name}
				</div>
				<div style={{ textAlign: 'right', color: 'var(--darkgrey)' }}>
					{formatMoney(option.price)}€/{t('subscription.month-lc')}
				</div>
			</div>
		)
	}

	const formatOptionLabelProject = (option) => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						flexGrow: '1',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{option.name}
				</div>
				<div style={{ textAlign: 'right', color: 'var(--darkgrey)' }}>
					{option.domain.url.replace(/^https?:\/\//, '')}
				</div>
			</div>
		)
	}

	const actions = [
		{
			title: t('common.cancel'),
			onClick: () => history.goBack()
		},
		{
			title: t('subscription.add'),
			style: 'filled-blue',
			flaticon: 'fi-rr-check',
			form: 'add-subscription',
			type: 'submit',
			disabled: !formState.isValid
		}
	];

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					gobackOnClick={() => history.goBack()}
					title={t('subscription.add-t')}
					actions={actions}
					separator={true}
				/>
			</ContentHead>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			<form className='add-subscription' id='add-subscription' onSubmit={formSubmitHandler}>
				<FormGroup
					title={t('subscription.project')}
					subtitle={t('subscription.project-st')}
				>
					{project &&
						<SelectAsyncWrapper
							onInput={inputHandler}
							id='project'
							label={t('subscription.project-assigned')}
							initialValue={project}
							initialValid={true}
							formatOptionLabel={formatOptionLabelProject}
							getOptionLabel={(e) => e.name}
							getOptionValue={(e) => e._id}
							isMulti={false}
							isDisabled={true}
						/>}
					{!projectId &&
						<SelectAsyncWrapper
							onInput={inputHandler}
							id='project'
							label={t('subscription.project-assigned')}
							loadOptions={searchProjects}
							formatOptionLabel={formatOptionLabelProject}
							getOptionLabel={(e) => e.name}
							getOptionValue={(e) => e._id}
							isMulti={false}
						/>
					}
				</FormGroup>
				<FormGroup
					title={t('subscription.settings')}
					subtitle={t('subscription.settings-st')}
				>
					<SelectWrapper
						onInput={inputHandler}
						initialValue={'month'}
						id='period'
						label={t('subscription.period')}
						options={subscriptionPeriods}
						initialValid={true}
					/>
					<Input
						id='start_date'
						element='input'
						type='date'
						label={t('subscription.start-date')}
						onInput={inputHandler}
						validators={[]}
						initialValid={true}
						initialValue={formatDate(new Date())}
					/>
				</FormGroup>
				<FormGroup
					title={t('subscription.services')}
					subtitle={t('subscription.services-st')}
				>
					<SelectAsyncWrapper
						onInput={inputHandler}
						id='services'
						label={t('subscription.services-included')}
						loadOptions={searchServices}
						className='one-row-item'
						dropdownIcon='fi-rr-search'
						formatOptionLabel={formatOptionLabelService}
						getOptionLabel={(e) => e.name}
						getOptionValue={(e) => e._id}
						isMulti={true}
						onChange={updateTotals}
					/>
					<SubscriptionTotals services={selectedServices} period={formState.inputs?.period?.value} />
				</FormGroup>
				<FormActions>
					<FormFooter
						actions={actions}
					/>
				</FormActions>
			</form>
		</ContentWrapper>
	);
};

export default NewSubscription;
