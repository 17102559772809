import React, { useContext, useEffect, useState } from 'react';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import PageHeader from '../../shared/components/Titles/PageHeader';
import DashboardWrapper from '../../shared/components/Dashboards/DashboardWrapper';
import DashboardColumn from '../../shared/components/Dashboards/DashboardColumn';
import DashboardBox from '../../shared/components/Dashboards/DashboardBox';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';

import './Dashboard.css';
import DashboardKPI from '../../shared/components/Dashboards/DashboardKPI';
import Tabs from '../../shared/components/Results/Tabs';
import DashboardSection from '../../shared/components/Dashboards/DashboardSection';
import formatMoney from '../../shared/utils/money';
import DashboardSubscriptions from '../components/DashboardSubscriptions';
import DashboardDeals from '../components/DashboardDeals';

import { useTranslation } from "react-i18next";

const Dashboard = (props) => {
	const { t, i18n } = useTranslation('common');
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const auth = useContext(AuthContex);
	const [tab, setTab] = useState('default');
	const [kpis, setKPIs] = useState();
	const [subscriptionsKpis, setSubscriptionsKPIs] = useState();
	const [dealsKpis, setDealsKPIs] = useState();

	const [period, setPeriod] = useState('year');
	const [periodNumber, setPeriodNumber] = useState(0);

	useEffect(() => {
		const fetchKPIs = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/kpis/`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setKPIs(response);
			} catch (error) {
				console.log(error);
			}
		};
		fetchKPIs();
		const fetchSubscriptionsKPIs = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/kpis/subscriptions?period=${period}&periodNumber=${periodNumber}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setSubscriptionsKPIs(response);
			} catch (error) {
				console.log(error);
			}
		};
		fetchSubscriptionsKPIs();
		const fetchDealsKPIs = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/kpis/deals?period=${period}&periodNumber=${periodNumber}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setDealsKPIs(response);
			} catch (error) {
				console.log(error);
			}
		};
		fetchDealsKPIs();
	}, [sendRequest, auth, period, periodNumber]);

	if (isLoading) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	const tabs = [
		{
			id: 'default',
			title: t('dashboard.thisyear'),
			onClick: () => {
				setPeriod('year');
				setPeriodNumber(0);
				setTab('default');
			}
			// to: '/companies/' + companyId + '/invoices/',
		},
		// {
		// 	id: 'last_year',
		// 	title: t('dashboard.lastyear'),
		// 	status: 'disabled'
		// },
		{
			id: 'this_month',
			title: t('dashboard.thismonth'),
			onClick: () => {
				setPeriod('month');
				setPeriodNumber(0);
				setTab('this_month');
			}
			// to: '/companies/' + companyId + '/invoices/',
			// status: 'disabled'
		},
		{
			id: 'last_month',
			title: t('dashboard.lastmonth'),
			onClick: () => {
				setPeriod('month');
				setPeriodNumber(1);
				setTab('last_month');
			}
			// to: '/companies/' + companyId + '/invoices/',
			// status: 'disabled'
		},
	];

	return (
		<ContentWrapper>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{!isLoading && kpis && subscriptionsKpis && dealsKpis && (
				<React.Fragment>
					<ContentHead>
						<PageHeader
							title={t('dashboard.title')}
						/>
					</ContentHead>
					<DashboardSection
						title={t('dashboard.mainkpis')}
						subtitle={t('dashboard.mainkpis-st')}
					/>
					<DashboardWrapper>
						<DashboardColumn className='flex-small'>
							<DashboardKPI
								title={t('models.projects')}
								data={kpis.projects}
								action={[{ to: '#', text: 'View projects' }]}
							/>
						</DashboardColumn>
						<DashboardColumn className='flex-small'>
							<DashboardKPI
								title={t('models.subscriptions')}
								data={kpis.subscriptions}
								action={[{ to: '#', text: 'View subscriptions' }]}
							/>
						</DashboardColumn>
						<DashboardColumn className='flex-small'>
							<DashboardKPI
								title={t('dashboard.mrr')}
								data={`${formatMoney(kpis.mrr)}€`}
								action={[{ to: '#', text: 'View subscriptions' }]}
							/>
						</DashboardColumn>
					</DashboardWrapper>

					<Tabs tabs={tabs} current={tab} />
					<DashboardSection
						title={t('models.subscriptions')}
						subtitle={t('dashboard.subscriptions-st')}
					/>
					<DashboardWrapper>
						<DashboardColumn className='flex-small'>
							<DashboardKPI
								title={t('dashboard.subscriptions-new')}
								data={subscriptionsKpis.new_subscriptions}
							// extra='+7%'
							/>
						</DashboardColumn>
						<DashboardColumn className='flex-small'>
							<DashboardKPI
								title={t('dashboard.subscriptions-new-amount')}
								data={`${formatMoney(subscriptionsKpis.mrr)}€`}
							// extra='+16%'
							/>
						</DashboardColumn>
						<DashboardColumn className='flex-small'>
							<DashboardKPI
								title={t('dashboard.subscriptions-lost')}
								data={subscriptionsKpis.cancelled_subscriptions}
							// extra='+7%'
							/>
						</DashboardColumn>
						<DashboardColumn className='flex-small'>
							<DashboardKPI
								title={t('dashboard.subscriptions-lost-amount')}
								data={`${formatMoney(subscriptionsKpis.mrr_lost)}€`}
							// extra='+16%'
							/>
						</DashboardColumn>
					</DashboardWrapper>
					<DashboardWrapper className='mt-20'>
						<DashboardColumn>
							<DashboardBox
								title={t('dashboard.subscriptions-last')}
							>
								<DashboardSubscriptions limit={6} hideColumnsIndex={[4]} />
							</DashboardBox>
						</DashboardColumn>
					</DashboardWrapper>

					<DashboardSection
						title={t('models.deals')}
						subtitle={t('dashboard.deals-st')}
					/>
					<DashboardWrapper>
						<DashboardColumn className='flex-small'>
							<DashboardKPI
								title={t('dashboard.deals-new')}
								data={dealsKpis.new_deals}
							// extra='+7%'
							/>
						</DashboardColumn>
						<DashboardColumn className='flex-small'>
							<DashboardKPI
								title={t('dashboard.deals-new-amount')}
								data={`${formatMoney(dealsKpis.deals_income)}€`}
							// extra='+13%'
							/>
						</DashboardColumn>
						<DashboardColumn className='flex-small'>
							<DashboardKPI
								title={t('dashboard.deals-mrr')}
								data={dealsKpis.mrr_deals}
							// extra='+4%'
							/>
						</DashboardColumn>
						<DashboardColumn className='flex-small'>
							<DashboardKPI
								title={t('dashboard.deals-mrr-amount')}
								data={`${formatMoney(dealsKpis.mrr_deals_income)}€`}
							// extra='+13%'
							/>

						</DashboardColumn>
					</DashboardWrapper>
					<DashboardWrapper className='mt-20'>
						<DashboardColumn>
							<DashboardBox
								title={t('dashboard.deals-last')}
							>
								<DashboardDeals limit={6} hideColumnsIndex={[1, 4, 6, 7]} />
							</DashboardBox>
						</DashboardColumn>
					</DashboardWrapper>
					{/* <ServerBox styleType='small' project={project.id} /> */}
				</React.Fragment>
			)}
		</ContentWrapper>
	);
};

export default Dashboard;
