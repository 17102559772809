import React, { useState, useEffect, useContext } from 'react';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useLocation } from 'react-router-dom';

import ProjectList from '../components/ProjectList';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ItemList from '../../shared/components/UIElements/ItemList';
import Notice from '../../shared/components/UIElements/Notice';
import PageHeader from '../../shared/components/Titles/PageHeader';
import Filters from '../../shared/components/Results/Filters';

import './Projects.css';
import { projectStatuses } from '../data/ProjectOptions';
import { useTranslation } from "react-i18next";

const Projects = (props) => {
	const { t } = useTranslation('common');
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [projectList, setProjectList] = useState();
	const auth = useContext(AuthContex);
	const location = useLocation();
	const [noticeMsg, setNoticeMsg] = useState();
	const [noticeType, setnoticeType] = useState();

	// Pagination, Search & Filter
	const [totalResults, setTotalResults] = useState(0);
	const [perPageLimit, setPerPageLimit] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [query, setQuery] = useState({});
	const fetchResultsQuery = async (query) => {
		try {
			const response = await sendRequest(
				process.env.REACT_APP_API_URL + '/projects/?' + query,
				'GET',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			setProjectList(response.projects);
			setTotalResults(response.count);
		} catch (error) {
			console.log(error);
		}
	};
	const filters = {
		queryFn: fetchResultsQuery,
		setQueryFn: setQuery,
		query: query,
		totalRes: totalResults,
		limitRes: perPageLimit,
		currentPage: currentPage,
		setCurrentPage: setCurrentPage,
		filtersStyle: 'buttons',
		filtersData: [
			{
				id: 'status',
				type: 'button',
				label: t('project.status-all'),
				value: '',
			},
			{
				id: 'status',
				type: 'button',
				label: t('project.status-waiting'),
				value: 'waiting',
			},
			{
				id: 'status',
				type: 'button',
				label: t('project.status-development'),
				value: 'development',
			},
			{
				id: 'status',
				type: 'button',
				label: t('project.status-active'),
				value: 'active',
			},
			{
				id: 'status',
				type: 'button',
				label: t('project.status-archived'),
				value: 'archived',
			},
			// filtersData: [
			// 	{
			// 		id: 'status',
			// 		type: 'select',
			// 		label: 'Status',
			// 		options: projectStatuses,
			// 	}
		]
	};
	// End Pagination, Search & Filter

	useEffect(() => {
		setNoticeMsg(location.noticeMsg);
		setnoticeType(location.noticeType);
	}, [location]);

	useEffect(() => {
		const fetchProjects = async () => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_API_URL + '/projects/',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setProjectList(response.projects);
				setTotalResults(response.count);
				setPerPageLimit(response.per_page);
			} catch (error) {
				console.log(error);
			}
		};
		fetchProjects();
	}, [sendRequest, auth]);

	const itemsHeader = [
		{
			title: t('tables.name'),
			style: 'flex-big'
		},
		{
			title: t('tables.domain'),
			style: 'flex-big'
		},
		{
			title: t('tables.status')
		},
		{
			title: t('tables.actions')
		}];

	const actions = [
		{
			title: t('project.add'),
			flaticon: 'fi-br-plus-small',
			style: 'filled-blue',
			to: '/projects/new'
		}
	];

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					title={t('models.projects')}
					// subtitle='List of all the client’s companies.'
					actions={actions}
				// subactions={subactions}
				/>
				{auth.role !== 'user' && <Filters pagination={true} filter={true} filters={filters} search={true} />}
			</ContentHead>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{noticeMsg && <Notice type={noticeType} message={noticeMsg} />}
			<ItemList
				header={itemsHeader}
				actions={true}
				columns={4}
				template='boxed'
				align='center'
			>
				{!isLoading && projectList && <ProjectList projects={projectList} header={itemsHeader} />}
			</ItemList>
			{(totalResults > perPageLimit) && <Filters pagination={true} filters={filters} />}
		</ContentWrapper>
	);
};

export default Projects;
