import React, { useReducer, useEffect, useState } from 'react';

import { validate } from '../../utils/validators';
import './Input.css';

const inputReducer = (state, action) => {
	switch (action.type) {
		case 'change':
			return {
				...state,
				value: action.val,
				isValid: validate(action.val, action.validators),
				isChanged: true,
			};
		case 'touch':
			return {
				...state,
				isTouched: true,
			};
		default:
			return state;
	}
};

const Input = (props) => {
	const [inputState, dispatch] = useReducer(inputReducer, {
		value: props.initialValue || '',
		isValid: props.initialValid || false,
		isTouched: false,
		isChanged: false
	});

	const { id, onInput } = props;
	const { value, isValid } = inputState;
	const [checked, setChecked] = useState(props.checked || false)

	useEffect(() => {
		onInput(props.id, inputState.value, inputState.isValid)
	}, [id, value, isValid, onInput]);

	const changeHandler = (event) => {
		if (props.onChange) {
			props.onChange(event);
		}
		if (props.type === 'checkbox'){
			setChecked(event.target.checked);
			event.target.value = event.target.checked;
		}
		dispatch({ type: 'change', val: event.target.value, validators: props.validators });
	};

	// const inputHandlerAdditional = (event) => {
	// 	if (props.onInputAditional) {
	// 		props.onInputAditional(event);
	// 	}
	// }

	const touchHandler = () => {
		dispatch({
			type: 'touch'
		});
	}

	const element =
		props.element === 'input' ? (
			props.type === 'checkbox' ? (
				<div className='cb-container'>
					<input
						id={props.id}
						type={props.type}
						placeholder={props.placeholder}
						className={`${props.className || ''}${props.style || ' cb-tgl-light'}`}
						onChange={changeHandler}
						onBlur={touchHandler}
						// onInput={inputHandlerAdditional}
						disabled={props.disabled}
						checked={checked}
					/>
					<label htmlFor={props.id} className='cb-tgl'></label>
				</div>
			) : (
				<input
					id={props.id}
					type={props.type}
					value={inputState.value}
					placeholder={props.placeholder}
					className={props.className}
					onChange={changeHandler}
					onBlur={touchHandler}
					// onInput={inputHandlerAdditional}
					disabled={props.disabled}
				/>
			)
		) : (
			<textarea
				id={props.id}
				value={inputState.value}
				placeholder={props.placeholder}
				rows={props.rows || 3}
				className={props.className}
				onChange={changeHandler}
				onBlur={touchHandler}
				// onInput={inputHandlerAdditional}
				disabled={props.disabled}
			>{inputState.value}</textarea>
		);

	return (
		<div className={`form-control ${props.className || ''} ${(!inputState.isValid && inputState.isTouched && 'form-control--invalid') || ''} ${(inputState.isValid && inputState.isChanged && 'form-control--valid') || ''}`}>
			{props.label && (<label htmlFor={props.id}>{props.label}</label>)}
			{element}
			{/* {!inputState.isValid && inputState.isTouched && <span className="notice small error">{props.errorText}</span>} */}
			{!inputState.isValid && inputState.isTouched && <p className='form-control-notice-error'>{props.errorText}</p>}
		</div>
	);
};

export default Input;
