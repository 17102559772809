import Translate from "../../shared/components/Utils/Translate";

const subscriptionPeriods = [
    { value: 'month', label: <Translate t='subscription.monthly' /> },
    { value: 'quarter', label: <Translate t='subscription.quarterly' /> },
    { value: 'semester', label: <Translate t='subscription.semesterly' /> },
    { value: 'year', label: <Translate t='subscription.yearly' /> }
];

const subscriptionStatuses = [
    { value: 'active', label: <Translate t='subscription.status-active' /> },
    { value: 'pending', label: <Translate t='subscription.status-pending' /> },
    { value: 'cancelled', label: <Translate t='subscription.status-cancelled' /> },
];

export { subscriptionPeriods, subscriptionStatuses };