import React from 'react';
import { useTranslation } from "react-i18next";


const Translate = (props) => {
  const { t, i18n } = useTranslation('common');
	return t(props.t);
};

export default Translate;
