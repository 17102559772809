import React from 'react';
import { Link } from 'react-router-dom';

import './Tabs.css';

const Tabs = (props) => {
    return (
        <ul className={`tabs ${props.className ? props.className : ''}`}>
            {props.tabs &&
                props.tabs.map((tab, index) => (
                    <li key={index} className={`tab-status${tab.status ? ' ' + tab.status : ''}${(props.current && props.current === tab.id) ? ' active' : ''}`}>
                        {tab.to &&
                            <Link to={tab.to} onClick={tab.onClick}>
                                {tab.title}
                            </Link>
                        }
                        {!tab.to &&
                            <Link to='#' onClick={tab.onClick}>
                                {tab.title}
                            </Link>
                        }
                    </li>
                ))
            }
        </ul>
    );
};

export default Tabs;