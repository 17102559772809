import React from 'react';
import NoteItem from './NoteItem';

const NoteList = (props) => {
	return (
		<React.Fragment>
				{props.notes.map((note) => {
					return (
						<NoteItem
							key={note.id}
							id={note.id}
							status={note.status}
							content={note.content}
							note={note}
							date={note.updated_at}
							created_by={note.created_by}
							header={props.header}
							completeTask={props.completeTask}
							archiveTask={props.archiveTask}
							restoreTask={props.restoreTask}
							// deleteTask={props.deleteTask}
							assigned_to_user={note.assigned_to_user}
							assigned_to_group={note.assigned_to_group}
						/>
					);
				})}
		</React.Fragment>
	);
};

export default NoteList;
