import React, { useState } from "react";
import Modal from "../../shared/components/UIElements/Modal";
import ActionButton from "../../shared/components/UIElements/ActionButton";
import NoteObject from "./NoteObject";

import './NoteModal.css';
import NoteData from "./NoteData";
import { capitalizeText } from "../../shared/utils/text";
import { noteFeatures } from "../data/NoteOptions";

import { useTranslation } from "react-i18next";

const NoteModal = (props) => {

    const { t } = useTranslation('common');

    const toggleModal = () => {
		if(openModal){
			setOpenModal(false);
		}else{
			setOpenModal(true);
		}
	}

    let maxLength = props.limit ?? 35;
	let smallText = props.note.content.substr(0, maxLength);
	smallText = <span onClick={toggleModal} className='pointer'>{smallText.substr(0, Math.min(smallText.length, smallText.lastIndexOf(" ")))}</span>;

    let expandable = true;
    if(props.note.content.length < maxLength){
        expandable = false;
        smallText = props.note.content;
    }
    let metadata = false;
    let metadataOnclick;
    if(props.note.data){
        metadata = true;
        metadataOnclick = () => toggleModal();
    }
	const [openModal, setOpenModal] = useState(false);

  
    const noteTitle = props.note.feature ? noteFeatures[props.note.feature] : 'Task';

	return (
		<React.Fragment>
			<span className={`inline-note ${metadata ? 'pointer' : ''}`} onClick={metadataOnclick}><span>{smallText}{expandable && '...'}</span>{/*(expandable || metadata) && <ActionButton onClick={(e) => toggleModal()} flaticon='fi-rr-plus-small' />*/}</span>
			{(expandable || metadata) &&
				<Modal
                show={openModal}
                title={<span className='notes-head'><span>{noteTitle}</span><NoteObject object={props.note} /></span>}
                closeAction={toggleModal}
                // className='notes'
                hook='modal-hook'
                footer={
                    <div className='modal-footer-actions'>
                        <div className='action-notes'>
                            <ActionButton text={t('common.close')} onClick={toggleModal} />
                        </div>
                    </div>
                }
            >
                {!props.note.data && props.note.content}
                {props.note.data && <NoteData note={props.note} />}
                {/* {props.note.data} */}
            </Modal>
			}
		</React.Fragment>
	);
}

export default NoteModal;