import Translate from "../../shared/components/Utils/Translate";

const companyStatuses = [
    { value: 'active', label: 'Active' },
    { value: 'archived', label: 'Archived' },
];

const companyTaxes = [
    { value: '21', label: <Translate t='company.tax21' /> },
    { value: '0', label: <Translate t='company.tax0' /> },
];

const companyPaymentPlatforms = [
    { value: 'stripe', label: <Translate t='company.stripe' /> },
    { value: 'bank', label: <Translate t='company.bank' /> },
];

const companyPaymentMethods = [
    { value: 'sepa', label: <Translate t='company.sepa' /> },
    { value: 'card', label: <Translate t='company.credit-card' /> },
    { value: 'transfer', label: <Translate t='company.bank-transfer' /> },
];

export { companyStatuses, companyTaxes, companyPaymentPlatforms, companyPaymentMethods };