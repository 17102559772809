import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const UserAuth = () => {
    const storedToken = JSON.parse(localStorage.getItem('userData'))?.token ? JSON.parse(localStorage.getItem('userData')).token : false;
    const [token, setToken] = useState(storedToken); // change to false to make unlogged by default
    const [userId, setUserId] = useState(false);
    const [tokenExpirationDate, setTokenExpirationDate] = useState(false);

    const [role, setRole] = useState(false);
    const [email, setEmail] = useState(false);
    const [first_name, setFn] = useState(false);
    const [last_name, setLn] = useState(false);

    const login = useCallback(
        (uid, token, role, email, first_name, last_name, expirationDate) => {
            setUserId(uid);
            setToken(token);
            setRole(role);
            setEmail(email);
            setFn(first_name);
            setLn(last_name);
            const newTokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7); // 1000 * 60 * 60 * 24 * 7 = 7 days (check back user controller 7d)
            setTokenExpirationDate(newTokenExpirationDate);
            localStorage.setItem('userData', JSON.stringify({ userId: uid, token: token, expiration: newTokenExpirationDate.toISOString(), role: role, email: email, first_name: first_name, last_name: last_name }));
        }
        , []);
    const logout = useCallback(() => {
        setUserId(null);
        setToken(null);
        setRole(null);
        setEmail(null);
        setFn(null);
        setLn(null);
        setTokenExpirationDate(null);
        localStorage.removeItem('userData');
    }, []);

    useEffect(() => {
        if (token && tokenExpirationDate) {
            const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
            logoutTimer = setTimeout(logout, remainingTime);
        } else {
            clearTimeout(logoutTimer);
        }
    }, [token, logout, tokenExpirationDate]);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
            login(storedData.userId, storedData.token, storedData.role, storedData.email, storedData.first_name, storedData.last_name);
        }
    }, [login]);

    return { token, login, logout, userId, role, email, first_name, last_name }
}