import React from 'react';

import './Status.css';
import Icon from './Icon';

const Status = (props) => {

    let statuses = {
        green: ['active', 'live', 'completed', 'paid'],
        red: ['cancelled'],
        orange: ['development', 'paused', 'processing', 'pending'],
        blue: ['setup'],
        grey: ['refunded', 'waiting', 'archived']
    }

    let color = '';
    let text;
    let icon = props.icon;
    if (props.status) {
        if (statuses.green.includes(props.status.value) || statuses.green.includes(props.status)) {
            color = 'green';
        } else if (statuses.red.includes(props.status.value) || statuses.red.includes(props.status)) {
            color = 'red';
        } else if (statuses.orange.includes(props.status.value) || statuses.orange.includes(props.status)) {
            color = 'orange'
        } else if (statuses.blue.includes(props.status.value) || statuses.blue.includes(props.status)) {
            color = 'blue'
        } else if (statuses.grey.includes(props.status.value) || statuses.grey.includes(props.status)) {
            color = 'grey'
        } else {
            color = '';
        }
        text = props.status.label;
        icon = props.icon ?? props.status.icon;
    }
    if (props.color) {
        color = props.color;
    }

    if (props.text) {
        text = props.text;
    }

    return <span className={`status ${!text ? 'only-icon' : ''} ${color}${props.className ? ' ' + props.className : ''}`}><Icon flaticon={icon} />{text && <span>{text}</span>}{props.children}</span>;
};

export default Status;
