import React from 'react';

import { useHttpClient } from '../../shared/hooks/http-hook';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ItemList from '../../shared/components/UIElements/ItemList';
import ProjectList from '../../projects/components/ProjectList';
import Empty from '../../shared/components/Results/Empty';
import ActionButton from '../../shared/components/UIElements/ActionButton';

import { useTranslation } from "react-i18next";

const CompanyProjects = (props) => {
    const { t } = useTranslation('common');
    let projects = props.company.projects;
    const { isLoading, errorMsg, sendRequest } = useHttpClient();

    let itemsHeader = [
        {
            title: t('tables.project'),
            style: 'flex-big'
        },
        {
            title: t('tables.domain'),
            style: 'flex-big'
        },
        {
            title: t('tables.status')
        },
        {
            title: t('tables.actions')
        }];

    if (props.hideColumnsIndex) {
        itemsHeader = itemsHeader.map((item, index) => {
            const style = item.style || '';
            const hiddenStyle = props.hideColumnsIndex.includes(index) ? 'hidden' : '';
            return { ...item, style: `${style} ${hiddenStyle}`.trim() };
        });
    }

    if(props.limit){
        projects = projects.slice(0, props.limit);
    }

    if (isLoading) {
        return <LoadingSpinner></LoadingSpinner>;
    }

    const emptyAction = {
        title: t('project.add'),
        flaticon: 'fi-br-plus-small',
        style: '',
        to: '/projects/new'
    };

    return (
        <React.Fragment>
            {isLoading && <LoadingSpinner />}
            {errorMsg && <Notice type='error' message={errorMsg} />}
            {!isLoading && projects && projects.length > 0 && (
                <React.Fragment>
                    <ItemList
                        header={itemsHeader}
                        actions={true}
                        columns={4}
                        template='boxed'
                        align='center'
                    >
                        {!isLoading && projects && <ProjectList projects={projects} header={itemsHeader} />}
                    </ItemList>
                </React.Fragment>
            )}
            {projects && projects.length === props.limit &&
                <ActionButton className='m-0a mt-10' text={t('common.viewall')} onClick={props.viewAll} />
            }
            {projects && projects.length === 0 &&
                <Empty type='info' message={t('project.not-found')} action={emptyAction} />
            }
        </React.Fragment>
    );
};

export default CompanyProjects;
