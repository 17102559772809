import React from 'react';

import './Tooltip.css';

const Tooltip = (props) => {
  if (props.dangerouslySetInnerHTML) {
    return (
      <span className={`tooltip-content ${props.className || ''}`}>
        <span
          dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        />
      </span>
    );
  } else {
    return (
      <span className={`tooltip-content ${props.className || ''}`}>
        <span>
          {props.children}
        </span>
      </span>
    );
  }
};

export default Tooltip;