import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';

import ContentBox from '../../shared/components/Wrappers/ContentBox';
import MainTitle from '../../shared/components/Titles/MainTitle';
import Input from '../../shared/components/UIElements/Input';
import Button from '../../shared/components/UIElements/Button';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ActionsWrapper from '../../shared/components/Wrappers/ActionsWrapper';
import Modal from '../../shared/components/UIElements/Modal';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import SelectWrapper from '../../shared/components/UIElements/SelectWrapper';
import Notice from '../../shared/components/UIElements/Notice';

import './EditService.css';
import PageHeader from '../../shared/components/Titles/PageHeader';
import Tabs from '../../shared/components/Results/Tabs';
import FormFooter from '../../shared/components/Forms/FormFooter';
import ServiceTotals from '../components/ServiceTotals';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import { serviceTypes } from '../data/ServiceOptions';

import { useTranslation } from "react-i18next";

const EditService = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [service, setLoadedService] = useState();
	const [subscriptions, setLoadedSubscriptions] = useState();
	const serviceId = useParams().sid;
	const history = useHistory();
	const [modalConfirmDelete, setmodalConfirmDelete] = useState(false);

	const openConfirmDelete = () => {
		if (modalConfirmDelete) {
			setmodalConfirmDelete(false);
		} else {
			setmodalConfirmDelete(true);
		}
	};

	const [formState, inputHandler, setFormData] = useForm();

	useEffect(() => {
		const fetchService = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/services/${serviceId}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setLoadedService(response.service);
				setFormData(
					{
						type: {
							value: response.service.type,
							isValid: true,
						},
						name: {
							value: response.service.name,
							isValid: true,
						},
						description: {
							value: response.service.description,
							isValid: true,
						},
						price: {
							value: response.service.price,
							isValid: true,
						},
					},
					true
				);
			} catch (error) {
				console.log(error);
			}
		};
		const fetchSubscriptions = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/subscriptions/service/${serviceId}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setLoadedSubscriptions(response.subscriptions);
			} catch (error) {
				console.log(error);
			}
		};
		fetchService();
		fetchSubscriptions();
	}, [sendRequest, serviceId, setFormData]);

	const formSubmitHandler = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				`${process.env.REACT_APP_API_URL}/services/${serviceId}`,
				'PATCH',
				JSON.stringify({
					type: formState.inputs.type.value,
					name: formState.inputs.name.value,
					description: formState.inputs.description.value,
					price: formState.inputs.price.value,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/services/',
				noticeMsg: t('service.added'),
				noticeType: 'success',
			});
		} catch (error) {
			console.log(error);
		}
	};

	const confirmDelete = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				`${process.env.REACT_APP_API_URL}/services/${serviceId}`,
				'DELETE',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			history.push({
				pathname: '/services/',
				noticeMsg: t('service.removed'),
				noticeType: 'success',
			});
		} catch (error) {
			openConfirmDelete();
		}
	};

	if (isLoading) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	if (!service) {
		return <div>{t('service.not-found')}</div>;
	}

	const actions = [
		{
			title: t('common.cancel'),
			onClick: () => history.goBack()
		},
		{
			title: t('common.save'),
			style: 'filled-blue',
			flaticon: 'fi-rr-check',
			form: 'edit-service',
			type: 'submit',
			disabled: !formState.isValid
		}
	];

	const subactions = [
		{
			title: t('service.remove'),
			flaticon: 'fi-rr-trash',
			onClick: openConfirmDelete
		}
	];

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					gobackOnClick={() => history.goBack()}
					title={service.name}
					actions={actions}
					subactions={subactions}
					separator={true}
				/>
			</ContentHead>
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{!isLoading && service && (
				<form onSubmit={formSubmitHandler} id='edit-service'>
					<FormGroup
						title={t('service.information')}
						subtitle={t('service.information-st')}
					>
						<Input
							id='name'
							element='input'
							type='text'
							placeholder={t('service.name-ph')}
							label={t('service.name')}
							onInput={inputHandler}
							validators={[VALIDATOR_REQUIRE()]}
							errorText='Please input a valid text'
							initialValid={formState.inputs.name.isValid}
							initialValue={formState.inputs.name.value}
						/>
						<SelectWrapper
							onInput={inputHandler}
							// initialValue={'web'}
							id='type'
							label={t('service.type')}
							options={serviceTypes}
							initialValid={true}
							initialValue={formState.inputs.type.value}
						/>
						<Input
							id='description'
							element='input'
							type='text'
							placeholder={t('service.description-ph')}
							label={t('service.description')}
							onInput={inputHandler}
							validators={[]}
							initialValid={true}
							initialValue={formState.inputs.description.value}
						/>
						<Input
							id='price'
							element='input'
							type='number'
							placeholder={t('service.price-ph')}
							label={t('service.price')}
							onInput={inputHandler}
							validators={[]}
							initialValid={true}
							initialValue={formState.inputs.price.value}
						/>
						<ServiceTotals price={formState.inputs.price.value} type={formState.inputs.type.value} />
					</FormGroup>
					<FormActions>
						<FormFooter
							actions={actions}
						/>
					</FormActions>
				</form>
			)}
			{subscriptions &&
				<Modal
					show={modalConfirmDelete}
					title={t('service.remove')}
					closeAction={openConfirmDelete}
					footer={
						<div className='modal-footer-actions'>
							<ActionButton text={(subscriptions && subscriptions.length > 0) ? t('common.confirm-archive') : t('common.confirm-remove')} style={`red-delete`} onClick={confirmDelete} />
							<ActionButton text={t('common.close')} onClick={openConfirmDelete} />
						</div>
					}
				>
					<Notice type='danger' message={t('common.action-irreversible')} />
					{(subscriptions && subscriptions.length > 0) &&
						<React.Fragment>
							<Notice type='alert' message={t('service.danger-associated-subscriptions', {subscriptions: subscriptions.length, subscriptionsWord: subscriptions.length > 1 ? t('models.subscription-plur') : t('models.subscription-sing')})} />
							<p>{t('service.notice-archive')}</p>
						</React.Fragment>
					}
					{(subscriptions && subscriptions.length === 0) &&
						<React.Fragment>
							<p>{t('service.notice-remove')}</p>
						</React.Fragment>
					}

				</Modal>
			}
		</ContentWrapper>
	);
};

export default EditService;
