import Translate from "../../shared/components/Utils/Translate";

const userRoles = [
    { value: 'super', label: <Translate t='user.role-super' /> },
    { value: 'manager', label: <Translate t='user.role-manager' /> },
    { value: 'user', label: <Translate t='user.role-user' /> },
];

const userGroups = [
    { value: 'sales', label: <Translate t='user.group-sales' /> },
    { value: 'tech', label: <Translate t='user.group-tech' /> },
    { value: 'marketing', label: <Translate t='user.group-marketing' /> },
    { value: 'finance', label: <Translate t='user.group-finance' /> },
];

export { userRoles, userGroups };