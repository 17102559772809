import React, { useReducer, useEffect, useState } from 'react';

import Select, { components } from 'react-select';
import Icon from './Icon';
// import Async, { useAsync } from 'react-select/async';
// import AsyncSelect from 'react-select/async';
// import { handleInputChange } from 'react-select/dist/declarations/src/utils';

import './SelectWrapper.css';
import InputSolo from './InputSolo';

const selectReducer = (state, action) => {
	switch (action.type) {
		case 'change':
			return {
				...state,
				value: action.val,
				isValid: true,
			};
		case 'touch':
			return {
				...state,
				isTouched: true,
			};
		default:
			return state;
	}
};

const DropdownIndicator = props => {
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...props}>
				<Icon flaticon='fi-rr-angle-small-down' />
			</components.DropdownIndicator>
		)
	);
};

const ClearIndicator = props => {
	return (
		components.ClearIndicator && (
			<components.ClearIndicator {...props}>
				<Icon flaticon='fi-rr-cross-small' />
			</components.ClearIndicator>
		)
	);
}

const SelectWrapper = (props) => {
	const [inputState, dispatch] = useReducer(selectReducer, {
		value: props.initialValue || '',
		isValid: props.initialValid || false,
		isTouched: false,
	});

	const { id, onInput } = props;
	const { value, isValid } = inputState;

	useEffect(() => {
		onInput(props.id, inputState.value, inputState.isValid);
	}, [id, value, isValid, onInput]);

	const [selectedOption, setSelectedOption] = useState(
		props.isMulti
			? props.initialValue && Array.isArray(props.initialValue)
				? props.initialValue
				: []
			: inputState.value
	);

	const checkInitialDisabled = () => {
		if (props.isMulti && props.selectDisabler) {
			return JSON.stringify(selectedOption) === JSON.stringify(["all"]);
		}
		return props.selectDisabler ?? false;
	};
	const [isDisabled, setIsDisabled] = useState(checkInitialDisabled());

	const changeHandler = (event) => {
		if (props.onChange) {
			props.onChange(event);
		}
		if (props.isMulti) {
			const values = event ? event.map(option => option.value) : [];
			dispatch({
				type: 'change',
				val: values,
				validators: props.validators,
			});
			setSelectedOption(values);
			props.onInput(id, values, isValid);
		} else {
			dispatch({
				type: 'change',
				val: event ? event.value : '',
				validators: props.validators,
			});
			setSelectedOption(event ? event.value : '');
		}
	};

	const toggleDisabled = () => {
		setIsDisabled(prevState => !prevState);
		if (!isDisabled) {
			props.onInput("group", "all", true);
		} else {
			const isValid = true;
			if(JSON.stringify(selectedOption) === JSON.stringify(["all"])){
				props.onInput("group", [], isValid);
			}else{
				props.onInput("group", selectedOption, isValid);
			}
		}
	};

	const touchHandler = () => {
		dispatch({
			type: 'touch',
		});
	};

	let valueSelect;
	if (props.isMulti) {
		valueSelect = selectedOption && selectedOption.length > 0 ? selectedOption.map(optionValue => {
			return props.options.find(option => option.value === optionValue);
		}) : [];
	} else {
		valueSelect = props.options.find(option => option.value === selectedOption);
	}

	return (
		<div className={`form-control ${props.className || ''}`}>
			<div className='select-label'>
				<label htmlFor={props.id}>{props.label}</label>
				{props.selectDisabler &&
					<InputSolo
						type="checkbox"
						text={props.selectDisablerTitle}
						className={`mb-10`}
						value={'all'}
						id={props.id}
						onInput={props.onInput}
						checked={isDisabled}
						onChange={toggleDisabled}
					/>
				}
			</div>
			<Select
				components={{ DropdownIndicator, ClearIndicator }}
				id={props.id}
				className={`select ${props.isMulti ? ' basic-multi-select' : ''}${props.disableInputText ? ' disable-input-text' : ''} ${props.className || ''}`}
				classNamePrefix='select-content'
				value={valueSelect}
				onChange={changeHandler}
				onBlur={touchHandler}
				options={props.options}
				isDisabled={isDisabled || props.isDisabled}
				isMulti={props.isMulti}
			/>
		</div>
	);
};

export default SelectWrapper;
