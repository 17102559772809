import React from 'react';

import Icon from '../../shared/components/UIElements/Icon';

const NoteAssigned = (props) => {

    let text;
    let icon;
    let color;

    if(props.assigned_to_user){
        icon = 'fi-rr-at';
        text = props.assigned_to_user.first_name+' '+props.assigned_to_user.last_name;
        color = 'blue';
    }else if(props.assigned_to_group){
        icon = 'fi-rr-hastag';
        text = props.assigned_to_group;
    }else{
        icon = 'fi-rr-hastag';
        text = 'everyone';
    }

    return <span className={`status ${color ? ' ' + color : ''}${!text ? ' only-icon' : ''}${props.className ? ' ' + props.className : ''}`}><Icon flaticon={icon} />{text && <span>{text}</span>}{props.children}</span>;
};

export default NoteAssigned;
