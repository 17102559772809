import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../../shared/components/UIElements/ActionButton';

import formatMoney from '../../shared/utils/money';
import Status from '../../shared/components/UIElements/Status';
import { serviceTypes } from '../data/ServiceOptions';

const ServiceItem = (props) => {
	const typeTable = serviceTypes;
	const type = typeTable
		.filter((type) => type.value === props.type)
		.map((type) => type);

	return (
		<React.Fragment>
			<Link to={`/services/edit/${props.id}`} key={props.id} className='item no-link'>
				<div>
					<span>{props.name}</span>
				</div>
				<div>
					{type[0].value === 'recurring' &&
						<Status icon='fi-rr-arrows-repeat' status={type[0]} />
					}
					{type[0].value === 'setup' &&
						<Status icon='fi-rr-layers' status={type[0]} />
					}
				</div>
				<div>
					<span>{formatMoney(props.price)}€</span>
				</div>
				<div className='actions'>
					<ActionButton to={`/services/edit/${props.id}`} flaticon='fi-br-pencil' className='inline-grid table-action-icon' />
				</div>
			</Link>
		</React.Fragment>
	);
};

export default ServiceItem;
