import React, { useContext } from 'react';
import {
	VALIDATOR_EMAIL,
	// VALIDATOR_MINLENGTH,
} from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import LoginBox from '../../shared/components/Wrappers/LoginBox';
import Input from '../../shared/components/UIElements/Input';
import Button from '../../shared/components/UIElements/Button';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/ErrorMessage';
import MainTitle from '../../shared/components/Titles/MainTitle';
import MainSubtitle from '../../shared/components/Titles/MainSubtitle';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import logo from '../../logo.svg';

import { useTranslation } from "react-i18next";

import './Login.css';

const Login = () => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();

	const [formState, inputHandler] = useForm(
		{
			email: {
				value: '',
				isValid: false,
			},
			password: {
				value: '',
				isValid: false,
			},
		},
		false
	);

	const loginSubmitHandler = async (event) => {
		event.preventDefault();

		try {
			const response = await sendRequest(
				process.env.REACT_APP_API_URL + '/users/login',
				'POST',
				JSON.stringify({
					email: formState.inputs.email.value,
					password: formState.inputs.password.value,
				}),
				{
					'Content-Type': 'application/json',
				}
			);
			auth.login(response.userId, response.token, response.role, response.email, response.first_name, response.last_name);
		} catch (error) {}
	};

	return (
		<LoginBox>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{/* <MainTitle>{t('user.login')}</MainTitle>
			<MainSubtitle>{t('user.login-st')}</MainSubtitle> */}
			<div className='logo-box'><img src={logo} alt='Midrocket'></img></div>
			<form onSubmit={loginSubmitHandler} className='login-form'>
				<Input
					id='email'
					element='input'
					type='email'
					placeholder={t('user.email')}
					onInput={inputHandler}
					validators={[VALIDATOR_EMAIL()]} // validators={[VALIDATOR_EMAIL()]}
					errorText={t('user.email-err')}
					initialValid={false}
				/>
				<Input
					id='password'
					element='input'
					type='password'
					placeholder={t('user.password')}
					onInput={inputHandler}
					validators={[]}
					errorText={t('user.password-err-valid')}
					initialValid={false}
				/>
				<ActionButton type='submit' className='wide filled-blue' text={t('user.login-btn')} disabled={!formState.isValid} />
				{/* <Button type='submit' className='wide' disabled={!formState.isValid}>
				{t('user.login-btn')}
				</Button> */}
			</form>
		</LoginBox>
	);
};

export default Login;
