import React from "react";

const ActionsWrapper = (props) => {
    return (
        <div className="actions-wrapper">
            {props.children}
        </div>
    );
};

export default ActionsWrapper;
