import React from "react";

import './ErrorMessage.css';

const ErrorMessage = props => {
    return(
        <div className="notice error-message">
            {props.message}
        </div>
    );
}

export default ErrorMessage;