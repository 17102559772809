import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';
import { useHistory } from 'react-router-dom';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentBox from '../../shared/components/Wrappers/ContentBox';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import PageHeader from '../../shared/components/Titles/PageHeader';
import ServerBox from '../components/ServerBox';
import Tabs from '../../shared/components/Results/Tabs';

import './Project.css';
import ProjectSubscriptions from '../tabs/ProjectSubscriptions';
import Status from '../../shared/components/UIElements/Status';
import NewNote from '../../notes/components/NewNote';
import { projectStatuses, projectTypes } from '../data/ProjectOptions';
import Modal from '../../shared/components/UIElements/Modal';
import DealModal from '../../deals/components/DealModal';
import DashboardWrapper from '../../shared/components/Dashboards/DashboardWrapper';
import DashboardColumn from '../../shared/components/Dashboards/DashboardColumn';
import DashboardBox from '../../shared/components/Dashboards/DashboardBox';
import DataTable from '../../shared/components/Dashboards/DataTable';
import ProjectDeals from '../tabs/ProjectDeals';
import ActionButton from '../../shared/components/UIElements/ActionButton';

import { useTranslation } from "react-i18next";
import formatDate from '../../shared/utils/dates';

const Project = () => {
	const { t } = useTranslation('common');
	const projectId = useParams().pid;
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [project, setLoadedProject] = useState();
	const auth = useContext(AuthContex);
	const history = useHistory();
	const [tab, setTab] = useState('default');
	const [showNoteModal, setShowNoteModal] = useState(false);
	const [showDealModal, setShowDealModal] = useState(false);

	const toggleNoteModal = () => {
		if (showNoteModal) {
			setShowNoteModal(false);
		} else {
			setShowNoteModal(true);
		}
	};

	const toggleDealModal = () => {
		if (showDealModal) {
			setShowDealModal(false);
		} else {
			setShowDealModal(true);
		}
	};

	useEffect(() => {
		const fetchProjects = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/projects/${projectId}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setLoadedProject(response.project);
			} catch (error) {
				console.log(error);
			}
		};
		fetchProjects();
	}, [sendRequest, auth, projectId]);

	if (isLoading) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	const actions = [];

	if (project) {
		const statusTable = projectStatuses;
		const status = statusTable
			.filter((status) => status.value === project.status)
			.map((status) => status);

		actions.push({
			component: <Status icon='fi-rr-globe' status={status[0]} className='big' />
		}
		);
	}

	actions.push(
		{
			flaticon: 'fi-br-notebook',
			tooltip: t('note.add'),
			onClick: () => toggleNoteModal()
		},
		{
			flaticon: 'fi-br-arrows-repeat',
			tooltip: t('subscription.add'),
			to: '/subscriptions/new/' + projectId,
		},
		{
			flaticon: 'fi-br-handshake',
			tooltip: t('deal.add'),
			onClick: () => toggleDealModal()
		},
		{
			flaticon: 'fi-br-pencil',
			tooltip: t('project.edit'),
			to: '/projects/edit/' + projectId,
		},
	);

	const tabs = [
		{
			id: 'default',
			title: t('common.general'),
			to: '/projects/' + projectId,
			onClick: () => setTab('default'),
		},
		{
			id: 'subscriptions',
			title: t('models.subscriptions'),
			onClick: () => setTab('subscriptions'),
		},
		{
			id: 'deals',
			title: t('models.deals'),
			onClick: () => setTab('deals'),
		},
		// {
		// 	id: 'integrations',
		// 	title: 'Integrations',
		// 	onClick: () => setTab('integrations'),
		// 	status: 'disabled'
		// },
	];

	let subdata;
	if (project) {
		const types = projectTypes;

		const type = types
			.filter((type) => type.value === project.type)
			.map((type) => type.label);

		subdata = [
			{
				title: project.company?.company_name || '',
				flaticon: 'fi-rr-briefcase',
				tooltip: t('project.company'),
				to: '/companies/' + project.company?.id
			},
			{
				title: project.domain.url ? project.domain.url.replace(/^https?:\/\//, '') : '',
				flaticon: 'fi-rr-globe',
				tooltip: t('project.domain')
			},
			{
				title: type,
				flaticon: 'fi-rr-square-code',
				tooltip: t('project.type')

			},
			{
				title: (project.created_by?.first_name || '') + ' ' + (project.created_by?.last_name || ''),
				flaticon: 'fi-rr-user',
				tooltip: t('common.createdby')
			}
		];
	}

	const renderTab = () => {
		switch (tab) {
			case 'subscriptions':
				return (
					<ProjectSubscriptions project={project} hideColumnsIndex={[0]} />
				)
			case 'deals':
				return (
					<ProjectDeals project={project} hideColumnsIndex={[0]} />
				)
			default:
				return (
					<>
						<DashboardWrapper>
							<DashboardColumn className='flex-small'>
								<DashboardBox
									title='Project information'
								>
									<DataTable>
										<div><span>{t('project.company')}</span><span>{project.company.name}</span></div>
										<div><span>{t('project.name')}</span><span>{project.name}</span></div>
										<div><span>{t('project.type')}</span><span>{project.type}</span></div>
										<div><span>{t('project.description')}</span><span>{project.description}</span></div>
										<div><span>{t('project.status')}</span><span>{project.name}</span></div>
										<div><span>{t('project.domain')}</span><span>{project.domain.url}</span></div>
										<div><span>{t('common.createdby')}</span><span>{project.created_by?.first_name} {project.created_by?.last_name}</span></div>
										<div><span>{t('common.lastupdated')}</span><span>{formatDate(project.updated_at, 'DD/MM/YYYY')}</span></div>
										<div><span>{t('common.created')}</span><span>{formatDate(project.created_at, 'DD/MM/YYYY')}</span></div>
									</DataTable>
								</DashboardBox>
							</DashboardColumn>
							<DashboardColumn>
								<DashboardBox
									title={t('models.subscriptions')}
									action={<ActionButton text={t('subscription.add')} to={`/subscriptions/new/${projectId}`} />}
									className='dashboard-box-table'
								>
									<ProjectSubscriptions project={project} limit={5} hideColumnsIndex={[0]} viewAll={() => setTab('subscriptions')} /> {/* status={'active'} */}
								</DashboardBox>
								<DashboardBox
									title={t('models.deals')}
									action={<ActionButton text={t('deal.add')} onClick={() => toggleDealModal()} />}
									className='dashboard-box-table'
								>
									<ProjectDeals project={project} limit={5} hideColumnsIndex={[0, 4, 6]} viewAll={() => setTab('deals')} />
								</DashboardBox>
							</DashboardColumn>
						</DashboardWrapper>
						{/* <ServerBox styleType='small' project={project.id} /> */}
					</>
				)
		}
	}

	return (
		<ContentWrapper>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{!isLoading && project && (
				<React.Fragment>
					<ContentHead>
						<PageHeader
							gobackOnClick={() => history.goBack()}
							title={project.name}
							subdata={subdata}
							actions={actions}
						/>
						<Tabs tabs={tabs} current={tab} />
					</ContentHead>
					<React.Fragment key={project.id}>
						{renderTab()}
					</React.Fragment>
					<NewNote show={showNoteModal} object={'project'} id={project.id} label={project.name} toggleNote={toggleNoteModal} />
				</React.Fragment>
			)}
			{project &&
				<Modal
					show={showDealModal}
					className='deal-modal'
					title={t('deal.add')}
					closeAction={toggleDealModal}
					footer={false}
				>
					<DealModal closeAction={toggleDealModal} project={project} />
				</Modal>
			}
		</ContentWrapper>
	);
};

export default Project;
