import React from 'react';
import ServiceItem from './ServiceItem';

const ServiceList = (props) => {
	return (
		<React.Fragment>
				{props.services.map((service) => {
					return (
						<ServiceItem
							key={service.id}
							id={service.id}
							name={service.name}
							type={service.type}
							price={service.price}
						/>
					);
				})}
		</React.Fragment>
	);
};

export default ServiceList;
