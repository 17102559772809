import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Switch,
} from 'react-router-dom';

import './App.css';

// Shared
import Header from './shared/components/Header/Header';
import MainWrapper from './shared/components/Wrappers/MainWrapper';
import { AuthContex } from './shared/context/auth-context';
import { UserAuth } from './shared/hooks/auth-hook';

// Pages
import Users from './users/pages/Users';
import Dashboard from './dashboard/pages/Dashboard';
import MyAccount from './account/pages/MyAccount';
import Companies from './companies/pages/Companies';
import NewCompany from './companies/pages/NewCompany';
import NewUser from './users/pages/NewUser';
import Company from './companies/pages/Company';
import EditCompany from './companies/pages/EditCompany';
import EditUser from './users/pages/EditUser';
import Login from './users/pages/Login';
import Sidebar from './shared/components/Sidebar/Sidebar';
import Projects from './projects/pages/Projects';
import Project from './projects/pages/Project';
import NewProject from './projects/pages/NewProject';
import EditProject from './projects/pages/EditProject';
import Services from './services/pages/Services';
import NewService from './services/pages/NewService';
import EditService from './services/pages/EditService';
import Settings from './settings/pages/Settings';
import MainSettings from './settings/pages/MainSettings';
import ServerSettings from './settings/pages/ServersSettings';
import ScrollToTop from './shared/components/Utils/ScrollToTop';
import Subscriptions from './subscriptions/pages/Subscriptions';
import NewSubscription from './subscriptions/pages/NewSubscription';
import EditSubscription from './subscriptions/pages/EditSubscription';
import Subscription from './subscriptions/pages/Subscription';
import Notes from './notes/pages/Notes';
import Deals from './deals/pages/Deals';
import Payouts from './payouts/pages/Payouts';
import NewPayout from './payouts/pages/NewPayout';
import Payout from './payouts/pages/Payout';

// Vars
const locale = 'es';

// Below function and class work the same
const App = () => {

	const { token, login, logout, userId, role, email, first_name, last_name } = UserAuth();
	let routes;

	if (token) {
		routes = (
			<React.Fragment>
				<ScrollToTop />
				<Switch>
					<Route path='/' exact>
						<Dashboard />
					</Route>
					{role !== 'user' && (
						<Route path='/tasks' exact>
							<Notes />
						</Route>
					)}
					<Route path='/companies' exact>
						<Companies />
					</Route>
					{role !== 'user' && (
						<Route path='/companies/new' exact>
							<NewCompany />
						</Route>
					)}
					{role !== 'user' && (
						<Route path='/companies/edit/:cid' exact>
							<EditCompany />
						</Route>
					)}
					<Route path='/companies/:cid' exact>
						<Company />
					</Route>
					<Route path='/projects' exact>
						<Projects />
					</Route>
					{role !== 'user' && (
						<Route path='/projects/new' exact>
							<NewProject />
						</Route>
					)}
					{role !== 'user' && (
						<Route path='/projects/edit/:pid' exact>
							<EditProject />
						</Route>
					)}
					{role !== 'user' && (
						<Route path='/projects/:pid' exact>
							<Project />
						</Route>
					)}
					{role !== 'user' && (<Route path='/deals' exact>
						<Deals />
					</Route>
					)}
					{role !== 'user' && (<Route path='/payouts' exact>
						<Payouts />
					</Route>
					)}
					{role !== 'user' && (<Route path='/payouts/new' exact>
						<NewPayout />
					</Route>
					)}
					{role !== 'user' && (
						<Route path='/payouts/:pid' exact>
							<Payout />
						</Route>
					)}
					{role !== 'user' && (<Route path='/subscriptions' exact>
						<Subscriptions />
					</Route>
					)}
					{role !== 'user' && (
						<Route path='/subscriptions/new' exact>
							<NewSubscription />
						</Route>
					)}
					{role !== 'user' && (
						<Route path='/subscriptions/new/:pid' exact>
							<NewSubscription />
						</Route>
					)}
					{role !== 'user' && (
						<Route path='/subscriptions/edit/:sid' exact>
							<EditSubscription />
						</Route>
					)}
					{role !== 'user' && (
						<Route path='/subscriptions/:sid' exact>
							<Subscription />
						</Route>
					)}
					<Route path='/services' exact>
						<Services />
					</Route>
					{role !== 'user' && (
						<Route path='/services/new' exact>
							<NewService />
						</Route>
					)}
					{role !== 'user' && (
						<Route path='/services/edit/:sid' exact>
							<EditService />
						</Route>
					)}
					{role !== 'user' && (
						<Route path='/users' exact>
							<Users />
						</Route>
					)}
					{role !== 'user' && (
						<Route path='/users/new' exact>
							<NewUser />
						</Route>
					)}
					{role !== 'user' && (
						<Route path='/users/edit/:uid' exact>
							<EditUser />
						</Route>
					)}
					{role === 'super' && (
						<Route path='/settings' exact>
							<Settings />
						</Route>
					)}
					{role === 'super' && (
						<Route path='/settings/main' exact>
							<MainSettings />
						</Route>
					)}
					{role === 'super' && (
						<Route path='/settings/servers' exact>
							<ServerSettings />
						</Route>
					)}
					<Route path='/account' exact>
						<MyAccount />
					</Route>
					<Route render={() => <Redirect to='/' />} />
				</Switch>
			</React.Fragment>
		);
	} else {
		routes = (
			<React.Fragment>
				<Switch>
					<Route path='/login' exact>
						<Login />
					</Route>
					<Route render={() => <Redirect to='/login' />} />
				</Switch>
			</React.Fragment>
		);
	}

	return (
		<AuthContex.Provider
			value={{
				isLoggedIn: !!token,
				userId: userId,
				token: token,
				role: role,
				email: email,
				first_name: first_name,
				last_name: last_name,
				login: login,
				logout: logout,
			}}
		>
			<Router>
				{token && <Header />}
				<MainWrapper>
					{token && <Sidebar />}
					{routes}
				</MainWrapper>
			</Router>
		</AuthContex.Provider>
	);
};

export default App;
