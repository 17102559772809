import React from 'react';

import './NoteObject.css';
import Icon from '../../shared/components/UIElements/Icon';
import { Link } from 'react-router-dom';

import { useTranslation } from "react-i18next";

const NoteObject = (props) => {

    const { t } = useTranslation('common');

    let text;
    let icon;
    let url;

    if(props.object.company){
        icon = 'fi-rr-briefcase';
        text = props.object.company.name;
        url = '/companies/'+props.object.company._id;
    }

    if(props.object.project){
        icon = 'fi-rr-globe';
        text = props.object.project.name;
        url = '/projects/'+props.object.project._id;
    }

    if(props.object.service){
        icon = 'fi-rr-cube';
        text = props.object.service.name;
        url = '/services/'+props.object.service._id;
    }

    if(props.object.subscription){
        icon = 'fi-rr-arrows-repeat';
        text = props.object.subscription.project.name;
        url = '/subscriptions/'+props.object.subscription._id;
    }

    if(props.object.payment){
        icon = 'fi-rr-credit-card';
        text = t('models.payment');
        url = '/subscriptions/'+props.object.payment.subscription;
    }

    if(props.object.user){
        icon = 'fi-rr-users';
        text = props.object.user.fist_name+' '+props.object.user.last_name;
        url = '/users/'+props.object.user._id;
    }

    return <Link className='no-link' to={url}><span className={`status ${!text && 'only-icon'}${props.className ? ' ' + props.className : ''}`}><Icon flaticon={icon} />{text && <span>{text}</span>}{props.children}</span></Link>;
};

export default NoteObject;
