import {
	useState,
	useCallback,
	// useRef,
	// useEffect
} from 'react';

export const useHttpClient = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState();

	// Commented the abort http as it throwed an error
	//   const activeHttpRequests = useRef([]);

	const sendRequest = useCallback(
		async (url, method = 'GET', body = null, headers = {}, effects = true) => {
			if(effects){
				setIsLoading(true);
			}
			//   const httpAbortCtrl = new AbortController();
			//   activeHttpRequests.current.push(httpAbortCtrl);

			try {
				const response = await fetch(url, {
					method,
					body,
					headers,
					//   signal: httpAbortCtrl.signal
				});

				const responseData = await response.json();

				// activeHttpRequests.current = activeHttpRequests.current.filter(
				//   reqCtrl => reqCtrl !== httpAbortCtrl
				// );

				if (!response.ok) {
					throw new Error(responseData.message);
				}

				setIsLoading(false);
				return responseData;
			} catch (error) {
				setErrorMsg(error.message);
				setIsLoading(false);
				throw error;
			}
		},
		[]
	);

	const clearError = () => {
		setErrorMsg(null);
	};

	//   useEffect(() => {
	//     return () => {
	//       // eslint-disable-next-line react-hooks/exhaustive-deps
	//       activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
	//     };
	//   }, []);

	return { isLoading, errorMsg, sendRequest, clearError };
};
