import React from 'react';
import ReactDom from 'react-dom';

import './Overlay.css';

const Overlay = props => {

    const content = <div className="overlay" onClick={props.onClick}></div>;
    return ReactDom.createPortal(content, document.getElementById('overlay'));

}

export default Overlay;