import React from 'react';
import DealItem from './DealItem';

const DealList = (props) => {

	const subtotalDeals = props.selectedDeals ? props.deals.filter(deal => props.selectedDeals.includes(deal.id)) : props.deals;

	const subtotals = subtotalDeals.reduce((accumulator, current) => {
		accumulator.price += current.price;
		accumulator.payments += current.payments.filter(payment => payment.paid).reduce((total, payment) => total + (payment.amount ?? 0), 0);
		accumulator.expenses += current.expenses.reduce((total, expense) => total + expense.amount,0);
		return accumulator;
	}, { id: 'subtotal', price: 0, payments: 0, expenses: 0 });
	subtotals.pending = subtotals.price - subtotals.payments;

	return (
		<React.Fragment>
			{props.deals.map((deal) => {
				return (
					<DealItem
						key={deal.id}
						id={deal.id}
						deal={deal}
						header={props.header}
						deleteDeal={props.deleteDeal}
						isFormSelector={props.isFormSelector}
						setSelectedDeals={props.setSelectedDeals}
					/>
				);
			})}
			{props.subtotals &&
				<DealItem
					key={props.subtotals.id}
					id={props.subtotals.id}
					subtotals={subtotals}
					header={props.header}
					isFormSelector={props.isFormSelector}
					setSelectedDeals={props.setSelectedDeals}
				/>
			}
		</React.Fragment>
	);
};

export default DealList;
