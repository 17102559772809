import React from 'react';
import UserItem from './UserItem';

const UserList = (props) => {
	return (
		<React.Fragment>
				{props.users.map((users) => {
					return (
						<UserItem
							key={users.id}
							id={users.id}
							first_name={users.first_name}
                            last_name={users.last_name}
                            email={users.email}
							role={users.role}
							header={props.header}
						/>
					);
				})}
		</React.Fragment>
	);
};

export default UserList;
