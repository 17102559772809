import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import Status from '../../shared/components/UIElements/Status';
import { dealStatuses } from '../data/DealOptions';
import Tooltip from '../../shared/components/UIElements/Tooltip';
import formatMoney from '../../shared/utils/money';
import Icon from '../../shared/components/UIElements/Icon';
import DealModal from './DealModal';
import Modal from '../../shared/components/UIElements/Modal';
import InputSolo from '../../shared/components/UIElements/InputSolo';

import { useTranslation } from "react-i18next";

const DealItem = (props) => {
	const { t, i18n } = useTranslation('common');
	const [deal, setDeal] = useState(props.deal ?? {});
	const [showDealModal, setShowDealModal] = useState(false);
	const [dealChecked, setDealChecked] = useState(false);

	// const pendingAmount = deal.payments.filter(payment => payment.paid).reduce((total, payment) => total + (payment.amount ?? 0), 0);
	// const dealStatus = (deal.price === pendingAmount) ? 'paid' : 'pending';

	const toggleEditDealModal = (event) => {

		if (props.isFormSelector) {
			if (dealChecked) {
				setDealChecked(false);
				// props.setSelectedDeals(deal.id)
				props.setSelectedDeals((prevSelectedDeals) => prevSelectedDeals.filter((id) => id !== deal.id));

			} else {
				// props.setSelectedDeals(deal.id)
				props.setSelectedDeals((prevSelectedDeals) => [...prevSelectedDeals, deal.id]);
				setDealChecked(true);
			}
		} else {
			if (showDealModal) {
				setShowDealModal(false);
			} else {
				setShowDealModal(true);
			}
		}
	}

	const handleDelete = (event) => {
		event.stopPropagation();
		event.preventDefault();
		props.deleteDeal(props.deal);
	}

	const statusTable = dealStatuses;
	const status = statusTable
		.filter((status) => status.value === deal.status)
		.map((status) => status);

	if (props.subtotals) {
		return (
			<React.Fragment>
				<Link onClick={(event) => toggleEditDealModal(event)} key={props.subtotals.id} to='#' className='item no-link subtotals'>
					<div className={props.header[0] && props.header[0]?.style}>
						<span>&nbsp;</span>
					</div>
					<div className={props.header[1] && props.header[1]?.style}>
						<span>&nbsp;</span>
					</div>
					<div className={props.header[2] && props.header[2]?.style}>
						<span>&nbsp;</span>
					</div>
					<div className={props.header[3] && props.header[3]?.style}>
						<span>{formatMoney(props.subtotals.price)}€</span>
					</div>
					<div className={props.header[4] && props.header[4]?.style}>
						<span>{formatMoney(props.subtotals.payments)}€</span>
					</div>
					<div className={props.header[5] && props.header[5]?.style}>
						<span>{formatMoney(props.subtotals.pending)}€</span>
					</div>
					<div className={props.header[6] && props.header[6]?.style}>
						<span>{formatMoney(props.subtotals.expenses)}€</span>
					</div>
					<div className={`actions ${props.header[7] && props.header[7]?.style}`}>
					</div>
					{props.isFormSelector &&
						<div className={props.header[8] && props.header[8]?.style}>
							<span>&nbsp;</span>
						</div>
					}
				</Link >
			</React.Fragment >
		);
	}

	return (
		<React.Fragment>
			<Link onClick={(event) => toggleEditDealModal(event)} key={deal.id} to='#' className='item no-link'>
				<div className={props.header[0] && props.header[0]?.style}>
					<span>{deal.project.name}</span>
					{deal.payout &&
						<>
							<Status color='grey' className='ml-10 tooltip' icon='fi-sr-money'>
								<Tooltip>Already paid out</Tooltip>
							</Status>

						</>
					}
				</div>
				<div className={props.header[1] && props.header[1]?.style}>
					<span>{deal.name}</span>
				</div>
				<div className={props.header[2] && props.header[2]?.style}>
					<Status status={status[0]} icon={status[2]} color={deal.payments.filter(payment => payment.paid).reduce((total, payment) => total + (payment.amount ?? 0), 0) === 0 ? 'red': ''}/>
				</div>
				<div className={props.header[3] && props.header[3]?.style}>
					<span>{formatMoney(deal.price)}€</span>
				</div>
				<div className={props.header[4] && props.header[4]?.style}>
					<span className='tooltip'>
						<span>{formatMoney(deal.payments.filter(payment => payment.paid).reduce((total, payment) => total + (payment.amount ?? 0), 0))}€</span>
						<Tooltip className='listed'>
							<ul>
								{deal.payments?.map(payment => (
									<li key={payment.description}>
										{payment.paid ? <Icon flaticon='fi-br-check' color='status-green' /> : <Icon flaticon='fi-br-cross' color='status-red' />}
										<span className='color-darkestgrey'>{payment.description}</span>
										<span>{formatMoney(payment.amount ?? 0)}€</span>
									</li>
								))}
								{deal.payments.length === 0 && t('deal.payments-not-found')}
							</ul>
						</Tooltip>
					</span>
				</div>
				<div className={props.header[5] && props.header[5]?.style}>
					<span>{formatMoney(deal.price - deal.payments.filter(payment => payment.paid).reduce((total, payment) => total + (payment.amount ?? 0), 0))}€</span>
				</div>
				<div className={props.header[6] && props.header[6]?.style}>
					<span className='tooltip'>
						<span>{formatMoney(deal.expenses.reduce((total, expense) => total + (expense.amount ?? 0), 0))}€</span>
						<Tooltip className='listed'>
							<ul>
								{deal.expenses?.map(expense => (
									<li key={expense.description}>
										{expense.paid ? <Icon flaticon='fi-br-check' color='status-green' /> : <Icon flaticon='fi-br-cross' color='status-red' />}
										<span className='color-darkestgrey'>{expense.description}</span>
										<span>{formatMoney(expense.amount ?? 0)}€</span>
									</li>
								))}
								{deal.expenses.length === 0 && t('deal.expenses-not-found')}
							</ul>
						</Tooltip>
					</span>
				</div>
				<div className={`actions ${props.header[7] && props.header[7]?.style}`}>
					<ActionButton onClick={() => toggleEditDealModal()} flaticon={`${props.deal.payout ? 'fi-br-menu-dots' : 'fi-br-pencil'}`} className='inline-grid table-action-icon' />
					{!props.deal.payout &&
						<ActionButton onClick={(e) => handleDelete(e)} flaticon='fi-br-trash' className='inline-grid table-action-icon tooltip'><Tooltip>{t('deal.remove')}</Tooltip></ActionButton>
					}
				</div>
				{props.isFormSelector &&
					<div className={props.header[8] && props.header[8]?.style}>
						<span>
							<InputSolo type='checkbox' name='deal_id' value={deal.id} checked={dealChecked} onChange={(e) => (e)} />
						</span>
					</div>
				}
			</Link >
			{deal &&
				<Modal
					show={showDealModal}
					className={`deal-modal ${deal.payout && 'disable-edit'}`}
					title={<span className='modal-head-flex'><span>{t('deal.edit')}</span><Icon flaticon='fi-br-cross' className='close-modal' onClick={() => toggleEditDealModal()} /></span>}
					closeAction={toggleEditDealModal}
					footer={false}
				>
					<DealModal closeAction={toggleEditDealModal} deal={deal} updateParent={setDeal} />
				</Modal>
			}
		</React.Fragment >
	);
};

export default DealItem;
