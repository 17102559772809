import React from "react";

import './FormGroup.css';

const FormGroup = (props) => {
    return (
        <div className={`form-group ${props.className || ''}${props.title ? ' with-title' : ''}${props.titleStyle ? ' '+props.titleStyle : ''}`}>
            {props.title &&
            <div className='form-group-title'>
                <h4>{props.title}</h4>
                {props.subtitle && <p className='form-subtitle'>{props.subtitle}</p>}
            </div>
            }
            <div className='form-group-content'>
                {props.children}
            </div>
        </div>
    );
};

export default FormGroup;
