import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';
import { useLocation } from 'react-router-dom';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import Notice from '../../shared/components/UIElements/Notice';
import PageHeader from '../../shared/components/Titles/PageHeader';
import Tabs from '../../shared/components/Results/Tabs';
import Filters from '../../shared/components/Results/Filters';
import ItemList from '../../shared/components/UIElements/ItemList';
import PaymentList from '../../payments/components/PaymentList';
import Status from '../../shared/components/UIElements/Status';
import Empty from '../../shared/components/Results/Empty';
import NewNote from '../../notes/components/NewNote';
import { subscriptionStatuses } from '../data/SubscriptionOptions';
import { paymentStatuses } from '../../payments/data/PaymentOptions';
import formatDate from '../../shared/utils/dates';
import { subscriptionRate } from '../../shared/utils/subscriptions';

import './Subscription.css';
import DashboardColumn from '../../shared/components/Dashboards/DashboardColumn';
import DashboardBox from '../../shared/components/Dashboards/DashboardBox';
import CompanyProjects from '../../companies/tabs/CompanyProjects';
import DataTable from '../../shared/components/Dashboards/DataTable';
import DashboardWrapper from '../../shared/components/Dashboards/DashboardWrapper';
import SubscriptionPayments from '../tabs/SubscriptionPayments';

import { useTranslation } from "react-i18next";

const Subscription = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const location = useLocation();
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [subscription, setLoadedSubscription] = useState();
	const [payments, setLoadedPayments] = useState();
	const subscriptionId = useParams().sid;
	const history = useHistory();
	const [noticeMsg, setNoticeMsg] = useState();
	const [noticeType, setnoticeType] = useState();
	const [showModal, setShowModal] = useState(false);
	const [tab, setTab] = useState('default');

	const toggleNote = () => {
		if (showModal) {
			setShowModal(false);
		} else {
			setShowModal(true);
		}
	};

	useEffect(() => {
		setNoticeMsg(location.noticeMsg);
		setnoticeType(location.noticeType);
	}, [location]);

	useEffect(() => {
		const fetchSubscription = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/subscriptions/${subscriptionId}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setLoadedSubscription(response.subscription);
			} catch (error) {
				console.log(error);
			}
		};
		fetchSubscription();
	}, [sendRequest, subscriptionId, auth]);

	if (isLoading) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	let actions = [];


	if (subscription) {
		const statusTable = subscriptionStatuses;
		const status = statusTable
			.filter((status) => status.value === subscription.status)
			.map((status) => status);

		actions.push({
			component: <Status icon='fi-rr-arrows-repeat' status={status[0]} className='big' />
		}
		);
	}

	actions.push(
		{
			flaticon: 'fi-br-notebook',
			tooltip: t('note.add'),
			onClick: () => toggleNote()
		},
	);

	if (subscription && subscription.status !== 'cancelled') {
		actions.push(
			{
				// title: 'Edit subscription',
				flaticon: 'fi-br-pencil',
				tooltip: t('subscription.edit'),
				to: '/subscriptions/edit/' + subscriptionId,
			}
		);
	}

	const tabs = [
		{
			id: 'default',
			title: t('common.general'),
			to: '/subscriptions/' + subscriptionId,
			onClick: () => setTab('default'),
		},
		{
			id: 'payments',
			title: t('models.payments'),
			onClick: () => setTab('payments'),
		},
	];

	let subdata;
	if (subscription) {
		subdata = [
			{
				title: subscription.project?.domain?.url ? subscription.project?.domain?.url.replace(/^https?:\/\//, '') : '',
				flaticon: 'fi-rr-globe',
				tooltip: t('project.domain')
			},
			{
				title: subscriptionRate(subscription),
				flaticon: 'fi-rr-arrows-repeat',
				tooltip: t('subscription.rate-period')

			},
			{
				title: formatDate(subscription.next_renewal, 'DD/MM/YYYY'),
				flaticon: 'fi-rr-calendar-clock',
				tooltip: t('subscription.next-payment')

			},
			{
				title: subscription.services.length === 1 ? subscription.services.length + ' service' : subscription.services.length + ' services',
				flaticon: 'fi-rr-cube',
				tooltip: subscription.services.map((service) => <span key={service.id} className='block pf-5'>{service.name}</span>)

			},
			{
				title: (subscription.created_by?.first_name || '') + ' ' + (subscription.created_by?.last_name || ''),
				flaticon: 'fi-rr-user',
				tooltip: t('common.createdby')
			}
		];
	}

	const renderTab = () => {
		switch (tab) {
			case 'payments':
				return (
					<SubscriptionPayments subscription={subscription} filters={true} />
				)
			default:
				return (
					<>
						<DashboardWrapper>
							<DashboardColumn className='flex-small'>
								<DashboardBox
									title={t('subscription.information')}
								>
									<DataTable>
										<div><span>{t('subscription.project')}</span><span>{subscription.project.name}</span></div>
										<div><span>{t('project.domain')}</span><span>{subscription.project?.domain.url.replace(/^https?:\/\//, '')}</span></div>
										<div><span>{t('subscription.rate-period')}</span><span>{subscriptionRate(subscription)}</span></div>
										<div><span>{t('subscription.start-date')}</span><span>{formatDate(subscription.start_date, 'DD/MM/YYYY')}</span></div>
										<div><span>{t('subscription.next-payment')}</span><span>{formatDate(subscription.next_renewal, 'DD/MM/YYYY')}</span></div>
										<div><span>{t('subscription.services')}</span><span><ul>{subscription.services.map((service) => <li key={service.id} className='block pf-5'>{service.name}</li>)}</ul></span></div>
										<div><span>{t('common.createdby')}</span><span>{subscription.created_by?.first_name} {subscription.created_by?.last_name}</span></div>
										<div><span>{t('common.lastupdated')}</span><span>{formatDate(subscription.updated_at, 'DD/MM/YYYY')}</span></div>
										<div><span>{t('common.created')}</span><span>{formatDate(subscription.created_at, 'DD/MM/YYYY')}</span></div>
									</DataTable>
								</DashboardBox>
							</DashboardColumn>
							<DashboardColumn>
								<DashboardBox
									title={t('models.payments')}
									className='dashboard-box-table'
								>
									<SubscriptionPayments subscription={subscription} limit={10} hideColumnsIndex={[]} viewAll={() => setTab('payments')} />
								</DashboardBox>
							</DashboardColumn>
						</DashboardWrapper>
						{/* <ServerBox styleType='small' project={project.id} /> */}
					</>
				)
		}
	}

	return (
		<ContentWrapper>
			{subscription && (
				<React.Fragment>
					<ContentHead>
						<PageHeader
							gobackOnClick={() => history.goBack()}
							title='Subscription'
							subdata={subdata}
							actions={actions}
						/>
						<Tabs tabs={tabs} current={tab} />
					</ContentHead>
					{isLoading && <LoadingSpinner />}
					{errorMsg && <Notice type='error' message={errorMsg} />}
					{noticeMsg && <Notice type={noticeType} message={noticeMsg} />}
					<React.Fragment key={subscription.id}>
						{renderTab()}
					</React.Fragment>
					<NewNote show={showModal} object={'subscription'} id={subscription.id} label='Subscription' toggleNote={toggleNote} />
				</React.Fragment>
			)}
		</ContentWrapper>
	);
};

export default Subscription;
