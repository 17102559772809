import React from 'react';

import Input from '../../shared/components/UIElements/Input';
import Button from '../../shared/components/UIElements/Button';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';

import { useTranslation } from "react-i18next";

const ServerList = (props) => {
	const { t } = useTranslation('common');
	return (
		<React.Fragment>
			{props.servers.map((server, item) => {
				let name = 'name.' + item;
				let ip = 'ip.' + item;
				const length = props.servers.length - 1;
				return (
					<div key={item} className='grid col-3 col-2-2-1 gap-20 mb-20 server align-start fc-nm'>
						{/* {props.formState.inputs && props.isRerendered && ( */}
						{!item.hide &&
							props.formState.inputs[name] &&
							props.formState.inputs[ip] && (
								<React.Fragment>
									<Input
										id={'name.' + item}
										element='input'
										type='text'
										className='mb-0'
										placeholder={t('setting.server-name-ph')}
										label={item === 0 && t('setting.server-name')}
										onInput={props.inputHandler}
										validators={[VALIDATOR_REQUIRE()]}
										errorText={t('forms.common.error-valid-text')}
										initialValid={props.formState.inputs[name].isValid}
										initialValue={props.formState.inputs[name].value}
									/>
									<Input
										id={'ip.' + item}
										element='input'
										type='text'
										className='mb-0'
										placeholder='Server IP'
										label={item === 0 && ('Server IP')}
										onInput={props.inputHandler}
										validators={[VALIDATOR_REQUIRE()]}
										errorText={t('forms.common.error-valid-text')}
										initialValid={props.formState.inputs[ip].isValid}
										initialValue={props.formState.inputs[ip].value}
									/>
									{item === length && (
										<div>
											<Button
												onClick={props.removeRow(item)}
												className='button-small grey mb-0'
												data-item={item}
												type='button'
											>
												{t('setting.server-remove')}
											</Button>
										</div>
									)}
								</React.Fragment>
							)}
					</div>
				);
			})}
			{props.formState.isValid && (
				<div className=''>
					<Button onClick={props.addRow()} className='button-small grey'>
						{t('setting.server-add')}
					</Button>
				</div>
			)}
		</React.Fragment>
	);
};

export default ServerList;
