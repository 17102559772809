import React from 'react';

const MainSubtitle = (props) => {
	return (
		<div className='subtitle-wrapper'>
			<p>{props.children}</p>
		</div>
	);
};

export default MainSubtitle;
