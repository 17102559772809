import React, { useEffect, useState, useContext } from 'react';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';
import { useLocation } from 'react-router-dom';

import MainTitle from '../../shared/components/Titles/MainTitle';
import UserList from '../components/UserList';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import ActionsWrapper from '../../shared/components/Wrappers/ActionsWrapper';
import ItemList from '../../shared/components/UIElements/ItemList';
import Button from '../../shared/components/UIElements/Button';

import './Users.css';
import PageHeader from '../../shared/components/Titles/PageHeader';
import Filters from '../../shared/components/Results/Filters';

import { useTranslation } from "react-i18next";
import { userRoles } from '../data/UserOptions';

const Users = () => {
	const { t } = useTranslation('common');
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [userList, setUserList] = useState();
	const auth = useContext(AuthContex);
	const location = useLocation();
	const [noticeMsg, setNoticeMsg] = useState();
	const [noticeType, setnoticeType] = useState();

	// Pagination, Search & Filter
	const [totalResults, setTotalResults] = useState(0);
	const [perPageLimit, setPerPageLimit] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [query, setQuery] = useState({});
	const fetchResultsQuery = async (query) => {
		try {
			const response = await sendRequest(
				process.env.REACT_APP_API_URL + '/users/?' + query,
				'GET',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			setUserList(response.users);
			setTotalResults(response.count);
		} catch (error) {
			console.log(error);
		}
	};

	const roles = userRoles;

	const filters = {
		queryFn: fetchResultsQuery,
		setQueryFn: setQuery,
		query: query,
		totalRes: totalResults,
		limitRes: perPageLimit,
		currentPage: currentPage,
		setCurrentPage: setCurrentPage,
		filtersData: [
			{
				id: 'role',
				type: 'select',
				label: t('user.role'),
				options: roles
			},
		]
	};
	// End Pagination, Search & Filter

	useEffect(() => {
		setNoticeMsg(location.noticeMsg);
		setnoticeType(location.noticeType);
	}, [location]);

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await sendRequest(process.env.REACT_APP_API_URL + '/users/', 'GET', null, { Authorization: 'Bearer ' + auth.token });
				setUserList(response.users);
				setTotalResults(response.count);
				setPerPageLimit(response.per_page);
			} catch (error) {
				console.log(error);
			}
		};
		fetchUsers();
	}, [sendRequest]);

	//const itemsHeader = ['Name', 'Email', 'Phone', 'Role', 'Actions'];

	const itemsHeader = [
        {
            title: t('tables.name'),
            style: 'flex-medium'
        },
        {
            title: t('tables.email'),
			style: 'flex-big'
        },
        {
            title: t('tables.phone'),
            style: 'flex-medium'
        },
        {
            title: t('tables.role'),
            style: 'flex-small'
        },
        {
            title: t('tables.actions'),
			style: 'flex-tiny'
        }];

	const actions = [
		{
			title: t('user.add'),
			flaticon: 'fi-br-plus-small',
			style: 'filled-blue',
			to: '/users/new'
		}
	];

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					title={t('models.users')}
					// subtitle='List of all the client’s companies.'
					actions={actions}
				// subactions={subactions}
				/>
				{auth.role !== 'user' && <Filters pagination={true} filter={true} filters={filters} search={true} />}
			</ContentHead>
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{noticeMsg && <Notice type={noticeType} message={noticeMsg} />}
			{isLoading && <LoadingSpinner />}
			<ItemList
				header={itemsHeader}
				actions={true}
				columns={5}
				template='boxed'
				align='center'
			>
				{!isLoading && userList && <UserList users={userList} header={itemsHeader} />}
			</ItemList>
			{(totalResults > perPageLimit) && <Filters pagination={true} filters={filters} />}
		</ContentWrapper>
	);
};

export default Users;
