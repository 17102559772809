import Translate from "../../shared/components/Utils/Translate";

const payoutMonths = [
    { value: '1', label: <Translate t='common.january' /> },
    { value: '2', label: <Translate t='common.february' /> },
    { value: '3', label: <Translate t='common.march' /> },
    { value: '4', label: <Translate t='common.april' /> },
    { value: '5', label: <Translate t='common.may' /> },
    { value: '6', label: <Translate t='common.june' /> },
    { value: '7', label: <Translate t='common.july' /> },
    { value: '8', label: <Translate t='common.august' /> },
    { value: '9', label: <Translate t='common.september' /> },
    { value: '10', label: <Translate t='common.october' /> },
    { value: '11', label: <Translate t='common.november' /> },
    { value: '12', label: <Translate t='common.december' /> },
];

const payoutYears = [];
const currentYear = new Date().getFullYear();
for (let year = 2020; year <= currentYear; year++) {
    payoutYears.push({ value: year.toString(), label: year.toString() });
}

export { payoutYears, payoutMonths };