import React, { useState, useEffect, useContext } from 'react';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useLocation } from 'react-router-dom';

import PageHeader from './../../shared/components/Titles/PageHeader';
import CompanyList from '../components/CompanyList';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ItemList from '../../shared/components/UIElements/ItemList';

import './Companies.css';
import Filters from '../../shared/components/Results/Filters';
import { companyStatuses } from '../data/CompanyOptions';

import { useTranslation } from "react-i18next";

const Companies = (props) => {
	const { t } = useTranslation('common');
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [companyList, setCompanyList] = useState();
	const auth = useContext(AuthContex);
	const location = useLocation();
	const [noticeMsg, setNoticeMsg] = useState();
	const [noticeType, setnoticeType] = useState();

	// Pagination, Search & Filter
	const [totalResults, setTotalResults] = useState(0);
	const [perPageLimit, setPerPageLimit] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [query, setQuery] = useState({});
	const fetchResultsQuery = async (query) => {
		try {
			const response = await sendRequest(
				process.env.REACT_APP_API_URL + '/companies/?' + query,
				'GET',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			setCompanyList(response.companies);
			setTotalResults(response.count);
		} catch (error) {
			console.log(error);
		}
	};
	const filters = {
		queryFn: fetchResultsQuery,
		setQueryFn: setQuery,
		query: query,
		totalRes: totalResults,
		limitRes: perPageLimit,
		currentPage: currentPage,
		setCurrentPage: setCurrentPage,
		filtersStyle: 'buttons',
		filtersData: [
			{
				id: 'status',
				type: 'button',
				label: 'All companies',
				value: '',
			},
			{
				id: 'status',
				type: 'button',
				label: 'Archived',
				value: 'archived',
			},
		// filtersData: [
		// 	{
		// 		id: 'status',
		// 		type: 'select',
		// 		label: 'Status',
		// 		options: companyStatuses,
		// 	},
			// {
			// 	id: 'status_txt',
			// 	type: 'text',
			// 	label: 'Status Text',
			// 	options: '',
			// },
			// {
			// 	id: 'status_cb',
			// 	type: 'checkbox',
			// 	label: 'Status',
			// 	text: 'Active',
			// 	value: 'active'
			// }
		]
	};
	// End Pagination, Search & Filter

	useEffect(() => {
		setNoticeMsg(location.noticeMsg);
		setnoticeType(location.noticeType);
	}, [location]);

	useEffect(() => {
		const fetchCompanies = async () => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_API_URL + '/companies/', // ?limit=5
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setCompanyList(response.companies);
				setTotalResults(response.count);
				setPerPageLimit(response.per_page);
			} catch (error) {
				console.log(error);
			}
		};
		fetchCompanies();
	}, [auth.token, sendRequest]);

	// useEffect(() => {
	// 	fetchResultsQuery(query);
	// 	console.log(query);
	// }, [query]);

	const itemsHeader = [
		{
			title: t('common.name'),
			style: 'flex-big'
		},
		{
			title: t('company.name'),
			style: 'flex-big'
		},
		{
			title: t('company.vat'),
			style: 'flex-medium'
		},
		{
			title: t('common.date'),
			style: 'flex-medium'

		},
		{
			title: t('models.projects'),
			style: 'flex-small'
		},
		{
			title: t('common.actions')
		}];

	const actions = [
		{
			title: t('company.add'),
			flaticon: 'fi-br-plus-small',
			style: 'filled-blue',
			to: '/companies/new'
		}
	];

	// const subactions = [
	// 	{
	// 		title: 'Help',
	// 		flaticon: 'fi-br-square-info',
	// 		to: '#'
	// 	},
	// ];
	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					title={t('models.companies')}
					// subtitle='List of all the client’s companies.'
					actions={actions}
				// subactions={subactions}
				/>
				<Filters pagination={true} filter={true} filters={filters} search={true} />
			</ContentHead>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{noticeMsg && <Notice type={noticeType} message={noticeMsg} />}
			<ItemList
				header={itemsHeader}
				actions={true}
				columns={6}
				template='simple'
				align='center'
			>
				{!isLoading && companyList && <CompanyList companies={companyList} header={itemsHeader} />}
			</ItemList>
			{(totalResults > perPageLimit) && <Filters pagination={true} filters={filters} />}
		</ContentWrapper>
	);
};

export default Companies;