import React from 'react';
import CompanyItem from './CompanyItem';

const CompanyList = (props) => {
	return (
		<React.Fragment>
				{props.companies.map((company) => {
					return (
						<CompanyItem
							key={company.id}
							id={company.id}
							name={company.name}
							company_name={company.company_name}
							vat={company.vat}
							date={company.created_at}
							projects={company.projects}
							header={props.header}
							status={company.status}
						/>
					);
				})}
		</React.Fragment>
	);
};

export default CompanyList;
