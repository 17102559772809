import React, { useContext } from 'react';
import { AuthContex } from '../../context/auth-context';

const Main = props => {

    const auth = useContext(AuthContex);
    
    let mainClass;
    if(!auth.isLoggedIn){
        mainClass = 'login';
    }

    return (
        <main className={mainClass}>
            {props.children}
        </main>
    );
}

export default Main;