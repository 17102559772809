import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';
import { useHistory } from 'react-router-dom';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ItemList from '../../shared/components/UIElements/ItemList';
import Empty from '../../shared/components/Results/Empty';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import DealList from '../../deals/components/DealList';

import { useTranslation } from "react-i18next";

const DashboardDeals = (props) => {
    const { t, i18n } = useTranslation('common');
    const { isLoading, errorMsg, sendRequest } = useHttpClient();
    const auth = useContext(AuthContex);
    const [deals, setDeals] = useState();

    const query = {};
    query.limit = props.limit ?? '10';
    // query.status = props.status ?? 'active';
    // query.period = 'month';

    useEffect(() => {
        const fetchDeals = async () => {
            try {
                const response = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/deals/?${new URLSearchParams(query).toString()}`,
                    'GET',
                    null,
                    { Authorization: 'Bearer ' + auth.token }
                );
                setDeals(response.deals);
            } catch (error) {
                console.log(error);
            }
        };
        fetchDeals();
    }, [sendRequest, auth]);

    if (isLoading) {
        return <LoadingSpinner></LoadingSpinner>;
    }

    let itemsHeader = [
        {
            title: t('tables.project'),
        },
        {
            title: t('tables.name'),
        },
        {
            title: t('tables.status'),
            style: 'flex-regular'
        },
        {
            title: t('tables.amount'),
            style: 'flex-regular text-align-right'
        },
        {
            title: t('tables.payments'),
            style: 'flex-regular text-align-right'
        },
        {
            title: t('tables.pending'),
            style: 'flex-regular text-align-right'
        },
        {
            title: t('tables.expenses'),
            style: 'flex-regular text-align-right'
        },
        {
            title: t('tables.actions'),
            style: 'flex-small'
        }];

    if (props.hideColumnsIndex) {
        itemsHeader = itemsHeader.map((item, index) => {
            const style = item.style || '';
            const hiddenStyle = props.hideColumnsIndex.includes(index) ? 'hidden' : '';
            return { ...item, style: `${style} ${hiddenStyle}`.trim() };
        });
    }

    const emptyAction = {
        title: t('deal.add'),
        flaticon: 'fi-br-plus-small',
        style: '',
        to: '#'
    };

    return (
        <React.Fragment>
            {isLoading && <LoadingSpinner />}
            {errorMsg && <Notice type='error' message={errorMsg} />}
            {!isLoading && deals && deals.length > 0 && (
                <ItemList
                    header={itemsHeader}
                    actions={true}
                    columns={itemsHeader.length}
                    template='boxed'
                    align='center'
                >
                    {!isLoading && deals && <DealList deals={deals} header={itemsHeader} />}
                </ItemList>
            )}
            {deals && deals.length === props.limit &&
                <div className='text-align-right'>
                    <ActionButton className='m-0a mt-10' text={t('common.viewall')} to='/deals' />
                </div>
            }
            {deals && deals.length === 0 &&
                <Empty type='info' message={t('deals.not-found')} action={emptyAction} />
            }
        </React.Fragment>
    );
};

export default DashboardDeals;
