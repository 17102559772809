import React from 'react';

import ActionButton from '../UIElements/ActionButton';

import './FormFooter.css';

const FormFooter = (props) => {
	return (
		<React.Fragment>
			<div className='separator'></div>
			<div className='form-footer'>
				{props.actions &&
					props.actions.map((action, index) => (
						<ActionButton key={index} to={action.to} flaticon={action.flaticon} text={action.title} style={action.style} type={action.type} form={action.form} onClick={action.onClick} disabled={action.disabled} />
					))
				}
			</div>
		</React.Fragment>
	);
};

export default FormFooter;

