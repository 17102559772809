import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContex } from '../../context/auth-context';
import Icon from '../UIElements/Icon';
import { useTranslation } from "react-i18next";

const SidebarLinks = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);

	return (
		<ul className={props.className}>
			{auth.isLoggedIn && (
				<React.Fragment>
					<span className='nav-title'>{t('navigation.analytics-t')}</span>
					<li>
						<NavLink to='/' exact>
							<Icon flaticon='fi-br-house-blank' />
							{t('navigation.dashboard')}
						</NavLink>
					</li>
					<li>
						<NavLink to='/tasks' exact>
							<Icon flaticon='fi-br-checkbox' />
							{t('navigation.tasks')}
						</NavLink>
					</li>
					<span className='nav-title'>{t('navigation.management-t')}</span>
					<li>
						<NavLink to='/companies'>
							<Icon flaticon='fi-br-briefcase' />
							{t('navigation.companies')}
						</NavLink>
						{auth.role !== 'user' && (
							<ul className='submenu'>
								<li>
									<NavLink to='/companies' exact>
										{t('navigation.list')}
									</NavLink>
								</li>
								<li>
									<NavLink to='/companies/new'>
										{t('navigation.add-new')}
									</NavLink>
								</li>
							</ul>
						)}
					</li>
					<li>
						<NavLink to='/projects'>
							<Icon flaticon='fi-br-globe' />
							{t('navigation.projects')}
						</NavLink>

						{auth.role !== 'user' && (
							<ul className='submenu'>
								<li>
									<NavLink to='/projects' exact>
										{t('navigation.list')}
									</NavLink>
								</li>
								<li>
									<NavLink to='/projects/new'>
										{t('navigation.add-new')}
									</NavLink>
								</li>
							</ul>
						)}
					</li>
					{auth.role !== 'user' && (
						<li>
							<NavLink to='/deals'>
								<Icon flaticon='fi-br-handshake' />
								{t('navigation.deals')}
							</NavLink>
							<ul className='submenu'>
								<li>
									<NavLink to='/deals' exact>
										{t('navigation.list')}
									</NavLink>
								</li>
								<li>
									<NavLink to='/payouts' exact>{t('navigation.payouts')}</NavLink>
								</li>
							</ul>

						</li>
					)}
					{auth.role !== 'user' && (
						<li>
							<NavLink to='/subscriptions'>
								<Icon flaticon='fi-br-arrows-repeat' />
								{t('navigation.subscriptions')}
							</NavLink>
						</li>
					)}
					<span className='nav-title'>{t('navigation.settings-t')}</span>
					{auth.role !== 'user' && (
						<li>
							<NavLink to='/services'>
								<Icon flaticon='fi-br-cube' />
								{t('navigation.services')}
							</NavLink>

							<ul className='submenu'>
								<li>
									<NavLink to='/services' exact>
										{t('navigation.list')}
									</NavLink>
								</li>
								<li>
									<NavLink to='/services/new'>
										{t('navigation.add-new')}
									</NavLink>
								</li>
							</ul>
						</li>
					)}
					{auth.role !== 'user' && (
						<li>
							<NavLink to='/users'>
								<Icon flaticon='fi-br-users' />
								{t('navigation.users')}
							</NavLink>
						</li>
					)}
					{auth.role === 'super' && (
						<li>
							<NavLink to='/settings'>
								<Icon flaticon='fi-br-settings' />
								{t('navigation.settings')}
							</NavLink>
						</li>
					)}
					<span className='nav-title'>{t('navigation.account-t')}</span>
					<li>
						<NavLink to='/account'>
							<Icon flaticon='fi-br-portrait' />
							{t('navigation.account')}
						</NavLink>
					</li>
					<li>
						<NavLink to='/logout' onClick={auth.logout}>
							<Icon flaticon='fi-br-lock' />
							{t('navigation.logout')}
						</NavLink>
					</li>
				</React.Fragment>
			)}
		</ul>
	);
};

export default SidebarLinks;
