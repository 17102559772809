import React from 'react';

import Icon from './Icon';
import { Link } from 'react-router-dom';

import './SimpleLink.css';
import Tooltip from './Tooltip';

const SimpleLink = (props) => {

	if (props.to) {
		return (
			<Link
				to={props.to}
				className={`action-link no-link ${props.className || ''}${props.icon ? ' with-icon' : ''}${(!props.to && !props.onClick) ? ' no-cursor' : ''}${props.tooltip ? ' tooltip' : ''}`}
				onClick={props.onClick}
			>
				{props.tooltip &&
					<Tooltip>
						{props.tooltip}
					</Tooltip>}
				{props.icon && (<Icon icon={props.icon} className='link-icon' />)}
				{props.flaticon && (<Icon flaticon={props.flaticon} className='link-icon' />)}
				<div className='action-link-text'>{props.text}</div>
			</Link>
		);
	}

	return (
		<a
			href={props.to}
			className={`action-link no-link ${props.className || ''}${props.icon ? ' with-icon' : ''}${(!props.to && !props.onClick) ? ' no-cursor' : ''}${props.tooltip ? ' tooltip' : ''}`}
			onClick={props.onClick}
		>
			{props.tooltip &&
				<Tooltip>
					{props.tooltip}
				</Tooltip>}
			{props.icon && (<Icon icon={props.icon} className='link-icon' />)}
			{props.flaticon && (<Icon flaticon={props.flaticon} className='link-icon' />)}
			<div className='action-link-text'>{props.text}</div>
		</a>
	);
};

export default SimpleLink;
