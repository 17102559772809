import React from 'react';
import { ReactSVG } from 'react-svg';

const Icon = (props) => {
    let icon = '';
    if(props.icon){
        try{
            icon = require('../../icons/' + props.icon + '.svg');
        }catch($e){
            icon = require('../../icons/star.svg');
        }
        return <ReactSVG src={icon} />;
    }
    if(props.flaticon){
        return <i className={`fi ${props.flaticon} ${props.color ? 'color-'+props.color : ''} ${props.className || ''}`} onClick={props.onClick}></i>
    }

};

export default Icon;
