import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	VALIDATOR_REQUIRE,
	VALIDATOR_EMAIL,
	VALIDATOR_MINLENGTH,
} from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';

import Input from '../../shared/components/UIElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Modal from '../../shared/components/UIElements/Modal';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import SelectWrapper from '../../shared/components/UIElements/SelectWrapper';
import Notice from '../../shared/components/UIElements/Notice';
import PageHeader from '../../shared/components/Titles/PageHeader';
import FormFooter from '../../shared/components/Forms/FormFooter';
import { capitalizeText } from '../../shared/utils/text';

import './EditUser.css';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import { userGroups, userRoles } from '../data/UserOptions';

import { useTranslation } from "react-i18next";

const EditUser = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [user, setLoadedUser] = useState();
	const userId = useParams().uid;
	const history = useHistory();
	const [modalConfirmDelete, setmodalConfirmDelete] = useState(false);
	// const [passwordValue, setPasswordValue] = useState('');
	const [passwordState, setPasswordState] = useState(true);
	const [passwordValidator, setPasswordValidator] = useState([VALIDATOR_MINLENGTH(8)]);

	const openConfirmDelete = () => {
		if (modalConfirmDelete) {
			setmodalConfirmDelete(false);
		} else {
			setmodalConfirmDelete(true);
		}
	};

	const makeValidIfEmpty = async (event) => {
		const length = await event.target.value.length;
		console.log(length);
		if (length === 0) {
			setPasswordState(true);
			setPasswordValidator([]);
		} else {
			setPasswordState(false);
			setPasswordValidator([VALIDATOR_MINLENGTH(8)]);
		}
	}

	const [formState, inputHandler, setFormData] = useForm(
		{
			first_name: {
				value: '',
				isValid: true,
			},
			last_name: {
				value: '',
				isValid: true,
			},
			email: {
				value: '',
				isValid: true,
			},
			phone: {
				value: '',
				isValid: true,
			},
			password: {
				value: '',
				isValid: true,
			},
			role: {
				value: '',
				isValid: true,
			},
		},
		true
	);

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/users/${userId}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setLoadedUser(response.user);
				setFormData(
					{
						first_name: {
							value: response.user.first_name,
							isValid: true,
						},
						last_name: {
							value: response.user.last_name,
							isValid: true,
						},
						email: {
							value: response.user.email,
							isValid: true,
						},
						phone: {
							value: response.user.phone,
							isValid: true,
						},
						password: {
							value: '',
							isValid: true,
						},
						role: {
							value: response.user.role,
							isValid: true,
						},
						group: {
							value: response.user.group,
							isValid: true,
						},
					},
					true
				);
			} catch (error) {
				console.log(error);
			}
		};
		fetchUser();
	}, [sendRequest, userId, setFormData]);

	const formSubmitHandler = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				`${process.env.REACT_APP_API_URL}/users/${userId}`,
				'PATCH',
				JSON.stringify({
					first_name: formState.inputs.first_name.value,
					last_name: formState.inputs.last_name.value,
					email: formState.inputs.email.value,
					phone: formState.inputs.phone.value,
					password: formState.inputs.password.value,
					role: formState.inputs.role.value,
					group: formState.inputs.group.value,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/users/',
				noticeMsg: t('user.updated'),
				noticeType: 'success',
			});
		} catch (error) {
			console.log(error);
		}
	};

	const confirmDelete = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				`${process.env.REACT_APP_API_URL}/users/${userId}`,
				'DELETE',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			history.push({
				pathname: '/users/',
				noticeMsg: t('user.removed'),
				noticeType: 'success',
			});
		} catch (error) {
			console.log(error);
		}
	};

	if (isLoading) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	const actions = [
		{
			title: t('common.cancel'),
			onClick: () => history.goBack()
		},
		{
			title: t('common.save'),
			style: 'filled-blue',
			flaticon: 'fi-rr-check',
			form: 'edit-user',
			type: 'submit',
			disabled: !formState.isValid
		}
	];

	const subactions = [
		{
			title: t('user.remove'),
			flaticon: 'fi-rr-trash',
			onClick: openConfirmDelete
		}
	];

	let subdata;
	if (user) {
		subdata = [
			{
				title: capitalizeText(user.role),
				flaticon: 'fi-rr-key',
				tooltip: t('user.role')
			},
		];
	}

	if (!user) {
		return <div>{t('user.not-found')}</div>;
	}

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					gobackOnClick={() => history.goBack()}
					title={`${user.first_name} ${user.last_name}`}
					subdata={subdata}
					actions={actions}
					subactions={subactions}
					separator={true}
				/>
			</ContentHead>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{!isLoading && user && (
				<form id='edit-user' onSubmit={formSubmitHandler}>
					<FormGroup
						title={t('user.user-details')}
						subtitle={t('user.user-details-st')}
					>
						<Input
							id='first_name'
							element='input'
							type='text'
							placeholder={t('user.first-name-ph')}
							label={t('user.first-name')}
							onInput={inputHandler}
							validators={[VALIDATOR_REQUIRE()]}
							errorText={t('forms.common.error-valid-text')}
							initialValid={formState.inputs.first_name.isValid}
							initialValue={formState.inputs.first_name.value}
						/>
						<Input
							id='last_name'
							element='input'
							type='text'
							placeholder={t('user.last-name-ph')}
							label={t('user.last-name')}
							onInput={inputHandler}
							validators={[VALIDATOR_REQUIRE()]}
							errorText={t('forms.common.error-valid-text')}
							initialValid={formState.inputs.last_name.isValid}
							initialValue={formState.inputs.last_name.value}
						/>
					</FormGroup>
					<FormGroup
						title={t('user.contact-information')}
						subtitle={t('user.contact-information-st')}
					>
						<Input
							id='email'
							element='input'
							type='email'
							placeholder={t('user.email-ph')}
							label={t('user.email')}
							onInput={inputHandler}
							validators={[VALIDATOR_EMAIL()]}
							initialValid={formState.inputs.email.isValid}
							initialValue={formState.inputs.email.value}
						/>
						<Input
							id='phone'
							element='input'
							type='phone'
							placeholder={t('user.phone-ph')}
							label={t('user.phone')}
							onInput={inputHandler}
							validators={[]}
							initialValid={formState.inputs.phone.isValid}
							initialValue={formState.inputs.phone.value}
						/>
					</FormGroup>
					<FormGroup
						title={t('user.security')}
						subtitle={t('user.security-st')}
					>
						<Input
							id='password'
							element='input'
							type='password'
							placeholder={t('user.password-edit')}
							label={t('user.password')}
							onChange={makeValidIfEmpty}
							onInput={inputHandler}
							validators={passwordValidator}
							errorText={t('user.password-err')}
							initialValid={passwordState}
							isValid={passwordState}
						/>
					</FormGroup>
					<FormGroup
						title={t('user.access')}
						subtitle={t('user.access-st')}
					>
						<SelectWrapper
							onInput={inputHandler}
							id='role'
							label={t('user.user-type')}
							initialValue={formState.inputs.role.value}
							initialValid={true}
							options={userRoles}
						/>
					</FormGroup>
					{formState.inputs.role.value && formState.inputs.role.value !== 'user' && <FormGroup
						title={t('user.group')}
						subtitle={t('user.group-st')}
					>
						<SelectWrapper
							onInput={inputHandler}
							id='group'
							label={t('user.user-group')}
							selectDisabler={true}
							selectDisablerTitle={t('user.notify-all')}
							selectDisablerValue='all'
							options={userGroups}
							validators={[VALIDATOR_REQUIRE()]}
							initialValid={formState.inputs.group.isValid}
							initialValue={formState.inputs.group.value}
							isMulti={true}
							disableInputText={true}
						/>
					</FormGroup>}
					<FormActions>
						<FormFooter
							actions={actions}
						/>
					</FormActions>
				</form>
			)}
			<Modal
				show={modalConfirmDelete}
				title={t('user.remove')}
				closeAction={openConfirmDelete}
				footer={
					<div className='modal-footer-actions'>
						<ActionButton text={t('common.confirm-remove')} style={`red-delete`} onClick={confirmDelete} />
						<ActionButton text={t('common.close')} onClick={openConfirmDelete} />
					</div>
				}
			>
				<Notice type='danger' message={`This action can't be undone`} />
				<p>{t('user.confirm-remove')}</p>
			</Modal>
		</ContentWrapper>
	);
};

export default EditUser;
