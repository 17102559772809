import React, { useState, useEffect, useContext } from 'react';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useHistory, useLocation } from 'react-router-dom';

import PayoutList from '../components/PayoutList';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ItemList from '../../shared/components/UIElements/ItemList';
import PageHeader from '../../shared/components/Titles/PageHeader';
import Filters from '../../shared/components/Results/Filters';

import './Payouts.css';

import { payoutYears } from '../data/PayoutData';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import Modal from '../../shared/components/UIElements/Modal';

import { useTranslation } from "react-i18next";

const Payouts = (props) => {
    const { t } = useTranslation('common');
    const { isLoading, errorMsg, sendRequest } = useHttpClient();
    const [payoutsList, setPayoutsList] = useState();
    const auth = useContext(AuthContex);
    const location = useLocation();
    const history = useHistory();
    const [noticeMsg, setNoticeMsg] = useState();
    const [noticeType, setnoticeType] = useState();
    const [modalConfirmDelete, setmodalConfirmDelete] = useState(false);
	const [modalPayout, setModalPayout] = useState();
	const openConfirmDelete = (deal) => {
		if (modalConfirmDelete) {
			setModalPayout();
			setmodalConfirmDelete(false);
		}else{
			setModalPayout(deal);
			setmodalConfirmDelete(true);
		}
	}

    // Pagination, Search & Filter
    const [totalResults, setTotalResults] = useState(0);
    const [perPageLimit, setPerPageLimit] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState({});
    const fetchResultsQuery = async (query) => {
        try {
            const response = await sendRequest(
                process.env.REACT_APP_API_URL + '/payouts/?' + query,
                'GET',
                null,
                { Authorization: 'Bearer ' + auth.token }
            );
            setPayoutsList(response.payouts);
            setTotalResults(response.count);
        } catch (error) {
            console.log(error);
        }
    };

    const years = payoutYears;

    const filters = {
        queryFn: fetchResultsQuery,
        setQueryFn: setQuery,
        query: query,
        totalRes: totalResults,
        limitRes: perPageLimit,
        currentPage: currentPage,
        setCurrentPage: setCurrentPage,
        filtersData: [
            {
                id: 'year',
                type: 'select',
                label: t('payout.year'),
                options: years
            },
        ]
    };
    // End Pagination, Search & Filter

    useEffect(() => {
        setNoticeMsg(location.noticeMsg);
        setnoticeType(location.noticeType);
    }, [location]);

    useEffect(() => {
        const fetchPayouts = async () => {
            try {
                const response = await sendRequest(
                    process.env.REACT_APP_API_URL + '/payouts/',
                    'GET',
                    null,
                    { Authorization: 'Bearer ' + auth.token }
                );
                setPayoutsList(response.payouts);
                setTotalResults(response.count);
                setPerPageLimit(response.per_page);
            } catch (error) {
                console.log(error);
            }
        };
        fetchPayouts();
    }, [auth.token, sendRequest]);

    const deletePayout = async (payout) => {
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_API_URL}/payouts/${payout.id}`,
                'DELETE',
                null,
                { Authorization: 'Bearer ' + auth.token }
            );
			history.push({
				pathname: '/payouts/',
				noticeMsg: response.message,
				noticeType: 'success',
			});
			fetchResultsQuery(new URLSearchParams(query).toString());
        } catch (error) {
            console.log(error)
        }
    };

    const itemsHeader = [
        {
            title: t('tables.month'),
            style: 'flex-small'
        },
        {
            title: t('tables.year'),
            style: 'flex-small'
        },
        {
            title: t('tables.deals'),
        },
        {
            title: t('tables.amount'),
            style: 'text-align-right'
        },
        {
            title: t('tables.actions')
        }];

    const actions = [
        {
            title: t('payout.generate'),
            flaticon: 'fi-br-plus-small',
            style: 'filled-blue',
            to: '/payouts/new'
        }
    ];

    return (
        <ContentWrapper>
            <ContentHead>
                <PageHeader
                    title={t('models.payouts')}
                    // subtitle='List of all the client’s companies.'
                    actions={actions}
                // subactions={subactions}
                />
                {auth.role !== 'user' && <Filters pagination={true} filter={true} filters={filters} search={true} />}
            </ContentHead>
            {isLoading && <LoadingSpinner />}
            {errorMsg && <Notice type='error' message={errorMsg} />}
            {noticeMsg && <Notice type={noticeType} message={noticeMsg} />}
            <ItemList
                header={itemsHeader}
                actions={true}
                columns={5}
                template='boxed'
                align='center'
            >
                {!isLoading && payoutsList && <PayoutList payouts={payoutsList} header={itemsHeader} />}
            </ItemList>
            {(totalResults > perPageLimit) && <Filters pagination={true} filters={filters} />}
        </ContentWrapper>
    );
};

export default Payouts;
