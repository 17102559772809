import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';
import { useHistory } from 'react-router-dom';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentBox from '../../shared/components/Wrappers/ContentBox';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import PageHeader from '../../shared/components/Titles/PageHeader';

import './Company.css';
import Tabs from '../../shared/components/Results/Tabs';
import CompanyProjects from '../tabs/CompanyProjects';
import CompanySubscriptions from '../tabs/CompanySubscriptions';
import NewNote from '../../notes/components/NewNote';
import DashboardColumn from '../../shared/components/Dashboards/DashboardColumn';
import DashboardBox from '../../shared/components/Dashboards/DashboardBox';
import DataTable from '../../shared/components/Dashboards/DataTable';
import DashboardWrapper from '../../shared/components/Dashboards/DashboardWrapper';

import formatDate from '../../shared/utils/dates';
import CompanyDeals from '../tabs/CompanyDeals';

import { useTranslation } from "react-i18next";

const Company = () => {
	const { t, i18n } = useTranslation('common');
	const companyId = useParams().cid;
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [company, setLoadedCompany] = useState();
	const auth = useContext(AuthContex);
	const [tab, setTab] = useState('default');
	const history = useHistory();
	const [showModal, setShowModal] = useState(false);

	const toggleNote = () => {
		if (showModal) {
			setShowModal(false);
		} else {
			setShowModal(true);
		}
	};

	useEffect(() => {
		const fetchCompanies = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/companies/${companyId}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setLoadedCompany(response.company);
			} catch (error) {
				console.log(error);
			}
		};
		fetchCompanies();
	}, [sendRequest, auth, companyId]);

	if (isLoading) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	const actions = [
		{
			// title: 'Add note',
			// flaticon: 'fi-br-medical-star',
			// flaticon: 'fi-br-comment',
			flaticon: 'fi-br-notebook',
			tooltip: t('note.add'),
			onClick: () => toggleNote()
		},
		{
			// title: 'Edit company',
			flaticon: 'fi-br-pencil',
			tooltip: t('company.edit'),
			to: '/companies/edit/' + companyId
		},
	];

	const tabs = [
		{
			id: 'default',
			title: t('common.general'),
			to: '/companies/' + companyId,
			onClick: () => setTab('default'),
		},
		{
			id: 'projects',
			title: t('models.projects'),
			onClick: () => setTab('projects'),
		},
		{
			id: 'subscriptions',
			title: t('models.subscriptions'),
			onClick: () => setTab('subscriptions'),
		},
		{
			id: 'deals',
			title: t('models.deals'),
			onClick: () => setTab('deals'),
		},
		{
			title: 'Payments',
			// to: '/companies/' + companyId + '/invoices/',
			status: 'disabled'
		},
	];

	let subdata;
	if (company) {
		subdata = [
			{
				title: company.company_name || '',
				flaticon: 'fi-rr-briefcase',
				tooltip: t('company.legal-name'),
				to: '/companies/' + company.id
			},
			{
				title: company.vat,
				flaticon: 'fi-rr-document',
				tooltip: t('company.vat-number')

			},
			{
				title: (company.created_by?.first_name || '') + ' ' + (company.created_by?.last_name || ''),
				flaticon: 'fi-rr-user',
				tooltip: t('common.createdby')
			}
		];
	}

	const renderTab = () => {
		switch (tab) {
			case 'projects':
				return (
					<CompanyProjects company={company} />
				)
			case 'subscriptions':
				return (
					<CompanySubscriptions company={company} />
				)
			case 'deals':
				return (
					<CompanyDeals company={company} />
				)
			default:
				return (
					<>
						<DashboardWrapper>
							<DashboardColumn className='flex-small'>
								<DashboardBox
									title={t('company.information')}
								>
									<DataTable>
										<div><span>{t('company.commercial-name')}</span><span>{company.name}</span></div>
										<div><span>{t('company.legal-name')}</span><span>{company.company_name}</span></div>
										<div><span>{t('company.vat-number')}</span><span>{company.vat}</span></div>
										<div><span>{t('company.address')}</span><span>{company.address?.address_1} {company.address?.address_2}<br />{company.address?.postal_code}, {company.address?.city}<br />{company.address?.country}</span></div>
										<div><span>{t('company.tax-rate')}</span><span>{company.tax}%</span></div>
										{company.payment?.platform && <div><span>{t('company.payment-platform')}</span><span>{company.payment?.platform}</span></div>}
										{company.payment?.method && <div><span>{t('company.payment-method')}</span><span>{company.payment?.method}</span></div>}
										{company.payment?.iban && <div><span>{t('company.payment-iban')}</span><span>{company.payment?.iban}</span></div>}
										{company.payment?.reference && <div><span>{t('company.payment-reference')}</span><span>{company.payment?.reference}</span></div>}
										<div><span>{t('common.createdby')}</span><span>{company.created_by?.first_name} {company.created_by?.last_name}</span></div>
										<div><span>{t('common.lastupdated')}</span><span>{formatDate(company.updated_at, 'DD/MM/YYYY')}</span></div>
										<div><span>{t('common.created')}</span><span>{formatDate(company.created_at, 'DD/MM/YYYY')}</span></div>
									</DataTable>
								</DashboardBox>
							</DashboardColumn>
							<DashboardColumn>
								<DashboardBox
									title={t('models.projects')}
									className='dashboard-box-table'
								>
									<CompanyProjects company={company} limit={5} hideColumnsIndex={[]} viewAll={() => setTab('projects')} />
								</DashboardBox>
								<DashboardBox
									title={t('models.subscriptions')}
									className='dashboard-box-table'
								>
									<CompanySubscriptions company={company} limit={5} hideColumnsIndex={[]} viewAll={() => setTab('subscriptions')} />
								</DashboardBox>
								<DashboardBox
									title={t('models.deals')}
									className='dashboard-box-table'
								>
									<CompanyDeals company={company} limit={5} hideColumnsIndex={[4, 6]} viewAll={() => setTab('deals')} />
								</DashboardBox>
							</DashboardColumn>
						</DashboardWrapper>
						{/* <ServerBox styleType='small' project={project.id} /> */}
					</>
				)
		}
	}

	return (
		<ContentWrapper>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{!isLoading && company && (
				<React.Fragment>
					<ContentHead>
						<PageHeader
							gobackOnClick={() => history.goBack()}
							title={company.name}
							subdata={subdata}
							actions={actions}
						/>
						<Tabs tabs={tabs} current={tab} />
					</ContentHead>
					<React.Fragment key={company.id}>
						{renderTab()}
					</React.Fragment>
					<NewNote show={showModal} object={'company'} id={company.id} label={company.name} toggleNote={toggleNote} />
				</React.Fragment>
			)}
		</ContentWrapper>
	);
};

export default Company;
