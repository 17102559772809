import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';

import Input from '../../shared/components/UIElements/Input';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Modal from '../../shared/components/UIElements/Modal';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import SelectWrapper from '../../shared/components/UIElements/SelectWrapper';
import SelectAsyncWrapper from '../../shared/components/UIElements/SelectAsyncWrapper';
import Notice from '../../shared/components/UIElements/Notice';
import PageHeader from '../../shared/components/Titles/PageHeader';
import Tabs from '../../shared/components/Results/Tabs';
import ActionButton from '../../shared/components/UIElements/ActionButton';

import './EditCompany.css';
import FormFooter from '../../shared/components/Forms/FormFooter';
import { companyPaymentMethods, companyPaymentPlatforms, companyTaxes } from '../data/CompanyOptions';

import { useTranslation } from "react-i18next";

const EditCompany = (props) => {
	const { t, i18n } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [company, setLoadedCompany] = useState();
	const [payments, setPayments] = useState();
	const [projects, setProjects] = useState();
	const [subscriptions, setSubscriptions] = useState();
	const [deals, setDeals] = useState();
	const [isSepa, setIsSepa] = useState(true);
	const companyId = useParams().cid;
	const history = useHistory();
	const [modalConfirmDelete, setmodalConfirmDelete] = useState(false);
	const [modalConfirmRestore, setmodalConfirmRestore] = useState(false);
	const [defaultUsers, setDefaultUsers] = useState('');

	const openConfirmDelete = () => {
		if (modalConfirmDelete) {
			setmodalConfirmDelete(false);
		} else {
			const fetchPayments = async () => {
				try {
					const response = await sendRequest(
						`${process.env.REACT_APP_API_URL}/payments/company/${companyId}`,
						'GET',
						null,
						{ Authorization: 'Bearer ' + auth.token }
					);
					setPayments(response.payments);
				} catch (error) {
					console.log(error);
				}
			};
			const fetchProjects = async () => {
				try {
					const response = await sendRequest(
						`${process.env.REACT_APP_API_URL}/projects/company/${companyId}?exclude_status=archived`,
						'GET',
						null,
						{ Authorization: 'Bearer ' + auth.token }
					);
					setProjects(response.projects);
				} catch (error) {
					console.log(error);
				}
			};
			const fetchSubscriptions = async () => {
				try {
					const response = await sendRequest(
						`${process.env.REACT_APP_API_URL}/subscriptions/company/${companyId}?status=active`,
						'GET',
						null,
						{ Authorization: 'Bearer ' + auth.token }
					);
					setSubscriptions(response.subscriptions);
				} catch (error) {
					console.log(error);
				}
			};
			const fetchDeals = async () => {
				try {
					const response = await sendRequest(
						`${process.env.REACT_APP_API_URL}/deals/company/${companyId}`,
						'GET',
						null,
						{ Authorization: 'Bearer ' + auth.token }
					);
					setDeals(response.deals);
				} catch (error) {
					console.log(error);
				}
			};
			fetchPayments();
			fetchProjects();
			fetchSubscriptions();
			fetchDeals();
			setmodalConfirmDelete(true);
		}
	};

	const openConfirmRestore = () => {
		if (modalConfirmRestore) {
			setmodalConfirmRestore(false);
		} else {
			setmodalConfirmRestore(true);
		}
	};

	const setPaymentType = (event) => {
		if (event.value === 'sepa') {
			setIsSepa(true);
		} else {
			setIsSepa(false);
		}
	};

	const setPaymentTypeSelect = (method) => {
		if (method !== 'sepa') {
			setIsSepa(false);
		}
	};

	const searchUsers = async (inputValue) => {
		if (inputValue.length > 2) {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/users/s/`,
					'POST',
					JSON.stringify({
						search: inputValue,
						format: 'select',
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					},
					false
				);
				if (response.users) {
					return response.users;
				} else {
					return [];
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const [formState, inputHandler, setFormData] = useForm(
		{
			name: {
				value: '',
				isValid: false,
			},
			company_name: {
				value: '',
				isValid: false,
			},
			vat: {
				value: '',
				isValid: false,
			},
			tax: {
				value: '',
				isValid: false,
			},
			address_1: {
				value: '',
				isValid: false,
			},
			address_2: {
				value: '',
				isValid: false,
			},
			city: {
				value: '',
				isValid: false,
			},
			postal_code: {
				value: '',
				isValid: false,
			},
			platform: {
				value: '',
				isValid: false,
			},
			method: {
				value: '',
				isValid: false,
			},
			iban: {
				value: '',
				isValid: false,
			},
			reference: {
				value: '',
				isValid: false,
			},
			// status: {
			// 	value: '',
			// 	isValid: false,
			// },
		},
		true
	);

	useEffect(() => {
		const fetchCompany = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/companies/${companyId}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setLoadedCompany(response.company);

				setFormData(
					{
						name: {
							value: response.company.name,
							isValid: true,
						},
						company_name: {
							value: response.company.company_name,
							isValid: true,
						},
						vat: {
							value: response.company.vat,
							isValid: true,
						},
						tax: {
							value: response.company.tax,
							isValid: true,
						},
						address_1: {
							value: response.company.address.address_1,
							isValid: true,
						},
						address_2: {
							value: response.company.address.address_2,
							isValid: true,
						},
						city: {
							value: response.company.address.city,
							isValid: true,
						},
						postal_code: {
							value: response.company.address.postal_code,
							isValid: true,
						},
						country: {
							value: response.company.address.country,
							isValid: true,
						},
						platform: {
							value: response.company.payment?.platform,
							isValid: true,
						},
						method: {
							value: response.company.payment?.method,
							isValid: true,
						},
						iban: {
							value: response.company.payment?.iban,
							isValid: true,
						},
						reference: {
							value: response.company.payment?.reference,
							isValid: true,
						},
						users: {
							value: response.users,
							isValid: true,
						},
						// status: {
						// 	value: response.company.status,
						// 	isValid: true,
						// },
					},
					true
				);
				setPaymentTypeSelect(response.company.payment?.method);
				setDefaultUsers(response.users);
			} catch (error) {
				console.log(error);
			}
		};
		fetchCompany();
	}, [auth.token, companyId, sendRequest, setFormData]);

	const formSubmitHandler = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				`${process.env.REACT_APP_API_URL}/companies/${companyId}`,
				'PATCH',
				JSON.stringify({
					name: formState.inputs.name.value,
					company_name: formState.inputs.company_name.value,
					vat: formState.inputs.vat.value,
					tax: formState.inputs.tax.value,
					address: {
						address_1: formState.inputs.address_1.value,
						address_2: formState.inputs.address_2.value,
						city: formState.inputs.city.value,
						postal_code: formState.inputs.postal_code.value,
						country: formState.inputs.country.value,
					},
					payment: {
						platform: formState.inputs.platform.value,
						method: formState.inputs.method.value,
						iban: formState.inputs.iban.value,
						reference: formState.inputs.reference.value,
					},
					users: formState.inputs.users.value,
					// status: formState.inputs.status.value,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/companies/',
				noticeMsg: t('company.updated'),
				noticeType: 'success',
			});
		} catch (error) {
			console.log(error);
		}
	};

	const confirmDelete = async (event) => {
		event.preventDefault();
		setmodalConfirmDelete(false);
		try {
			const response = await sendRequest(
				`${process.env.REACT_APP_API_URL}/companies/${companyId}`,
				'DELETE',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			history.push({
				pathname: '/companies/',
				noticeMsg: response.message,
				noticeType: 'success',
			});
		} catch (error) { }
	};

	const confirmRestore = async (event) => {
		event.preventDefault();
		setmodalConfirmRestore(false);
		try {
			const response = await sendRequest(
				`${process.env.REACT_APP_API_URL}/companies/restore/${companyId}`,
				'PATCH',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			history.push({
				pathname: '/companies/',
				noticeMsg: response.message,
				noticeType: 'success',
			});
		} catch (error) {
			setmodalConfirmRestore(false);
		}
	};

	// if (isLoading) {
	// 	return <LoadingSpinner></LoadingSpinner>;
	// }

	if (!company) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	const actions = [
		{
			title: t('common.cancel'),
			to: '/companies/' + companyId,
		},
		{
			title: t('common.save'),
			style: 'filled-blue',
			flaticon: 'fi-rr-check',
			form: 'edit-company',
			type: 'submit',
			disabled: !formState.isValid
		}
	];

	let subactions = [
		{
			title: t('company.remove'),
			flaticon: 'fi-rr-trash',
			onClick: openConfirmDelete
		}
	];

	if (company && company.status === 'archived') {
		subactions = [
			{
				title: t('company.restore'),
				flaticon: 'fi-rr-time-past',
				onClick: openConfirmRestore
			}
		];
	}

	const tabs = [
		{
			title: t('common.general'),
			to: '/companies/' + companyId,
			status: 'active'
		},
		{
			title: t('common.integrations'),
			status: 'disabled'
		},
	];

	let subdata;
	if (company) {
		subdata = [
			{
				title: company.company_name || '',
				flaticon: 'fi-rr-briefcase',
				tooltip: t('company.legal-name'),
				to: '/companies/' + company.id
			},
			{
				title: company.vat,
				flaticon: 'fi-rr-document',
				tooltip: t('company.vat-number')

			},
			{
				title: (company.created_by?.first_name || '') + ' ' + (company.created_by?.last_name || ''),
				flaticon: 'fi-rr-user',
				tooltip: t('common.createdby')
			}
		];
	}

	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					gobackOnClick={() => history.goBack()}
					title={company.name}
					subdata={subdata}
					actions={actions}
					subactions={subactions}
					separator={true}
				/>
				{/* <Tabs tabs={tabs} /> */}
			</ContentHead>
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{company && (
				<form id='edit-company' onSubmit={formSubmitHandler}>
					<FormGroup
						title={t('company.information')}
						subtitle={t('company.information-st')}
					>
						<Input
							id='name'
							element='input'
							type='text'
							placeholder={t('company.commercial-name-ph')}
							label={t('company.commercial-name')}
							onInput={inputHandler}
							validators={[VALIDATOR_REQUIRE()]}
							errorText={t('forms.common.error-valid-text')}
							initialValid={formState.inputs.name.isValid}
							initialValue={formState.inputs.name.value}
						/>
						<Input
							id='company_name'
							element='input'
							type='text'
							placeholder={t('company.legal-name-ph')}
							label={t('company.legal-name')}
							onInput={inputHandler}
							validators={[]}
							initialValid={formState.inputs.company_name.isValid}
							initialValue={formState.inputs.company_name.value}
						/>
						<Input
							id='vat'
							element='input'
							type='text'
							placeholder={t('company.vat-number-ph')}
							label={t('company.vat-number')}
							onInput={inputHandler}
							validators={[VALIDATOR_REQUIRE()]}
							errorText={t('company.vat-number-err')}
							initialValid={formState.inputs.vat.isValid}
							initialValue={formState.inputs.vat.value}
						/>
						<SelectWrapper
							onInput={inputHandler}
							id='tax'
							label={t('company.tax-rate')}
							initialValid={formState.inputs.tax.isValid}
							initialValue={formState.inputs.tax.value}
							options={companyTaxes}
						/>
					</FormGroup>
					<FormGroup
						title={t('company.address')}
						subtitle={t('company.address-st')}
					>
						<Input
							id='address_1'
							element='input'
							type='text'
							placeholder={t('company.address-1-ph')}
							label={t('company.address-1')}
							onInput={inputHandler}
							validators={[]}
							initialValid={formState.inputs.address_1.isValid}
							initialValue={formState.inputs.address_1.value}
						/>
						<Input
							id='address_2'
							element='input'
							type='text'
							placeholder={t('company.address-2-ph')}
							label={t('company.address-2')}
							onInput={inputHandler}
							validators={[]}
							initialValid={formState.inputs.address_2.isValid}
							initialValue={formState.inputs.address_2.value}
						/>
						<Input
							id='postal_code'
							element='input'
							type='text'
							placeholder={t('company.postal-code-ph')}
							label={t('company.postal-code')}
							className='flex-half'
							onInput={inputHandler}
							validators={[]}
							initialValid={formState.inputs.postal_code.isValid}
							initialValue={formState.inputs.postal_code.value}
						/>
						<Input
							id='city'
							element='input'
							type='text'
							placeholder={t('company.city-ph')}
							label={t('company.city')}
							className='flex-half'
							onInput={inputHandler}
							validators={[]}
							initialValid={formState.inputs.city.isValid}
							initialValue={formState.inputs.city.value}
						/>
						<Input
							id='country'
							element='input'
							type='text'
							placeholder={t('company.country-ph')}
							label={t('company.country')}
							onInput={inputHandler}
							validators={[]}
							initialValid={formState.inputs.country.isValid}
							initialValue={formState.inputs.country.value}
						/>
					</FormGroup>
					<FormGroup
						title={t('company.payment')}
						subtitle={t('company.payment-st')}
					>
						<SelectWrapper
							onChange={setPaymentType}
							onInput={inputHandler}
							id='platform'
							label={t('company.payment-platform')}
							initialValue={formState.inputs.platform.value}
							initialValid={formState.inputs.platform.isValid}
							options={companyPaymentPlatforms}
						/>
						<SelectWrapper
							onChange={setPaymentType}
							onInput={inputHandler}
							id='method'
							label={t('company.payment-method')}
							initialValue={formState.inputs.method.value}
							initialValid={formState.inputs.method.isValid}
							options={companyPaymentMethods}
						/>
						{isSepa && (
							<React.Fragment>
								<Input
									id='iban'
									element='input'
									type='text'
									placeholder={t('company.payment-iban-ph')}
									label={t('company.payment-iban')}
									onInput={inputHandler}
									validators={[]}
									initialValid={formState.inputs.iban.isValid}
									initialValue={formState.inputs.iban.value}
								/>
								<Input
									id='reference'
									element='input'
									type='text'
									placeholder={t('company.payment-reference')}
									label={t('company.payment-reference')}
									onInput={inputHandler}
									validators={[]}
									initialValid={formState.inputs.reference.isValid}
									initialValue={formState.inputs.reference.value}
								/>
							</React.Fragment>
						)}
					</FormGroup>
					<FormGroup
						title={t('company.access')}
						subtitle={t('company.access-st')}
					>
						<SelectAsyncWrapper
							onInput={inputHandler}
							id='users'
							label={t('company.access-users')}
							initialValue={defaultUsers}
							defaultValue={defaultUsers}
							loadOptions={searchUsers}
							getOptionLabel={(e) =>
								e.first_name + ' ' + e.last_name + ' (' + e.email + ')'
							}
							getOptionValue={(e) => e._id}
							isMulti={true}
						/>
					</FormGroup>
					{/* <FormGroup
						title='Date'
						subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et commodo mauris, ut blandit diam. Fusce lobortis orci eget leo pharetra condimentum.'
					>
						<SelectWrapper
							onInput={inputHandler}
							id='status'
							label='Status'
							initialValue={formState.inputs.status.value}
							options={[
								{ value: 'active', label: 'Active' },
								{ value: 'archived', label: 'Archived' },
							]}
						/>
					</FormGroup> */}
					<FormActions>
						<FormFooter
							actions={actions}
						/>
						{/* <Button type='submit' disabled={!formState.isValid}>
								Save changes
							</Button> */}
					</FormActions>
				</form>
			)}
			{!isLoading && payments && projects && subscriptions && deals &&
				<Modal
					show={modalConfirmDelete}
					title={t('company.remove')}
					closeAction={openConfirmDelete}
					footer={
						<div className='modal-footer-actions'>
							{subscriptions.length === 0 && projects.length === 0 && <ActionButton text={(payments.length > 0 || company.projects.length > 0) ? t('common.confirm-archive') : t('common.confirm-remove')} style={`red-delete`} onClick={confirmDelete} />}
							<ActionButton text={t('common.close')} onClick={openConfirmDelete} />
						</div>
					}
				>
					{(subscriptions.length > 0) &&
						<React.Fragment>
							<Notice type='danger' message={t('company.danger-associated-subscriptions', {subscriptions: subscriptions.length, subscriptionsWord: subscriptions.length > 1 ? t('models.subscription-plur') : t('models.subscription-sing')})} />
						</React.Fragment>
					}
					{(projects.length > 0) &&
						<React.Fragment>
							<Notice type='danger' message={t('company.danger-associated-active-projects')} />
						</React.Fragment>
					}
					{(projects.length === 0 && company.projects.length > 0) &&
						<React.Fragment>
							<Notice type='alert' message={t('company.danger-associated-archived-projects')} />
						</React.Fragment>
					}
					{(deals.length > 0) &&
						<React.Fragment>
							<Notice type='alert' message={t('company.danger-associated-deals', {deals: deals.length})} />
						</React.Fragment>
					}
					{(payments.length > 0) &&
						<React.Fragment>
							<Notice type='alert' message={t('company.danger-associated-payments', {payments: payments.length})} />
						</React.Fragment>
					}
					{(company.projects.length > 0 || payments.length > 0) &&
						<React.Fragment>
							<p>{t('company.notice-archive')}</p>
						</React.Fragment>
					}
					{(subscriptions.length === 0 && projects.length === 0 && company.projects.length === 0 && payments.length === 0) &&
						<React.Fragment>
							<Notice type='danger' message={t('common.action-irreversible')} />
							<p>{t('company.notice-remove')}</p>
						</React.Fragment>
					}

				</Modal>
			}
			{/* Fake modal */}
			{(!payments || !projects || !subscriptions || !deals || isLoading) &&
				<Modal
					show={modalConfirmDelete}
					title={t('company.remove')}
					closeAction={openConfirmDelete}
					footer={
						<div className='modal-footer-actions'>
							<ActionButton text={t('common.confirm-remove')} style={`red-delete`} />
							<ActionButton text={t('common.close')} onClick={openConfirmDelete} />
						</div>
					}
				>
					{isLoading &&
						<LoadingSpinner></LoadingSpinner>
					}
					<React.Fragment>
						<Notice type='danger' message={t('common.action-irreversible')} />
						<p>{t('company.notice-remove')}</p>
					</React.Fragment>

				</Modal>
			}
			{/* End fake modal */}
			{company &&
				<Modal
					show={modalConfirmRestore}
					title={t('company.restore')}
					closeAction={openConfirmRestore}
					footer={
						<div className='modal-footer-actions'>
							<ActionButton text={t('company.restore-confirm')} style={`filled-blue`} onClick={confirmRestore} />
							<ActionButton text={t('common.close')} onClick={openConfirmRestore} />
						</div>
					}
				>
					<React.Fragment>
						<p>{t('company.restore-quest')}</p>
					</React.Fragment>
				</Modal>
			}
		</ContentWrapper>
	);
};

export default EditCompany;
