import Translate from "../../shared/components/Utils/Translate";

const dealTypes = [
    { value: 'recurring', label: <Translate t='deal.type-maintenance' /> },
    { value: 'setup', label: <Translate t='deal.type-setup' /> },
];

const dealStatuses = [
    { value: 'paid', label: <Translate t='deal.status-paid' />, icon: 'fi-br-check' },
    { value: 'pending', label: <Translate t='deal.status-pending' />, icon: 'fi-br-clock' },
];

export { dealTypes, dealStatuses };