import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Modal from '../../shared/components/UIElements/Modal';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import SelectAsyncWrapper from '../../shared/components/UIElements/SelectAsyncWrapper';
import Notice from '../../shared/components/UIElements/Notice';
import PageHeader from '../../shared/components/Titles/PageHeader';
import FormFooter from '../../shared/components/Forms/FormFooter';
import ActionButton from '../../shared/components/UIElements/ActionButton';
import SubscriptionTotals from '../components/SubscriptionTotals';

import formatMoney from '../../shared/utils/money';
import { subscriptionRate } from '../../shared/utils/subscriptions';
import formatDate from '../../shared/utils/dates';

import './EditSubscription.css';
import FormInfo from '../../shared/components/Forms/FormInfo';

import { useTranslation } from "react-i18next";

const EditSubscription = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [subscription, setLoadedSubscription] = useState();
	const [payments, setLoadedPayments] = useState();
	const subscriptionId = useParams().sid;
	const history = useHistory();
	const [modalConfirmDelete, setmodalConfirmDelete] = useState(false);
	const [selectedServices, setSelectedServices] = useState([]);

	const openConfirmDelete = () => {
		if (modalConfirmDelete) {
			setmodalConfirmDelete(false);
		} else {
			setmodalConfirmDelete(true);
		}
	};

	const [formState, inputHandler, setFormData] = useForm();

	useEffect(() => {
		const fetchSubscription = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/subscriptions/${subscriptionId}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setLoadedSubscription(response.subscription);
				setSelectedServices(response.subscription.services);
				setFormData(
					{
						services: {
							value: response.subscription.services,
							isValid: false,
						},
					},
					true
				);
			} catch (error) {
				console.log(error);
			}
		};
		const fetchPayments = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/payments/subscription/${subscriptionId}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setLoadedPayments(response.payments);
			} catch (error) {
				console.log(error);
			}
		};
		fetchSubscription();
		fetchPayments();
	}, [sendRequest, subscriptionId, setFormData, auth]);

	const formSubmitHandler = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				`${process.env.REACT_APP_API_URL}/subscriptions/${subscriptionId}`,
				'PATCH',
				JSON.stringify({
					services: formState.inputs.services.value,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/subscriptions/',
				noticeMsg: t('subscription.updated'),
				noticeType: 'success',
			});
		} catch (error) {
			console.log(error);
		}
	};

	const confirmDelete = async (event) => {
		event.preventDefault();
		try {
			const response = await sendRequest(
				`${process.env.REACT_APP_API_URL}/subscriptions/${subscriptionId}`,
				'DELETE',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			history.push({
				pathname: '/subscriptions/',
				noticeMsg: response.message,
				noticeType: 'success',
			});
		} catch (error) {
			setmodalConfirmDelete(false);
		}
	};

	const searchServices = async (inputValue) => {
		if (inputValue.length > 2) {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/services/s/`,
					'POST',
					JSON.stringify({
						search: inputValue,
						type: 'recurring',
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					},
					false
				);
				if (response.services) {
					return response.services;
				} else {
					return [];
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const updateTotals = (values) => {
		setSelectedServices(values);
	}

	const formatOptionLabelService = (option) => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						flexGrow: '1',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{option.name}
				</div>
				<div style={{ textAlign: 'right', color: 'var(--darkgrey)' }}>
					{formatMoney(option.price)}€/{t('subscription.month-lc')}
				</div>
			</div>
		)
	}

	if (isLoading) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	const actions = [
		{
			title: t('common.cancel'),
			onClick: () => history.goBack()
		},
		{
			title: t('common.save'),
			style: 'filled-blue',
			flaticon: 'fi-rr-check',
			form: 'edit-subscription',
			type: 'submit',
			disabled: !formState.isValid
		}
	];

	const subactions = [
		{
			title: t('subscription.remove'),
			flaticon: 'fi-rr-trash',
			onClick: openConfirmDelete
		}
	];

	let subdata;
	if (subscription) {
		subdata = [
			{
				title: subscription.project?.domain?.url ? subscription.project?.domain?.url.replace(/^https?:\/\//, '') : '',
				flaticon: 'fi-rr-globe',
				tooltip: t('project.domain')
			},
			{
				title: subscriptionRate(subscription),
				flaticon: 'fi-rr-arrows-repeat',
				tooltip: t('subscription.rate-period')

			},
			{
				title: formatDate(subscription.next_renewal, 'DD/MM/YYYY'),
				flaticon: 'fi-rr-calendar-clock',
				tooltip: t('subscription.next-payment')

			},
			{
				title: (subscription.created_by?.first_name || '') + ' ' + (subscription.created_by?.last_name || ''),
				flaticon: 'fi-rr-user',
				tooltip: t('common.createdby')
			}
		];
	}

	const formatOptionLabelProject = (option) => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						flexGrow: '1',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{option.name}
				</div>
				<div style={{ textAlign: 'right', color: 'var(--darkgrey)' }}>
					{option.domain.url.replace(/^https?:\/\//, '')}
				</div>
			</div>
		)
	}

	if (subscription && subscription.status === 'cancelled') {
		history.push({
			pathname: '/subscriptions/',
		});
		return;
	}

	return (
		<ContentWrapper>
			{subscription && (
				<React.Fragment>
					<ContentHead>
						<PageHeader
							gobackOnClick={() => history.goBack()}
							title={t('subscription.edit')}
							subdata={subdata}
							actions={actions}
							subactions={subactions}
							separator={true}
						/>
					</ContentHead>
					{errorMsg && <Notice type='error' message={errorMsg} />}
					{!isLoading && subscription && (
						<form onSubmit={formSubmitHandler} id='edit-subscription'>
							{subscription.project &&
								<FormGroup
									title={t('subscription.project')}
									subtitle={t('subscription.project-st')}
								>

									<SelectAsyncWrapper
										onInput={inputHandler}
										id='project'
										label={t('subscription.project-assigned')}
										initialValue={subscription.project}
										initialValid={true}
										formatOptionLabel={formatOptionLabelProject}
										getOptionLabel={(e) => e.name}
										getOptionValue={(e) => e._id}
										isMulti={false}
										isDisabled={true}
									/>
								</FormGroup>
							}
							<FormGroup
								title={t('subscription.services')}
								subtitle={t('subscription.services-st')}
							>
								<SelectAsyncWrapper
									onInput={inputHandler}
									id='services'
									label={t('subscription.services-included')}
									loadOptions={searchServices}
									className='one-row-item'
									dropdownIcon='fi-rr-search'
									formatOptionLabel={formatOptionLabelService}
									initialValue={selectedServices}
									initialValid={formState.inputs.services.isValid}
									defaultValue={selectedServices}
									getOptionLabel={(e) => e.name}
									getOptionValue={(e) => e._id}
									isMulti={true}
									onChange={updateTotals}
								/>
								<SubscriptionTotals services={selectedServices} period={subscription.period} />
							</FormGroup>
							<FormActions>
								<FormFooter
									actions={actions}
								/>
							</FormActions>
						</form>
					)}
					{payments &&
						<Modal
							show={modalConfirmDelete}
							title={t('subscription.remove')}
							closeAction={openConfirmDelete}
							footer={
								<div className='modal-footer-actions'>
									<ActionButton text={(payments && payments.length > 0) ? t('subscription.confirm-cancel') : t('common.confirm-remove')} style={`red-delete`} onClick={confirmDelete} />
									<ActionButton text={t('common.close')} onClick={openConfirmDelete} />
								</div>
							}
						>
							<Notice type='danger' message={t('common.action-irreversible')} />
							{(payments && payments.length > 0) &&
								<React.Fragment>
									<Notice type='alert' message={t('subscription.danger-associated-payments', { payments: payments.length })} />
									<p>{t('subscription.notice-cancel')}</p>
								</React.Fragment>
							}
							{(payments && payments.length === 0) &&
								<React.Fragment>
									<p>{t('subscription.notice-remove')}</p>
								</React.Fragment>
							}

						</Modal>
					}
				</React.Fragment>
			)}
		</ContentWrapper>
	);
};

export default EditSubscription;
