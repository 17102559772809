import React, { useState, useEffect, useContext } from 'react';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Notice from '../../shared/components/UIElements/Notice';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Icon from '../../shared/components/UIElements/Icon';

import './ServerBox.css';

const ServerBox = (props) => {

	const projectId = props.project;
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [serverStat, setLoadedServerStat] = useState();
	const auth = useContext(AuthContex);

	useEffect(() => {
		const fetchServerStat = async () => {
			try {
				const response = await sendRequest(
					`${process.env.REACT_APP_API_URL}/statistics/project/server/${projectId}`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				)
                setLoadedServerStat(response.statistics);
                console.log(response);
			} catch (error) {
				console.log(error);
			}
		};
		fetchServerStat();
	}, [sendRequest]);

	return (
		<React.Fragment>
            <div className={`stats-server ${props.styleType}`}>
            {isLoading && <LoadingSpinner type='inside'></LoadingSpinner>}
            {errorMsg && <Notice type='error' message={errorMsg} />}
			{!isLoading && serverStat && serverStat.data && (
				<React.Fragment>
					{serverStat.type === 'server' && (
						<React.Fragment>
                            <div className="stats-server-status">
                                <Icon icon='check' />
                            </div>
                            <div className="stats-server-info">
                            <div className="stats-server-info-name">{serverStat.data.name}</div>
							<div className="stats-server-info-ip">{serverStat.data.host}</div>
                            </div>
						</React.Fragment>
					)}
				</React.Fragment>
			)}
            </div>
		</React.Fragment>
	);
};

export default ServerBox;
