import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { VALIDATOR_REQUIRE } from '../../shared/utils/validators';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContex } from '../../shared/context/auth-context';

import ContentBox from '../../shared/components/Wrappers/ContentBox';
import MainTitle from '../../shared/components/Titles/MainTitle';
import Input from '../../shared/components/UIElements/Input';
import Button from '../../shared/components/UIElements/Button';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ActionsWrapper from '../../shared/components/Wrappers/ActionsWrapper';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import FormGroup from '../../shared/components/Forms/FormGroup';
import FormActions from '../../shared/components/UIElements/FormActions';
import Notice from '../../shared/components/UIElements/Notice';

import './MainSettings.css';

import { useTranslation } from "react-i18next";

const MainSettings = (props) => {
	const { t } = useTranslation('common');
	const auth = useContext(AuthContex);
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [settings, setLoadedSettings] = useState();
	const history = useHistory();

	const [formState, inputHandler, setFormData] = useForm();

	useEffect(() => {
		const fetchSettings = async () => {
			try {
				const settings = await sendRequest(
					process.env.REACT_APP_API_URL + '/settings/main',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setLoadedSettings(settings);
				setFormData(
					{
						phone: {
							value: settings.setting.value.phone,
							isValid: true,
						},
					},
					true
				);
			} catch (error) {
				console.log(error);
			}
		};
		fetchSettings();
	}, [sendRequest, setFormData]);

	const formSubmitHandler = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				process.env.REACT_APP_API_URL + '/settings/',
				'POST',
				JSON.stringify({
					main: {
						phone: formState.inputs.phone.value,
					},
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			history.push({
				pathname: '/settings/',
				noticeMsg: t('setting.updated'),
				noticeType: 'success',
			});
		} catch (error) {
			console.log(error);
		}
	};

	const confirmDelete = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				process.env.REACT_APP_API_URL + '/settings/restore/main',
				'GET',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			history.push({
				pathname: '/settings/',
				noticeMsg: t('setting.restored'),
				noticeType: 'success',
			});
		} catch (error) {}
	};

	if (isLoading) {
		return <LoadingSpinner></LoadingSpinner>;
	}

	if (!settings) {
		return (
			<ContentWrapper>
				<ContentHead>
					<MainTitle>{t('models.settings')}</MainTitle>
					<ActionsWrapper>
						<Button onClick={confirmDelete} className='delete'>
							{t('setting.restore')}
						</Button>
					</ActionsWrapper>
				</ContentHead>
				{errorMsg && <Notice type='error' message={errorMsg} />}
				<ContentBox>
					<div>{t('setting.restore-error')}</div>
				</ContentBox>
			</ContentWrapper>
		);
	}

	return (
		<ContentWrapper>
			<ContentHead>
				<MainTitle>{t('models.settings')}</MainTitle>
				<ActionsWrapper></ActionsWrapper>
			</ContentHead>
			{errorMsg && <Notice type='error' message={errorMsg} />}
			<ContentBox>
				{!isLoading && settings && (
					<form onSubmit={formSubmitHandler}>
						<FormGroup title={t('setting.basic-settings')}>
							<Input
								id='phone'
								element='input'
								type='phone'
								placeholder={t('setting.company-phone-ph')}
								label={t('setting.company-phone')}
								onInput={inputHandler}
								validators={[VALIDATOR_REQUIRE()]}
								errorText='Please input a valid text'
								initialValid={formState.inputs.phone.isValid}
								initialValue={formState.inputs.phone.value}
							/>
						</FormGroup>
						<FormActions>
							<Button type='submit' disabled={!formState.isValid}>
								{t('common.save')}
							</Button>
						</FormActions>
					</form>
				)}
			</ContentBox>
		</ContentWrapper>
	);
};

export default MainSettings;
