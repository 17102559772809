import React from 'react';

import formatMoney from '../../shared/utils/money';
import { periodToNumber } from '../../shared/utils/subscriptions';

import './SubscriptionTotals.css';

const SubscriptionTotals = (props) => {

	let total = 0;
	props.services.forEach((service) => (total += service.price));

	return (
		<React.Fragment>
			<div className='subscription-total'>
				<span>Total</span>
				<span>{formatMoney(periodToNumber(props.period)*total)}€/{props.period}</span>
			</div>
		</React.Fragment>
	);
};

export default SubscriptionTotals;
