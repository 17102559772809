import React from 'react';
import SubscriptionItem from './SubscriptionItem';

import { subscriptionRate } from '../../shared/utils/subscriptions';

const SubscriptionList = (props) => {
	return (
		<React.Fragment>
				{props.subscriptions.map((subscription) => {
					return (
						<SubscriptionItem
							key={subscription.id}
							header={props.header}
							id={subscription.id}
							project={subscription.project?.name}
							period={subscription.period}
							price={subscriptionRate(subscription)}
							services={subscription.services}
							status={subscription.status}
							subscription={subscription}
						/>
					);
				})}
		</React.Fragment>
	);
};

export default SubscriptionList;
