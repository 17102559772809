import React, { useState } from 'react';

import MobileMenu from './MobileMenu';
import Overlay from './../UIElements/Overlay';
import AccountBox from './AccountBox';

import logo from './../../../logo.svg';
import './Header.css';

const Header = () => {
	const [mobileMenuStatus, setMobileMenuStatus] = useState(false);
	const openMobileMenu = () => {
		setMobileMenuStatus(true);
	};
	const closeMobileMenu = () => {
		setMobileMenuStatus(false);
	};

	return (
		<React.Fragment>
			<header className='main'>
				<div className='header-content'>
					<div className='left'>
						<img src={logo} alt='Midrocket'></img>
					</div>
					<div className='right'>
						{/* <NavLinks className='desktop-nav' /> */}
						<AccountBox />
						<div className='open-mobile' onClick={openMobileMenu}>
							<i className='fi fi-rr-menu-burger'></i>
						</div>
					</div>
				</div>
			</header>
			<MobileMenu show={mobileMenuStatus} onClick={closeMobileMenu} />
			{mobileMenuStatus && <Overlay onClick={closeMobileMenu} />}
		</React.Fragment>
	);
};

export default Header;
