import React from 'react';

import { subscriptionRate } from '../../shared/utils/subscriptions';
import PaymentItem from './PaymentItem';

const PaymentList = (props) => {
	return (
		<React.Fragment>
				{props.payments.map((payment) => {
					return (
						<PaymentItem
							key={payment.id}
							header={props.header}
							id={payment.id}
							start_date={payment.start_date}
							status={payment.status}
							price={payment.price}
						/>
					);
				})}
		</React.Fragment>
	);
};

export default PaymentList;
