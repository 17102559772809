import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContex } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useLocation } from 'react-router-dom';

import PageHeader from '../../shared/components/Titles/PageHeader';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Notice from '../../shared/components/UIElements/Notice';
import ContentHead from '../../shared/components/Wrappers/ContentHead';
import ContentWrapper from '../../shared/components/Wrappers/ContentWrapper';
import ItemList from '../../shared/components/UIElements/ItemList';

import './Notes.css';
import Filters from '../../shared/components/Results/Filters';
import NoteList from '../components/NoteList';
import { userGroups } from '../../users/data/UserOptions';
import { noteStatusesFilter } from '../data/NoteOptions';

import { useTranslation } from "react-i18next";

const Notes = (props) => {
	const { t } = useTranslation('common');
	const { isLoading, errorMsg, sendRequest } = useHttpClient();
	const [notes, setNotes] = useState();
	const auth = useContext(AuthContex);
	const location = useLocation();
	const history = useHistory();
	const [noticeMsg, setNoticeMsg] = useState();
	const [noticeType, setnoticeType] = useState();


	// let defaultQuery = new URLSearchParams({ type: 'task', status: 'pending', assigned_to: 'me' }).toString();
	// let defaultQuery = { type: 'task', status: 'pending', assigned_to: 'me' };

	// Pagination, Search & Filter
	const [totalResults, setTotalResults] = useState(0);
	const [perPageLimit, setPerPageLimit] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [query, setQuery] = useState({});

	const fetchResultsQuery = async (query) => {
		try {
			const response = await sendRequest(
				process.env.REACT_APP_API_URL + '/notes/?type=task&' + new URLSearchParams(query).toString(),
				'GET',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			setNotes(response.notes);
			setTotalResults(response.count);
		} catch (error) {
			console.log(error);
		}
	};

	const filters = {
		queryFn: fetchResultsQuery,
		setQueryFn: setQuery,
		query: query,
		totalRes: totalResults,
		limitRes: perPageLimit,
		currentPage: currentPage,
		setCurrentPage: setCurrentPage,
		filtersData: [
			{
				id: 'status',
				type: 'select',
				label: t('note.status'),
				options: noteStatusesFilter,
				value: 'pending'
			},
			{
				id: 'assigned_to',
				type: 'select',
				label: t('note.assigned-to'),
				infoLabel: t('note.assigned-to-info'),
				options: [
					{ value: 'me', label: t('note.assigned-to-me') },
					{ value: 'all', label: t('note.assigned-to-all') },
					...userGroups
				],
			},
		],
		defaultFiltersData: [
			{
				id: 'status',
				value: 'pending'
			},
			{
				id: 'assigned_to',
				value: 'me'
			}
		]
		// {
		// 	id: 'status_txt',
		// 	type: 'text',
		// 	label: 'Status Text',
		// 	options: '',
		// },
		// {
		// 	id: 'status_cb',
		// 	type: 'checkbox',
		// 	label: 'Status',
		// 	text: 'Active',
		// 	value: 'active'
		// }
	};
	// End Pagination, Search & Filter

	useEffect(() => {
		setNoticeMsg(location.noticeMsg);
		setnoticeType(location.noticeType);
	}, [location, history]);

	useEffect(() => {
		const fetchNotes = async () => {
			try {
				const response = await sendRequest(
					process.env.REACT_APP_API_URL + '/notes/?type=task',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setNotes(response.notes);
				setTotalResults(response.count);
				setPerPageLimit(response.per_page);
			} catch (error) {
				console.log(error);
			}
		};
		fetchNotes();
	}, [auth.token, sendRequest]);
	
	const completeTask = async (note) => {
		try {
			const response = await sendRequest(
				`${process.env.REACT_APP_API_URL}/notes/complete/${note.id}`,
				'PATCH',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			history.push({
				pathname: '/tasks/',
				noticeMsg: response.message,
				noticeType: 'success',
			});
			setNotes(response.notes);
			fetchResultsQuery(new URLSearchParams(query).toString());
		} catch (error) {
			console.log(error)
		}
	};

	const archiveNote = async (note) => {
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_API_URL}/notes/archive/${note.id}`,
                'PATCH',
                null,
                { Authorization: 'Bearer ' + auth.token }
            );
			history.push({
				pathname: '/tasks/',
				noticeMsg: response.message,
				noticeType: 'success',
			});
			fetchResultsQuery(new URLSearchParams(query).toString());
        } catch (error) {
            console.log(error)
        }
    };

	const restoreTask = async (note) => {
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_API_URL}/notes/restore/${note.id}`,
                'PATCH',
                null,
                { Authorization: 'Bearer ' + auth.token }
            );
			history.push({
				pathname: '/tasks/',
				noticeMsg: response.message,
				noticeType: 'success',
			});
			fetchResultsQuery(new URLSearchParams(query).toString());
        } catch (error) {
            console.log(error)
        }
    };

	const deleteNote = async (note) => {
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_API_URL}/notes/${note.id}`,
                'DELETE',
                null,
                { Authorization: 'Bearer ' + auth.token }
            );
			history.push({
				pathname: '/tasks/',
				noticeMsg: response.message,
				noticeType: 'success',
			});
			fetchResultsQuery(new URLSearchParams(query).toString());
        } catch (error) {
            console.log(error)
        }
    };

	const itemsHeader = [
		{
			title: t('tables.updated'),
			style: 'flex-small color-darkgrey'
		},
		{
			title: t('tables.content'),
			// style: 'flex-big'
		},
		{
			title: t('tables.status'),
			style: 'flex-tiny'
		},
		{
			title: t('tables.relation'),
			style: 'flex-medium'
		},
		{
			title: t('tables.assigned-to'),
			style: 'flex-regular'
		},
		{
			title: t('tables.status'),
			style: 'flex-small'
		}];

	// const actions = [
	// 	{
	// 		title: 'Add company',
	// 		flaticon: 'fi-br-plus-small',
	// 		style: 'filled-blue',
	// 		to: '/companies/new'
	// 	}
	// ];

	// const subactions = [
	// 	{
	// 		title: 'Help',
	// 		flaticon: 'fi-br-square-info',
	// 		to: '#'
	// 	},
	// ];
	return (
		<ContentWrapper>
			<ContentHead>
				<PageHeader
					title={t('models.tasks')}
				// subtitle='List of all the client’s companies.'
				// actions={actions}
				// subactions={subactions}
				/>
				<Filters pagination={true} filter={true} filters={filters} search={true} />
			</ContentHead>
			{isLoading && <LoadingSpinner />}
			{errorMsg && <Notice type='error' message={errorMsg} />}
			{noticeMsg && <Notice type={noticeType} message={noticeMsg} />}
			<ItemList
				header={itemsHeader}
				actions={true}
				columns={6}
				template='simple'
				align='center'
			>
				{!isLoading && notes && <NoteList notes={notes} completeTask={completeTask} deleteTask={deleteNote} archiveTask={archiveNote} restoreTask={restoreTask} header={itemsHeader} />}
			</ItemList>
			{(totalResults > perPageLimit) && <Filters pagination={true} filters={filters} />}
		</ContentWrapper>
	);
};

export default Notes;